import React from 'react'
import imgChatswood from '../assets/navbar/chatswood.webp'
import imgHornsby from '../assets/navbar/hornsby.webp'

function NavbarDropdown_Campus() {
  return (
<div className="dropper">
    <div className="dropdown">
        <div className="dropdown-container">
            <div className="dropdown-left">
              <p className="dropdown-heading">World Class Environment</p>
              <p className="dropdown-text">
              Project Academy's academic ecosystem incorporates campus design, technology, and an amazing learning culture to produce a world class environment for high school students to excel.
              </p>
              <a className="dropdown-nav-link" href="/life-at-project">Life at Project →</a>
            </div>
            <div className="dropdown-right">
              <div className="dropdown-right-top">
                <div className="dropdown-right-top-left">
                  <p className="dropdown-heading">Chatswood Campus</p>
                  <p className="dropdown-text">
                  Project Academy's HQ is located in the heart of Chatswood NSW.</p>
                  <p className="dropdown-text">
                  Packed with state of the art facilities, it is one of Project Academy's biggest campuses.
                  </p>
                  <a className="dropdown-nav-link" href="/chatswood">Chatswood campus →</a>
                </div>
                <div className="dropdown-right-top-right">
                  <img src={imgChatswood} />
                </div>
              </div>
              <div className="dropdown-right-bottom">
                <div className="dropdown-right-top-left">
                    <p className="dropdown-heading">Hornsby Campus</p>
                    <p className="dropdown-text">
                    Project Academy is opening a new campus in the coming year.
                    </p>
                    <p className="dropdown-text">
                    Also packed with state of the art facilities, stay tuned as we reveal more!
                    </p>
                  </div>
                  <div className="dropdown-right-top-right">
                    <img src={imgHornsby} />
                  </div>              
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default NavbarDropdown_Campus