import React from 'react'

function BlogDiv({data}) {
  return (
    <>
        
        <div className="blog-div">
        <a className="blog-link" href={data.link}>
            <div className="blog-image">
                <img src={data.img} />
            </div>
            <div className="blog-text">
                <div className="blog-text-upper">
                    <div className="blog-title">
                        <p>{data.heading}</p>
                    </div>
                    <div className="blog-desc">
                        <p>{data.description}</p>
                    </div>
                </div>
                <div className="blog-text-lower">
                    <div className="info-testimonial-person">
                        <div className="info-testimonial-image blog-author-image">
                            <img src={data.profilepic} />
                        </div>
                        <div className="info-testimonial-details">
                            <p className="info-testimonial-name">{data.author}</p>
                            <p className="info-testimonial-school">{data.marks}</p>
                        </div>
                    </div>
                </div>
            </div>
            </a>
        </div>

    </>

  )
}

export default BlogDiv