import React from 'react'

import "./FAQ.scss"

function FAQ({topic}) {

  return (
    <div className="container">
        <div className="FAQ-container">
            <div className="FAQ-left">
                <h2>Frequently Asked Questions</h2>
            </div>
            <div className="FAQ-right">
                {topic?.map((n) => (
                    <div className="FAQ-one"> 
                        <div className="FAQ-question"><p>{n.question}</p></div>
                        <div className="FAQ-answer"><p>{n.answer}</p></div>
                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default FAQ