const articlesHscEconomics = [
    {
        subject: "GUIDE",
        topic: "Complete Guide To Acing HSC Economics",
        description: "",
        img: require('../img-econ/Econ 1.jpg'),
        link: "https://www.projectacademy.nsw.edu.au/year-12-guides/complete-guide-to-hsc-economics-how-to-ace-hsc-economics"
    },
    {
        subject: "GUIDE",
        topic: "How I came 5th in NSW for Economics",
        description: "",
        img: require('../img-econ/Econ 3.jpg'),
        link: "https://about.projectacademy.nsw.edu.au/year-12-guides/how-i-achieved-a-99-60-atar-and-a-state-rank-in-economics"
    },
    {
        subject: "GUIDE",
        topic: "How I came 6th in NSW for Economics",
        description: "",
        img: require('../img-econ/Econ 4.jpg'),
        link: "https://www.projectacademy.nsw.edu.au/headline-articles/achieving-two-state-ranks-and-a-99-95-atar"
    },
    {
        subject: "DEEP DIVE",
        topic: "Topic 2: Australia's Place in the Global Economy",
        description: "",
        img: require('../img-econ/Econ 5.jpg'),
        link: "https://about.projectacademy.nsw.edu.au/year-12-guides/deeper-dive-hsc-economics-australias-place-in-the-global-economy"
    },
]

export { articlesHscEconomics }