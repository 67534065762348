import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./Info.scss";
import "./IpadSection.scss";

function IpadSection({test}) {
  return (
    <div>
        <Tabs className="ipad-container" defaultIndex={0} selectedTabClassName="ipad-tablist-selected">
            <div className="ipad-left">
                <div className="ipad-left-top">
                    <div className="ipad-header">
                        {test?.map((n) => (
                            <>
                                {n.section_heading
                                ? <h2 key={n.section_heading}>{n.section_heading}</h2>
                                : ""
                                }
                            </>
                        ))}
                    </div>
                    <TabList className="ipad-tablist">
                        {/* ? is required below to check if array exists */}
                        {test?.map((n) => (
                            <>
                                {n.heading
                                ? 
                                    (
                                        <Tab>
                                            <div className="ipad-tablist-item">
                                                <span className="ipad-tablist-item-heading" key={n.heading}>{n.heading}</span>
                                                <p>{n.heading_para}</p>
                                            </div>
                                        </Tab>
                                    )
                                : ""
                                }
                            </>

                        ))
                        }
                    </TabList>
                </div>
            </div>
            
            <div className="ipad-right">
            {/* ? is required below to check if array exists */}
            {test?.map((n) => (
                <TabPanel>
                    <img className="ipad-img" src={n.app_img_one} />
                </TabPanel>
                ))
            }
            </div>
        </Tabs>
    </div>
    
  )
}

export default IpadSection