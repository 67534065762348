import React from 'react'
import Profile from './Profile'
import { allProfiles } from "../assets/profileObjects"

function Alumni({data}) {

  return (
    <div className="container tutors-container">
        <h3 className="tutors-header">Our Alumni</h3>  
        <div className="tutors-group">
        {allProfiles?.map((tutor) => (
              (tutor.subject === data)
              ? <Profile tutorName={tutor.name} tutorSubject={data}/>
              : ""
          ))}
        </div>
    </div>
  )
}

export default Alumni