// Y10 INFO
// Y10 INFO
// Y10 INFO
// Y10 INFO
// Y10 INFO

const infoProvenY10Maths = [
    {
        heading: "1. Weekly Masterclass →",
        heading_para_1: "NSW's Top Teachers",
        para_1: "Learn Year 10 Maths from a HSC state-ranker, first in course, or experienced teacher.",
        heading_para_2: "Exam Oriented",
        para_2: "Apply theory to practice with Year 10 exam style maths questions on a weekly basis.",
        heading_para_3: "Principles Based",
        para_3: "Designed to help you develop a deep understanding of Year 10 and even Year 11 concepts.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/6102654085c24d6c9f8ff270_Home%20Section%20A%20Slide%2016.png"
    },
    {
        heading: "2. Unlimited Maths Tutorials →",
        heading_para_1: "Highly Personalised",
        para_1: "Every week, attend highly personalised academic-help sessions with some of the best tutors in NSW.",
        heading_para_2: "Dedicated Tutor",
        para_2: "A tutor who achieved a Band 6 in HSC Maths will be assigned to you. Their goal is to ensure your marks increase every week.",
        heading_para_3: "Unlimited Tutorials",
        para_3: "1 tutorial per week? 5 tutorials per week? Book as many Year 10 Maths tutorials as you need.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60fe99d9a4cf33b1d74817b8_Frame%2073.png"
    },
    {
        heading: "3. Daily Support till 10pm →",
        heading_para_1: "Ask Anything",
        para_1: "Got questions? Every night from 8 - 10pm, we run homework help sessions called Thinktank.",
        heading_para_2: "Any Subject",
        para_2: "Whether it's English, Maths, Science, Economics... you can get help with it all. ",
        heading_para_3: "School Work Help",
        para_3: "Need help with work from school? No problem. Your tutor is there to help you.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60c71d4d4b03cb8925ffb62d_Home%20Section%20A%20Slide%203-p-1080.png"
    },
    {
        testimonial: `"Academic success has and will always be predicated on self motivation... the light-hearted, supportive and collaborative culture of Project has provided the catalyst for self-determination and ultimately improving my marks"`,
        testimonial_name: "Jason Lin",
        testimonial_achieve: "Dux of Knox Grammar '20",
        testimonial_atar: "99.95 ATAR, 3rd in NSW for Chemistry",
        testimonial_img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60efb31f45789a7a206c888f_Jason.png"
    },
    {
        section_heading: "A Proven Approach to Year 10 Maths"
    }
]

// Y10 RESOURCES
// Y10 RESOURCES
// Y10 RESOURCES
// Y10 RESOURCES
// Y10 RESOURCES

const infoResourcesY10Maths = [
    {
        heading: "1. Weekly In-depth Notes →",
        heading_para_1: "In-depth Weekly Notes",
        para_1: "High Band 6 students don't just know the theory. They know the why, the how and know how to apply it in exams, assignments and pracs. Our resources distill the last 10 years of Project's state ranker's and 99+ ATAR tutor's knowledge.",
        heading_para_2: "Purposefully Structured",
        para_2: "All notes are accessed through the Project Books app organised at an unmatched level of detail. Content is organised by lesson and topic, alongside topic-specific HSC exam questions so you know exactly where to focus when preparing for an assessment.",
        doc: "https://drive.google.com/file/d/1MyhfBXANlUXQ8sF1R-jQgB9nMtoT9vy4/preview"
    },
    {
        heading: "2. Weekly In-class Workbook →",
        heading_para_1: "Comprehensive Theory Notes",
        para_1: "Every week, you will receive a specially designed workbook for class. Each workbook helps you capture class content in a neat, structured way so that you will easily remember how to approach each concept.",
        heading_para_2: "Specially Designed Workbooks",
        para_2: "This way, our workbooks are designed to make absorbing class content infinitely more efficient, while ensuring you understand, retain and can easily revise content for your HSC exam.",
        doc: "https://docs.google.com/file/d/1_vzLrPluHhiwreqVi5o1ogAF53S8BOJT/preview"
    },
    {
        heading: "3. Weekly Exam-like Homework →",
        heading_para_1: "Weekly Exam-like Homework",
        para_1: "Every week, you will receive specially designed homework sets to mimic how you will be examined in the HSC. By the time you sit your HSC, it should feel like a breeze.",
        heading_para_2: "Marked & Tracked",
        para_2: "After you submit your homework, receive highly detailed feedback from a state ranking or high band 6 tutor. All mistakes are tracked via an app so we know exactly what topics you're struggling with.",
        doc: `https://drive.google.com/file/d/10h3cmsd5T9O5QFBS451ixG0FlzxS822D/preview`
    },
    {
        heading: "4. 1,000+ Past Papers →",
        heading_para_1: "1,000+ Past Papers",
        para_1: "Access hundreds of past papers for Year 10 Maths and over 1,000 HSC past papers across any other subject you take at school.",
        heading_para_2: "Not Just HSC Papers",
        para_2: "These past papers aren't just for Trials or the HSC. They cover all assessment tasks encountered every term, and for all years.",
        doc: "https://drive.google.com/file/d/1gO1A3SABw4B3wG2c7u5Li7iRfRMd_ezl/preview"
    },
    {
        testimonial: "Academic success has and will always be predicated on self motivation... the light-hearted, supportive and collaborative culture of Project has provided the catalyst for self-determination and ultimately improving my marks",
        testimonial_name: "Jason Lin",
        testimonial_achieve: "Dux of Knox Grammar '20",
        testimonial_atar: "99.95 ATAR, 3rd in NSW for Chemistry",
        testimonial_img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60efb31f45789a7a206c888f_Jason.png"
    },
    {
        section_heading: "The Right Resources"
    }
]
export {
    infoProvenY10Maths,
    infoResourcesY10Maths
}
