const chatswoodData = [
    {
        heading: "Chatswood campus",
        description: "From inspiring, state ranking tutors to your friends and classmates, Project is a vibrant community where you can feel supported.",
        video: "",
        points: [
            {
                heading1:"1. A beautiful campus",
                description1:"Education doesn't have to live in a boring classroom. Instead, we believe students should learn in inspiring environments that encourage focus, collaboration and camaraderie.",
                image: require("../campus/One.webp")
            },
            {
                heading1:"2. World class classroom design",
                description1:"Chatswood's classrooms are ultra spacious, and come with extra-wide tables and comfortable seating. Special lighting directs focus onto the whiteboards and walkways allow teachers to engage students anywhere in the room.",
                image: require("../campus/Two.webp")
            },
            {
                heading1:"3. Dedicated tutorial rooms",
                description1:"Project's tutorial rooms are designed for the most personalised and collaborative school and course related Q&A support imaginable. At Project, every student can access unlimited tutorials, where you can ask as many questions and get as much support as you need from a tutor.",
                image: require("../campus/Three.webp")  
            },
            {
                heading1:"4. Grasslands study sanctuary",
                description1:"This room is about achieving flow. We built Grasslands as a silent sanctuary where you can escape all interruptions to really focus on your work. The rule here is simple - perfect silence; no talking or whispering for any reason. Welcome to work nirvana.",
                image: require("../campus/Four.webp")
            },
            {
                heading1:"5. Staff and students",
                description1:"People are what makes any foreign place feel like home. At Project, we also believe people interactions are also what helps students grow into the best version of themselves.",
                image: require("../campus/Five.webp")
            },
            {
                heading1:"6. Main office",
                description1:"Whenever you need help (or just want to talk), you can always find our friendly operations team at the Main Office on Level 1.",
                image: require("../campus/Six.webp")
            }
        ]
    }
]

export {chatswoodData}