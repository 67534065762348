import React from 'react'

import HeroWide from '../components/HeroWide'
import GeneralWideFold from '../components/GeneralWideFold'
import CTA from '../components/CTA'
import SEO from '../components/SEO'

import { chatswoodHero } from '../assets/heroObjects'

function Chatswood() {
  return (
    <>
        <SEO 
          title='Chatswood Campus | Project Academy'
          description="Project Academy's HQ is located in the heart of Chatswood NSW Packed with state of the art facilities, it is one of Project Academy's biggest campuses."
          name='Project Academy'
        />
        <HeroWide heroInput = {chatswoodHero} />
        <GeneralWideFold topic = "chatswood" />
        <CTA />
    </> 
  )
}

export default Chatswood