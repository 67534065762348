const lifeatprojectData = [
    {
        heading: "1. Academic Life at Project",
        description: "From inspiring, state ranking tutors to your friends and classmates, Project is a vibrant community where you can feel supported.",
        video: "https://www.youtube.com/embed/GoXVTy9OYJE?autoplay=1&mute=1",
        points: [
            {
                heading1:"1. Weekly masterclass",
                description1:"What surprises most new students is just how inspiring and motivating Project teachers are. Every single one of our teaching staff go through a rigorous training and evaluation process, and are hand-picked for their teaching ability, pure knowledge and impact on students.",
                image: require("../frames/Frame1.webp")  
            },
            {
                heading1:"2. Personalised tutorials",
                description1:"Every week, students also attend tutorials. These are private 1-4 sessions with a tutor dedicated to ensuring your marks improve week on week. Tutorials run daily, at all times of the day, giving you the flexibility to book in whenever and however many times you need.",
                image: require("../frames/Frame2.webp") 
            },
            {
                heading1:"3. Unique academic ecosystem",
                description1:"Every week, students also attend tutorials. These are private 1-4 sessions with a tutor dedicated to ensuring your marks improve week on week. Tutorials run daily, at all times of the day, giving you the flexibility to book in whenever and however many times you need.",
                image: require("../frames/Frame3.webp")   
            },
            {
                heading1:"4. Access to world class study space",
                description1:"At Project Academy, we believe students do their best work when A.They love the work they do, 2. They love the people they do their work with, and 3. They love the space they work in. For the last 8 years, Project Academy has structured.",
                image: require("../frames/Frame4.webp")
            },
        ]
    },
    {
        heading: "2. Community Life at Project",
        description: "A Project education is so much more than coursework. It's late-night discussions in Thinkpad with friends, movie nights, dinners out at Chatswood - and much much more.",
        video: "https://www.youtube.com/embed/uH8TonYlvJk?autoplay=1&mute=1",
        points: [
            {
                heading1: "1. Your team, your family",
                description1: "The HSC can be an exhausting marathon. A long run is far better with friends, or even better, a team that has your back every step of the way. At Project Academy, we’re a team pushing each other to our very best.",
                image: require("../frames/Frame5.webp")
            },
            {
                heading1: "2. Breaks, meals and fun",
                description1: "Between all the hours of focused study and exam prep, we encourage our students to be themselves and have fun! Senior high school is a long marathon and you need your breaks to perform at your peak. Now, go take that nap, grab a healthy snack or head to the gym!",
                image: require("../frames/Frame6.webp") 
            },
            {
                heading1: "3. Your second home",
                description1: "Sometimes, we're unproductive at home and school. The internet at the library may be too slow, and cafes may be too noisy....where do you go? Project is open for you from 9am to 10pm, 7 days a week. When you need a place to study, a place to hang, or even rest - Project is open for you.",
                image: require("../frames/Frame7.webp")  
            },
        ]
    },
    {
        heading: "3. Support & Wellbeing",
        description: "The HSC is a marathon. Our highest priority is to make sure you have all the support you need, in and outside the classroom.",
        video: "https://www.youtube.com/embed/QG54bFhIasg?autoplay=1&mute=1",
        points: [
            {
                heading1: "1. Always here for you",
                description1: "Our teachers, admin and founders back our students every step of the way. They are always available to talk to and are always just a message away.",
                image: require("../frames/Frame8.webp")
            },
            {
                heading1: "2. Health & wellness sessions",
                description1: "Behind every HSC high achiever is a healthy body and healthy mind.",
                image: require("../frames/Frame9.webp") 
            },
            {
                heading1: "3. Peer support",
                description1: "Sometimes, we're unproductive at home and school. The internet at the library may be too slow, and cafes may be too noisy....where do you go? Project is open for you from 9am to 10pm, 7 days a week. When you need a place to study, a place to hang, or even rest - Project is open for you.",
                image: require("../frames/Frame10.webp")  
            },
        ]
    },
    {
        heading: "4. Careers & Partnerships",
        description: "The HSC is just one part of your life. There's a whole world waiting for you and our mission is to inspire you to continue doing amazing things after school and beyond.",
        video: "https://www.youtube.com/embed/UYA2UtiFY78?autoplay=1&mute=1",
        points: [
            {
                heading1: "1. Career development partnerships",
                description1: "Education is so much more than the textbook. We believe that students should think deeply about the world, dream outside the classroom and consider how they can improve the world we live in.",
                image: require("../frames/Frame11.webp")
            },
            {
                heading1: "2. Scholarships & uni workshops",
                description1: "Our students are supported in their professional or academic pursuits beyond the HSC. We have produced more UNSW and UTS Co-op Scholars than any school in Australia and our alumni dominate the ranks of scholarship recipients at universities like UNSW, USYD, ANU, MIT, Stanford and Oxford.",
                image: require("../frames/Frame12.webp")
            },
            {
                heading1: "3. Internships & jobs",
                description1: "Project connects (and also provides) internship and job opportunities to our talented students. Our designers, teachers, operations team are mostly Project Alumni - and some even work alongside their school studies! We believe in young people creating the future - and will support you in exploring your career opportunities.",
                image: require("../frames/Frame13.webp") 
            },
        ]
    },
    {
        heading: "5. Alumni Network",
        description: "“If I have seen further it is by standing on the shoulders of Giants.” - Sir Issac Newton. We’re here to lend you a “shoulder” and help you “see further” than you ever thought possible, through the HSC and into the wonderful life beyond school.",
        video: "https://www.youtube.com/embed/FP_3HrHlnHQ?autoplay=1&mute=1",
        points: [
            {
                heading1: "A global network of alumni",
                description1: "Project's alumni network spans thousands of talented students and young professionals globally. They're making a name for themselves - in the world of entrepreneurship, business, science, technology, law, politics, arts and more.",
                image: require("../frames/Frame20.webp")   
            },
            {
                heading1: "Robbie Ferguson",
                description1: "Robbie ('14) attained a 99.95 ATAR and three state ranks as Dux of Knox Grammer. He's a Thiel Fellow, Forbes 30 Under 30 recipient, and founder of Immutable, valued at $2.5 billion.",
                image: require("../frames/Frame14.webp") 
            },
            {
                heading1: "Adam Chew",
                description1: "Adam Chew ('15) from Newington College was a Youth Olympian Candidate and represented Australia for fencing. Today, Adam has graduated from a Bachelor of Commerce & Information Systems, and is working as a Program Manager at Amazon.",
                image: require("../frames/Frame16.webp") 
            },
            {
                heading1: "Hannah Ahn",
                description1: "Hannah ('18) was school captain at Chatswood High School. She was Canva's youngest Product Manager. She's also the founder of Next Chapter, a community for driven young people.",
                image: require("../frames/Frame15.webp") 
            },
            {
                heading1: "Bruce Zhang",
                description1: "Bruce ('17') was a Prefect at Baulkham Hills. He's now PhD student at University College London researching Healthy Ageing. He graduated 1st in Biological Sciences, as Valedictorian and recipient of the Faculty Medal. He's published 3 research papers in science journals and is also an established artist with paintings on exhibition in the UK and Australia.",
                image: require("../frames/Frame17.webp")    
            },
            {
                heading1: "Divya Ramesh",
                description1: "Divya was a graduate of Baulkahm Hills. She's a Software Engineering Manager of Australia's largest technology companies, building AI and data-science software for millions of users.",
                image: require("../frames/Frame18.webp") 
            },
        ]
    }
]

export {lifeatprojectData}