import React from 'react'

function Testimonial({testam}) {
  return (
    <>
            <>
            <div className="info-testimonial">
                <div className="info-testimonial-text">
                    {testam.testimonial}
                </div>
                <div className="info-testimonial-person">
                    <div className="info-testimonial-image">
                        <img src={testam.testimonial_img} />
                    </div>
                    <div className="info-testimonial-details">
                        <p className="info-testimonial-name">{testam.testimonial_name}</p>
                        <p className="info-testimonial-school">{testam.testimonial_achieve}</p>
                        <p className="info-testimonial-marks">{testam.testimonial_atar}</p>
                    </div>
                </div>
            </div>
            </>
    </>
  )
}

export default Testimonial