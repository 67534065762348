import React from 'react'
import imgTutors from '../assets/navbar/tutors.webp'

function NavbarDropdown_Subjects() {
  return (
<div className="dropper">
    <div className="dropdown">
        <div className="dropdown-container">
            <div className="dropdown-left">
              <p className="dropdown-heading">Project Academy Courses</p>
              <p className="dropdown-text">
                Project Academy's courses are one of the most effective HSC tuition programs in NSW.

                Every course has been carefully designed and refined by experts to consistently produce 99+ ATAR and state ranking students.

                If you are looking to achieve a high Band 6, this is the course for you.
              </p>
              <a className="dropdown-nav-link" href="/timetable">Timetable →</a>
            </div>
            <div className="dropdown-right">
              <div className="dropdown-right-top">
                <div className="dropdown-right-top-left">
                  <p className="dropdown-heading">State ranking teachers</p>
                  <p className="dropdown-text">
                    Project Academy is home to the most effective, passionate and highest achieving teachers in Australia.
                  </p>
                  <a className="dropdown-nav-link" href="/tutors">Meet Our Tutors →</a>

                </div>
                <div className="dropdown-right-top-right">
                  <img src={imgTutors} />
                </div>
              </div>
              <div className="dropdown-right-bottom">
                <div className="dropdown-right-bottom-col1">
                  <p className="dropdown-heading">Year 12</p>
                  <ul className="dropdown-subjects">
                      <li><a href={`${process.env.REACT_APP_HSCENGLISH}`}>Y12 English Advanced →</a></li>
                      <li><a href={`${process.env.REACT_APP_HSCECONOMICS}`}>Y12 Economics →</a></li>
                      <li><a href={`${process.env.REACT_APP_HSCCHEMISTRY}`}>Y12 Chemistry →</a></li>
                      <li><a href={`${process.env.REACT_APP_HSCPHYSICS}`}>Y12 Physics →</a></li>
                      <li><a href={`${process.env.REACT_APP_HSC4UMATHS}`}>Y12 Maths Extension 2 →</a></li>
                      <li><a href={`${process.env.REACT_APP_HSC3UMATHS}`}>Y12 Maths Extension 1 →</a></li>
                      <li><a href={`${process.env.REACT_APP_HSC2UMATHS}`}>Y12 Maths Advanced →</a></li>
                    </ul>
                </div>
                <div className="dropdown-right-bottom-col2">
                  <p className="dropdown-heading">Year 11</p>
                  <ul className="dropdown-subjects">
                    <li><a href={`${process.env.REACT_APP_Y11ENGLISH}`}>Y11 English Advanced →</a></li>
                    <li><a href={`${process.env.REACT_APP_Y11ECONOMICS}`}>Y11 Economics →</a></li>
                    <li><a href={`${process.env.REACT_APP_Y11CHEMISTRY}`}>Y11 Chemistry →</a></li>
                    <li><a href={`${process.env.REACT_APP_Y11PHYSICS}`}>Y11 Physics →</a></li>
                    <li><a href={`${process.env.REACT_APP_Y11MATHSACCEL}`}>Y11 Maths Accelerated →</a></li>
                    <li><a href={`${process.env.REACT_APP_Y11MATHSADV}`}>Y11 Maths Advanced →</a></li>
                  </ul>
                </div>
                <div className="dropdown-right-bottom-col3">
                  <p className="dropdown-heading">Year 10</p>
                  <ul className="dropdown-subjects">
                    <li><a href={`${process.env.REACT_APP_Y10MATHS}`}>Y10 Maths Advanced →</a></li>
                  </ul>
                </div>
                <div className="dropdown-right-bottom-col4">
                  <p className="dropdown-heading">Year 09</p>
                  <ul className="dropdown-subjects">
                    <li><a href={`${process.env.REACT_APP_Y9MATHS}`}>Y09 Maths Advanced →</a></li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>
    

  )
}

export default NavbarDropdown_Subjects