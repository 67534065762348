const faq_Normanhurst = [
    {
        question: "What is Normanhurst Boys High School's rank in the HSC?",
        answer: "Normanhurst Boys’ High school consistently achieves rankings within the top 20 in the HSC. In 2020, NBHS achieved rank 7 in all of NSW in the HSC. As a top selective high school, its students perform very well academically across most subjects each year."
    },
    {
        question: "Best Tutoring Centres in Northwest Sydney",
        answer: "Project Academy has a long and successful relationship with students of Normanhurst Boys’ High school. Each year, we help guide more than half of our students to achieve a >95 ATAR in the HSC, with the majority of our students achieving top band 6 grades in their respective subjects."
    },
    {
        question: "What is the best tutoring for Normanhurst Boys’ High School?",
        answer: "Project Academy has a long and successful relationship with students of Normanhurst Boys’ High school. 2019 Dux and school captain of Normanhurst, Marko Beocanin teaches English at our Chatswood campus. Each year, we help guide more than half of our students to achieve a >95 ATAR in the HSC, with the majority of our students achieving top band 6 grades in their respective subjects."
    },
    {
        question: "What are Normanhurst Boys High school Fees like?",
        answer: "Being a government-run public high school, tuition at Normanhurst Boys’ High is free! However there are some costs that need to be covered by parents over the course of a year. These include purchasing uniforms, parent’s association donations and school contribution fees and approximately add up to $1300 a year."
    },
    {
        question: "Is Normanhurst a good suburb?",
        answer: "Normanhurst is a good suburb to live in, with top schools such as Normanhurst Boys’ High school, and Loreto Normanhurst and well-serviced public transport. In addition to these, there are several good schools in nearby suburbs such as Pymble Ladies College in Pymble."
    },
    {
        question: "Is Normanhurst a good school?",
        answer: "Normanhurst Boys’ High school consistently achieves rankings within the top 20 in the HSC. As a top selective high school, its students perform very well academically across most subjects each year. Alumni include CEO of Fox Sports and founder of Sky Sports TV David Hill and Chief Judge in the NSW Supreme Court Peter McClellan."
    },
    {
        question: "What is Normanhurst Boys High school like? / What’s the student experience like at Normanhurst Boys?",
        answer: "Studying at Normanhurst Boys High school will offer you the opportunity to participate in a competitive school environment with like-minded students with whom you’ll achieve success in the HSC. Alongside your academic pursuits, NBHS also offers several extra-curriculars such as weekly musical band practice and participation in debating competitions."
    },
]

export {
    faq_Normanhurst
}