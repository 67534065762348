import React from 'react';
import HeroWide from '../components/HeroWide';
import GeneralFold from '../components/GeneralFold';
import CTA from '../components/CTA';
import SEO from '../components/SEO';

//Objects
import home_InfoOne from '../assets/InfoObjects'
import {lifeHero} from '../assets/heroObjects'

function LifeProject() {
  return (
    <>
        <SEO 
          title='Life at Project Academy - So Much More Than Tutoring'
          description="From your inspiring teachers and state ranking tutors to your friends and classmates, Project is a vibrant community where you can feel supported and encouraged."
          name='Project Academy'
        />
        <HeroWide heroInput={lifeHero} />
        <GeneralFold topic="lifeatproject" />
        <CTA />
    </>

  )
}

export default LifeProject