import React from 'react'
import Hero from '../../components/Hero';
import Tutors from '../../components/TutorSection';
import Info from '../../components/Info';
import Results from '../../components/Results';
import BlogSection from '../../components/BlogSection';
import TimetableSection from '../../components/TimetableSection';
import CourseSchedule from '../../components/CourseSchedule';
import CTA from '../../components/CTA';
import Pricing from '../../components/Pricing';
import SEO from '../../components/SEO';

//Objects
import { home_InfoOne, home_InfoTwo, home_InfoThree } from '../../assets/InfoObjects';
import { infoProvenY11MathsAdv, infoResourcesY11MathsAdv } from '../../assets/infoObjects/infoY11MathsAdv';

//Data
import { hero_Y11MathsAdv } from '../../assets/HeroText';
import { schedulesY11MathsAdv } from '../../assets/courseSchedules/schedulesY11';

function Y11MathsAdv() {

  return (
    <>
        <SEO 
          title='Year 11 Maths Advanced Tutoring | Project Academy'
          description='Year 11 Maths Advanced tutoring designed to maximise your chances of an upper Band 6. Access 99+ ATAR teachers, incredible resources and past papers.'
          name='Project Academy'
        />
        <Hero data={hero_Y11MathsAdv} />
        <Info data={infoProvenY11MathsAdv} />
        <Tutors data="HSC 2U Maths" />
        <Info data={infoResourcesY11MathsAdv} />
        <Info data={home_InfoTwo} />
        <CourseSchedule data={schedulesY11MathsAdv} />
        <TimetableSection data="Y11 Maths Advanced" />
        <Pricing data="Y11 Maths Adv" />
        <BlogSection />
        <CTA />
    </>
        
  )
}

export default Y11MathsAdv