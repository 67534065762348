const faq_NSG = [
    {
        question: "What’s the student experience at North Sydney Girls like?",
        answer: "Your time at North Sydney Girls High School will be one that provides you with a treasure trove of skills, experiences, memories and friends that will equip you to achieve higher and better at every stage of your life. North Sydney Girls offers experienced teachers and a competitive environment in which your academic abilities and confidence as a leader will be strongly fostered. You’ll find that the student community at North Sydney Girls will support you through your time at high school. In addition to this, North Sydney Girls offers a wide range of non-academic subjects to enrich students, such as participation in musical bands and sport teams."
    },
    {
        question: "Is North Sydney Girls a good school?",
        answer: "North Sydney Girls High School’s academic performance is very strong, with the school ranking within the top 20 schools in NSW in the HSC since 2000. The Sun-Herald ranked North Sydney Girls High School first in Australia's top ten girls' schools in 2001. Each year, large numbers of students from North Sydney Girls achieve band 6 grades in the HSC."
    },
    {
        question: "What is North Sydney Girls High School's rank in the HSC?",
        answer: "North Sydney Girls High school consistently achieves rankings within the top 10 in the HSC. In 2020, NSGHS achieved rank 4 in all of NSW in the HSC. As a top selective high school, its students perform very well academically across most subjects each year."
    },
    {
        question: "Do North Sydney Girls students often attend tutoring?",
        answer: "The majority of students of North Sydney Girls attend some form of coaching or tutoring. 1 in 3 NSGs studying for the HSC attend Project Academy, and every year, those who attend Project Academy have achieved top marks in the HSC."
    },
    {
        question: "What is the best tutoring for North Sydney Girls?",
        answer: "Each year, Project Academy helps to guide more than half of our students to achieve a 95+ ATAR in the HSC, with the majority of our students achieving top band 6 grades in their chosen subjects. Our long and successful relationship with North Sydney Girls High School means that we are able to skilfully target areas in which students may need the most assistance, and tailor our tutoring services to their curriculum and examinations. We also have numerous tutors that graduated from NSG who have real experience from attending the school and thus can offer comprehensive support and understanding to current students. As such, Project Academy can provide a personalised tutoring experience which will be most beneficial to North Sydney Girls student education."
    },
    {
        question: "What are North Sydney Girls High School fees like?",
        answer: "Being a government-run selective high school, tuition at North Sydney Girls is free! However there are some costs that need to be covered by parents over the course of a year. These include purchasing textbooks, uniforms, parent’s association donations and school contribution fees."
    },
    {
        question: "What’s Crows Nest suburb like?",
        answer: "Crows Nest is a pleasant quiet residential suburb that is suited for students’ families. Nearby suburbs North Sydney and St Leonards have excellent transport, shopping and recreational amenities and Sydney CBD is a short journey away."
    },
    {
        question: "Who are some alumni of North Sydney Girls?",
        answer: "Famous alumni of North Sydney Girls High School include: actress Nicole Kidman, actress Naomi Watts, NSW Supreme Court Judge Justice Lucy McCallum, medical pioneer Anna Katherine Donald and Olympian Elise Simone Ashton. The size of each annual cohort is roughly 150 students. Recent alumni are notable for being high achievers in academic fields such as 4 Unit Mathematics, 3 Unit Mathematics and Chemistry."
    },
]

export {
    faq_NSG
}