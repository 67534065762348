const faq_ChatswoodHigh = [
    {
        question: "What is the best tutoring for Chatswood High School?",
        answer: "Project Academy has a long and successful relationship with students of Chatswood High School. Each year, we help guide more than half of all our students to achieve a >95 ATAR in the HSC, with the majority of our students achieving top band 6 grades in their respective subjects."
    },
    {
        question: "What are Chatswood High School's fees like?",
        answer: "Being a government-run public high school, tuition at Chatswood High is free! However there are some costs that need to be covered by parents over the course of a year. These include purchasing textbooks, uniforms, parent’s association donations and school contribution fees."
    },
    {
        question: "What are some notable alumni of Chatswood High School?",
        answer: "Notable alumni of Chatswood High School include: artist Darren Percival and state MP Carl Scully. The size of each annual cohort is roughly 150 students."
    },
    {
        question: "What is Chatswood High School’s rank in the HSC?",
        answer: "Chatswood High consistently achieves rankings within the top 80 in the HSC. In 2020, Chatswood High achieved rank 69 in all of NSW in the HSC. In terms of academic performance, Chatswood High School and its students boast solid credentials."
    },
    {
        question: "Do Chatswood High School Students often get tutoring?",
        answer: "Many Chatswood High students attend some form of coaching or tutoring. And every year, many who attend Project Academy achieve highly in the HSC."
    },
    {
        question: "What’s Chatswood suburb like?",
        answer: "Chatswood is a vibrant suburb suited to all kinds of students and families’ living. Chatswood offers excellent trains, bus and road transport options, alongside a bustling CBD with a Westfield shopping centre. Nearby suburbs include leafy North Sydney and Crows Nest. Sydney CBD is quite close."
    },
    {
        question: "Is Chatswood High School a good school?",
        answer: "Chatswood High School’s academic performance is strong, with the school consistently ranking within the top 80 schools in the HSC for the past 5 years. Each year, many students from Chatswood High achieve band 6 grades in the HSC."
    },
    {
        question: "What’s the student experience at Chatswood High School like?",
        answer: "Your time at Chatswood High School will be one that provides you with a treasure trove of skills, experiences, memories and friends that will equip you to achieve higher and better at every stage of your life. Chatswood High offers good teachers and a competitive environment in which your academic abilities and confidence as a leader will be strongly fostered. You’ll find that the student community at Chatswood High will support you through your time at high school. In addition to this, Chatswood High School offers a wide range of non-academic activities to enrich students, such as participation in musical bands, weekly sport activities and more."
    },
]

export {
    faq_ChatswoodHigh
}