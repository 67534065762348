import React from 'react'

import VideoFold from '../components/VideoFold'
import IpadSection from '../components/IpadSection';
import Hero from '../components/Hero';
import CTA from '../components/CTA';
import SEO from '../components/SEO';

//Data - Info
import { Ipad_one, Ipad_two, Ipad_three } from '../assets/IpadObjects';
import { hero_Ipad } from '../assets/HeroText';

import "../components/IpadSection.scss"
// import collage from "../assets/ipad/Collage.jpg"

function Ipad() {

  return (
    <>
        <SEO 
          title='Project Academy iPad - The Ultimate HSC Companion'
          description="Project Academy's iPad is your ultimate HSC companion - loaded with exclusive HSC apps, the iPad gives you an unfair advantage from Year 9 to Year 12."
          name='Project Academy'
        />
        <Hero data={hero_Ipad} />
        <VideoFold />
        {/* <div className="container collage">
          <img src={collage} />
        </div> */}
        <div className="ipad-section-one">
          <IpadSection test={Ipad_one} />
        </div>
        <IpadSection test={Ipad_two} />
        <IpadSection test={Ipad_three} />
        <CTA />
    </>
  )
}

export default Ipad