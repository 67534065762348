import React from 'react'
import Hero from '../components/Hero'
import Profile from '../components/Profile'
import SEO from '../components/SEO'

import imgFreya from "../assets/featuredprofile/FreyaOsterberg.webp"
import profileFreya from "../assets/profile/Freya.jpg"

import imgCory from "../assets/featuredprofile/CoryAitchison.webp"
import profileCory from "../assets/profile/CoryAitchison.jpg"

import imgMarko from "../assets/featuredprofile/MarkoBeocanin.webp"
import profileMarko from "../assets/profile/Marko.jpg"

import cory2 from "../assets/featuredarticle/Cory2.jpg"
import marko2 from "../assets/featuredarticle/Marko2.jpg"
import freya1 from "../assets/featuredarticle/Freya1.jpg"

import { hero_Tutors } from '../assets/HeroText'
import { allProfiles } from '../assets/profileObjects'

import "./Tutorsandresults.scss"

function Tutorsandresults() {
  var tutorNameArray = [];
  var tutorArray = [];
  allProfiles?.map((tutor) => ( 
    (!tutorNameArray.includes(tutor.name) && tutor.subject !== "Alumni")
    ? (
      tutorNameArray.push(tutor.name),
      tutorArray.push(tutor)
      )
    : ""
  ));

  return (
    <>
      <SEO 
          title='Our Teachers | The Highest Performing Team'
          description="Project Academy is home to the highest performing and most effective tutors in NSW. Hand picked by our academic heads, our average ATAR is 99.5+."
          name='Project Academy'
        />
      <Hero data={hero_Tutors}/>
      <div className="container tutors-container">
          <div className="tutors-featured">
            <img src={imgCory} />
            <div className="tutors-featured-right">
              <p className="tutors-featured-tag">Featured</p>
              <p className="tutors-featured-name">Meet Cory</p>
              <p className="tutors-featured-paragraph">Cory graduated from Knox Grammar as Vice Captain and Dux, with 2 state ranks and a 99.95 ATAR. Considered one of the best physics teachers in NSW, he is known for his depth of knowledge and passionate teaching style.</p>
              <div className="tutors-publication-slider">
                <p className="publication-tag">Publications</p>
                <div className="publication-group">
                  {/* One div */}
                  <a href="https://www.projectacademy.nsw.edu.au/headline-articles/achieving-two-state-ranks-and-a-99-95-atar">
                    <div className="publication-div">
                      <img className="publication-img" src={profileCory} />
                      <div className="publication-text-group">
                        <p className="publication-title">Achieving 2 State Ranks, Dux and a 99.95 ATAR</p>
                        <p className="publication-paragraph">I’d be lying if I told you that I enjoyed sitting my HSC.</p>
                      </div>
                    </div>
                  </a>

                  {/* One div */}
                  <a href="https://www.projectacademy.nsw.edu.au/year-12-guides/a-state-rankers-guide-to-writing-20-20-economics-essays">
                  <div className="publication-div">
                      <img className="publication-img" src={cory2} />
                      <div className="publication-text-group">
                        <p className="publication-title">A State Ranker's Guide to 20/20 Economics Essays</p>
                        <p className="publication-paragraph">Believe it or not, at the beginning of Year 11 I initially struggled with economics.</p>
                      </div>
                  </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="tutors-group">
            {tutorArray?.slice(0,10).map((tutor) => (
                <Profile tutorName={tutor.name} tutorSubject={tutor.subject}/>
            ))}
          </div>
          <div className="tutors-featured">
            <img src={imgMarko} />
            <div className="tutors-featured-right">
              <p className="tutors-featured-tag">Featured</p>
              <p className="tutors-featured-name">Meet Marko</p>
              <p className="tutors-featured-paragraph">Marko graduated from Normanhurst Boys as School Captain and Dux with 3 state ranks and a 99.95 ATAR. With over 3 years experience as a teacher, he is known for his depth and breadth of knowledge, and is loved for his highly engaging teaching style. </p>
              <div className="tutors-publication-slider">
                <p className="publication-tag">Publications</p>
                <div className="publication-group">
                  {/* One div */}
                  <a href="https://www.projectacademy.nsw.edu.au/headline-articles/achieving-3-state-ranks-a-perfect-99-95-atar-and-being-school-captain">
                    <div className="publication-div">
                      <img className="publication-img" src={profileMarko} />
                      <div className="publication-text-group">
                        <p className="publication-title">Achieving 3 State Ranks, Dux and a 99.95 ATAR</p>
                        <p className="publication-paragraph">At times, Year 12 was the last lap of Mario Kart - where the likelihood of a blue-shell seems to triple...</p>
                      </div>
                    </div>
                  </a>

                  {/* One div */}
                  <a href="https://www.projectacademy.nsw.edu.au/headline-articles/a-state-rankers-guide-to-writing-20-20-english-advanced-essays">
                    <div className="publication-div">
                      <img className="publication-img" src={marko2} />
                      <div className="publication-text-group">
                        <p className="publication-title">A State Ranker's Guide to 20/20 HSC English Essays</p>
                        <p className="publication-paragraph">Essays are tough because every module has a different essay structure...</p>
                      </div>
                    </div>
                  </a>


                </div>
              </div>
            </div>
          </div>
          <div className="tutors-group">
            {tutorArray?.slice(11,16).map((tutor) => (
                <Profile tutorName={tutor.name} tutorSubject={tutor.subject}/>
            ))}
          </div>
          <div className="tutors-featured">
            <img src={imgFreya} />
            <div className="tutors-featured-right">
              <p className="tutors-featured-tag">Featured</p>
              <p className="tutors-featured-name">Meet Freya</p>
              <p className="tutors-featured-paragraph">Loved by hundreds of students, Freya is known for her highly engaging and energetic classes. She graduated from Hornsby Girls as an All-Rounder, ranking first in all levels of English.</p>
              <div className="tutors-publication-slider">
                <p className="publication-tag">Publications</p>
                <div className="publication-group">
                  <a href="https://www.youtube.com/watch?v=uYLcUB2ERB0&ab_channel=ProjectAcademy">
                    <div className="publication-div">
                      <img className="publication-img" src={freya1} />
                      <div className="publication-text-group">
                        <p className="publication-title">Analysing & Writing Essays about Poetry</p>
                        <p className="publication-paragraph">We know analysing and writing essays about poetry can be hard...</p>
                      </div>
                    </div>
                  </a>
                  
                  <a href="https://medium.com/projectacademy/hate-is-a-strong-word-a01dcdc788df">
                    <div className="publication-div">
                      <img className="publication-img" src={profileFreya} />
                      <div className="publication-text-group">
                        <p className="publication-title">Hate is a strong word.</p>
                        <p className="publication-paragraph">Hate is a strong word and that's why I use it. Perhaps it’s in response to the pervasive millennial-hating we endure...</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="tutors-group">
            {tutorArray?.slice(17,400).map((tutor) => (
                  <Profile tutorName={tutor.name} tutorSubject={tutor.subject}/>
            ))}
          </div>
      </div>
    </>

  )
}

export default Tutorsandresults