import React, {useState} from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./Info.scss";
import Testimonial from './Testimonial';

function Info({data}) {

    return (
    <div>
        <Tabs className="container info-container" defaultIndex={0} selectedTabClassName="tablist-selected">
            <div className="info-left">
                <div className="info-left-top">
                    <div className="info-header">
                        {data?.map((n) => (
                            n.section_heading
                            ? <h2 key={n.section_heading}>{n.section_heading}</h2>
                            : null
                        ))}
                    </div>
                    <TabList className="tablist">
                        {/* ? is required below to check if array exists */}
                        {data?.map((n) => (
                            n.heading
                            ? <Tab key={n.heading} className="tablist-item">{n.heading}</Tab>
                            : null
                        ))
                        }
                    </TabList>
                </div>
                <div className="info-left-bottom">
                {data?.map((n) => (
                    n.testimonial
                    ? <Testimonial testam={n} />
                    : null
                    
                ))}

                </div>
            </div>
            
            <div className="info-right">
            {/* ? is required below to check if array exists */}
            {data?.map((n) => (
                        
                <TabPanel>
                    {
                        n.img
                        ? <img className="info-right-image" src={n.img}/>
                        : (n.video)
                        ? <iframe className="info-video" width="100%" height="500" src={n.video} allowfullscreen></iframe>
                        : <iframe className="info-doc" src={n.doc} width="100%" height="480" allow="autoplay"></iframe>
                    }
                    
                    <div className="info-right-paragraph">
                        <div className="info-group-text">
                            <span className="info-paragraph-heading">{n.heading_para_1}</span>
                            <p>{n.para_1}</p>
                        </div>
                        {
                            n.heading_para_2
                            ? (<div className="info-group-text">
                                    <span className="info-paragraph-heading">{n.heading_para_2}</span>
                                    <p>{n.para_2}</p>
                                </div>)
                            : null
                        }
                        {
                            n.heading_para_3
                            ? (<div className="info-group-text">
                                    <span className="info-paragraph-heading">{n.heading_para_3}</span>
                                    <p>{n.para_3}</p>
                                </div>)
                            : null
                        }
                    </div>
                </TabPanel>
                ))
            }
            </div>
        </Tabs>
        
    </div>
  )
}

export default Info