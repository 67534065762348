import React from 'react'
import Hero from '../../components/Hero';
import Tutors from '../../components/TutorSection';
import Info from '../../components/Info';
import Results from '../../components/Results';
import BlogSection from '../../components/BlogSection';
import TimetableSection from '../../components/TimetableSection';
import SEO from '../../components/SEO';

//Objects
import { home_InfoOne, home_InfoTwo, home_InfoThree } from '../../assets/InfoObjects';
import { infoProvenY12English, infoResourcesY12English } from '../../assets/infoObjects/infoY12English';
import { schedulesY12English } from '../../assets/courseSchedules/schedulesHSC';
import { hero_english } from '../../assets/HeroText';
import CourseSchedule from '../../components/CourseSchedule';
import CTA from '../../components/CTA';
import Pricing from '../../components/Pricing';

function HSCEnglish() {

  return (
    <>
        <SEO 
          title='HSC English Advanced Tutoring | Project Academy'
          description='Year 12 HSC English Advanced tutoring designed to maximise your chances of an upper Band 6. Access 99+ ATAR teachers, incredible resources and past papers.'
          name='Project Academy'
        />
        <Hero data={hero_english} />
        <Info data={infoProvenY12English} />
        <Tutors data="HSC English" />
        <Info data={infoResourcesY12English} />
        <Info data={home_InfoTwo} />
        <CourseSchedule data={schedulesY12English} />
        <TimetableSection data="HSC English Advanced" />
        <Pricing data="HSC English" />
        <BlogSection />
        <CTA />
    </>
        
  )
}

export default HSCEnglish