import React from 'react'
import Hero from '../../components/Hero';
import Tutors from '../../components/TutorSection';
import Info from '../../components/Info';
import Results from '../../components/Results';
import BlogSection from '../../components/BlogSection';
import TimetableSection from '../../components/TimetableSection';
import CourseSchedule from '../../components/CourseSchedule';
import CTA from '../../components/CTA';
import Pricing from '../../components/Pricing';
import SEO from '../../components/SEO';

//Objects
import { home_InfoOne, home_InfoTwo, home_InfoThree } from '../../assets/InfoObjects';
import { infoProvenY9Maths, infoResourcesY9Maths } from '../../assets/infoObjects/infoY9Maths';

//Data
import { hero_Y9Maths } from '../../assets/HeroText';
import { schedulesY9Maths } from '../../assets/courseSchedules/schedulesJunior';

function Y9Maths() {

  return (
    <>
        <SEO 
          title='Year 9 Maths Tutoring | Project Academy'
          description='Year 9 Maths tutoring designed to maximise your chances of an upper Band 6. Access 99+ ATAR teachers, incredible resources and past papers.'
          name='Project Academy'
        />
        <Hero data={hero_Y9Maths} />
        <Info data={infoProvenY9Maths} />
        <Tutors data="Junior Maths" />
        <Info data={infoResourcesY9Maths} />
        <Info data={home_InfoTwo} />
        <CourseSchedule data={schedulesY9Maths} />
        <TimetableSection data="Y09 Maths Advanced" />
        <Pricing data="Y9 Maths" />
        <BlogSection />
        <CTA />
    </>
  )
}

export default Y9Maths