const pricingHSCEnglish = [
    {
        // Description
        description_one: "Approx.",
        description_two: "$52 hourly rate",
        description_three: "for the 3 hour weekly class. Unlimited Marking for no extra charge. Subscription pauses during holidays.",
        // Value props
        main_one: "At $669/month, no lock ins",
        main_two: "Class + unlimited marking",
        main_three: "Multi-subject discount",
        // Tick points
        point_one: "NSW's Top 1% Tutors",
        point_two: "Unlimited Tutorials",
        point_three: "NSW's Most Effective Courses",
        point_four: "Access to Project's iPad",
        point_five: "Access to Exclusive Resources",
        point_six: "Access to Project's Study Space",
    }
]

const pricingHSCEconomics = [
    {
        // Description
        description_one: "Approx.",
        description_two: "$34 hourly rate",
        description_three: "if you attend one 3 hour class and one 1.5 hour tutorial every week. Subscription pauses during holidays.",
        // Value props
        main_one: "At $669/month, no lock ins",
        main_two: "Class + unlimited tutorials",
        main_three: "Multi-subject discount available",
        // Tick points
        point_one: "3h Weekly Class",
        point_two: "All Resources with Project Academy iPad",
        point_three: "Online Messaging with Tutors",
        point_four: "Exams Marked by Tutors",
        point_five: "Homeworked Marked by Tutors",
        point_six: "Access to Unlimited Tutorials Daily",
        point_seven: "Access to Study Spaces",
    }
]

const pricingHSCChemistry = [
    {
        // Description
        description_one: "Approx.",
        description_two: "$34 hourly rate",
        description_three: "if you attend one 3 hour class and one 1.5 hour tutorial every week. Subscription pauses during holidays.",
        // Value props
        main_one: "At $669/month, no lock ins",
        main_two: "Class + unlimited tutorials",
        main_three: "Multi-subject discount available",
        // Tick points
        point_one: "3h Weekly Class",
        point_two: "All Resources with Project Academy iPad",
        point_three: "Online Messaging with Tutors",
        point_four: "Exams Marked by Tutors",
        point_five: "Homeworked Marked by Tutors",
        point_six: "Access to Unlimited Tutorials Daily",
        point_seven: "Access to Study Spaces",
    }
]

const pricingHSCPhysics = [
    {
        // Description
        description_one: "Approx.",
        description_two: "$34 hourly rate",
        description_three: "if you attend one 3 hour class and one 1.5 hour tutorial every week. Subscription pauses during holidays.",
        // Value props
        main_one: "At $669/month, no lock ins",
        main_two: "Class + unlimited tutorials",
        main_three: "Multi-subject discount available",
        // Tick points
        point_one: "3h Weekly Class",
        point_two: "All Resources with Project Academy iPad",
        point_three: "Online Messaging with Tutors",
        point_four: "Exams Marked by Tutors",
        point_five: "Homeworked Marked by Tutors",
        point_six: "Access to Unlimited Tutorials Daily",
        point_seven: "Access to Study Spaces",
    }
]

const pricingHSC4UMaths = [
    {
        // Description
        description_one: "Approx.",
        description_two: "$34 hourly rate",
        description_three: "if you attend one 3 hour class and one 1.5 hour tutorial every week. Subscription pauses during holidays.",
        // Value props
        main_one: "At $669/month, no lock ins",
        main_two: "Class + unlimited tutorials",
        main_three: "Multi-subject discount available",
        // Tick points
        point_one: "3h Weekly Class",
        point_two: "All Resources with Project Academy iPad",
        point_three: "Online Messaging with Tutors",
        point_four: "Exams Marked by Tutors",
        point_five: "Homeworked Marked by Tutors",
        point_six: "Access to Unlimited Tutorials Daily",
        point_seven: "Access to Study Spaces",
    }
]

const pricingHSC3UMaths = [
    {
        // Description
        description_one: "Approx.",
        description_two: "$34 hourly rate",
        description_three: "if you attend one 3 hour class and one 1.5 hour tutorial every week. Subscription pauses during holidays.",
        // Value props
        main_one: "At $669/month, no lock ins",
        main_two: "Class + unlimited tutorials",
        main_three: "Multi-subject discount available",
        // Tick points
        point_one: "3h Weekly Class",
        point_two: "All Resources with Project Academy iPad",
        point_three: "Online Messaging with Tutors",
        point_four: "Exams Marked by Tutors",
        point_five: "Homeworked Marked by Tutors",
        point_six: "Access to Unlimited Tutorials Daily",
        point_seven: "Access to Study Spaces",
    }
]

const pricingHSC2UMaths = [
    {
        // Description
        description_one: "Approx.",
        description_two: "$34 hourly rate",
        description_three: "if you attend one 3 hour class and one 1.5 hour tutorial every week. Subscription pauses during holidays.",
        // Value props
        main_one: "At $669/month, no lock ins",
        main_two: "Class + unlimited tutorials",
        main_three: "Multi-subject discount available",
        // Tick points
        point_one: "3h Weekly Class",
        point_two: "All Resources with Project Academy iPad",
        point_three: "Online Messaging with Tutors",
        point_four: "Exams Marked by Tutors",
        point_five: "Homeworked Marked by Tutors",
        point_six: "Access to Unlimited Tutorials Daily",
        point_seven: "Access to Study Spaces",
    }
]

const pricingY11English = [
    {
        // Description
        description_one: "Approx.",
        description_two: "$44 hourly rate",
        description_three: "for the 3 hour weekly class. Unlimited Marking for no extra charge. Subscription pauses during holidays.",
        // Value props
        main_one: "At $579/month, no lock ins",
        main_two: "Class + unlimited marking",
        main_three: "Multi-subject discount available",
        // Tick points
        point_one: "3h Weekly Class",
        point_two: "All Resources with Project Academy iPad",
        point_three: "Online Messaging with Tutors",
        point_four: "Exams Marked by Tutors",
        point_five: "Homeworked Marked by Tutors",
        point_six: "Access to Unlimited Tutorials Daily",
        point_seven: "Access to Study Spaces",
    }
]

const pricingY11Economics = [
    {
        // Description
        description_one: "Approx.",
        description_two: "$30 hourly rate",
        description_three: "if you attend one 3 hour class and one 1.5 hour tutorial every week. Subscription pauses during holidays.",
        // Value props
        main_one: "At $579/month, no lock ins",
        main_two: "Class + unlimited tutorials",
        main_three: "Multi-subject discount available",
        // Tick points
        point_one: "3h Weekly Class",
        point_two: "All Resources with Project Academy iPad",
        point_three: "Online Messaging with Tutors",
        point_four: "Exams Marked by Tutors",
        point_five: "Homeworked Marked by Tutors",
        point_six: "Access to Unlimited Tutorials Daily",
        point_seven: "Access to Study Spaces",
    }
]

const pricingY11Chemistry = [
    {
        // Description
        description_one: "Approx.",
        description_two: "$34 hourly rate",
        description_three: "if you attend one 2.5 hour class and one 1.5 hour tutorial every week. Subscription pauses during holidays.",
        // Value props
        main_one: "At $579/month, no lock ins",
        main_two: "Class + unlimited tutorials",
        main_three: "Multi-subject discount available",
        // Tick points
        point_one: "2.5h Weekly Class",
        point_two: "All Resources with Project Academy iPad",
        point_three: "Online Messaging with Tutors",
        point_four: "Exams Marked by Tutors",
        point_five: "Homeworked Marked by Tutors",
        point_six: "Access to Unlimited Tutorials Daily",
        point_seven: "Access to Study Spaces",
    }
]

const pricingY11Physics = [
    {
        // Description
        description_one: "Approx.",
        description_two: "$34 hourly rate",
        description_three: "if you attend one 2.5 hour class and one 1.5 hour tutorial every week. Subscription pauses during holidays.",
        // Value props
        main_one: "At $579/month, no lock ins",
        main_two: "Class + unlimited tutorials",
        main_three: "Multi-subject discount available",
        // Tick points
        point_one: "2.5h Weekly Class",
        point_two: "All Resources with Project Academy iPad",
        point_three: "Online Messaging with Tutors",
        point_four: "Exams Marked by Tutors",
        point_five: "Homeworked Marked by Tutors",
        point_six: "Access to Unlimited Tutorials Daily",
        point_seven: "Access to Study Spaces",
    }
]

const pricingY11MathsAccel = [
    {
        // Description
        description_one: "Approx.",
        description_two: "$30 hourly rate",
        description_three: "if you attend one 3 hour class and one 1.5 hour tutorial every week. Subscription pauses during holidays.",
        // Value props
        main_one: "At $579/month, no lock ins",
        main_two: "Class + unlimited tutorials",
        main_three: "Multi-subject discount available",
        // Tick points
        point_one: "3h Weekly Class",
        point_two: "All Resources with Project Academy iPad",
        point_three: "Online Messaging with Tutors",
        point_four: "Exams Marked by Tutors",
        point_five: "Homeworked Marked by Tutors",
        point_six: "Access to Unlimited Tutorials Daily",
        point_seven: "Access to Study Spaces",
    }
]

const pricingY11MathsAdv = [
    {
        // Description
        description_one: "Approx.",
        description_two: "$34 hourly rate",
        description_three: "if you attend one 2.5 hour class and one 1.5 hour tutorial every week. Subscription pauses during holidays.",
        // Value props
        main_one: "At $579/month, no lock ins",
        main_two: "Class + unlimited tutorials",
        main_three: "Multi-subject discount available",
        // Tick points
        point_one: "2.5h Weekly Class",
        point_two: "All Resources with Project Academy iPad",
        point_three: "Online Messaging with Tutors",
        point_four: "Exams Marked by Tutors",
        point_five: "Homeworked Marked by Tutors",
        point_six: "Access to Unlimited Tutorials Daily",
        point_seven: "Access to Study Spaces",
    }
]

const pricingY10Maths = [
    {
        // Description
        description_one: "Approx.",
        description_two: "$28 hourly rate",
        description_three: "for the 3 hour weekly class. Unlimited Additional Support provided for no extra charge. Subscription pauses during holidays.",
        // Value props
        main_one: "At $369/month, no lock ins",
        main_two: "Class + unlimited support",
        main_three: "Multi-subject discount available",
        // Tick points
        point_one: "3h Weekly Class",
        point_two: "All Resources with Project Academy iPad",
        point_three: "Online Messaging with Tutors",
        point_four: "Exams Marked by Tutors",
        point_five: "Homeworked Marked by Tutors",
        point_six: "Access to Study Spaces",
    }
]

const pricingY9Maths = [
    {
        // Description
        description_one: "Approx.",
        description_two: "$21 hourly rate",
        description_three: "for the 3 hour weekly class. Unlimited Additional Support provided for no extra charge. Subscription pauses during holidays.",
        // Value props
        main_one: "At $279/month, no lock ins",
        main_two: "Class + unlimited support",
        main_three: "Multi-subject discount available",
        // Tick points
        point_one: "3h Weekly Class",
        point_two: "All Resources with Project Academy iPad",
        point_three: "Online Messaging with Tutors",
        point_four: "Exams Marked by Tutors",
        point_five: "Homeworked Marked by Tutors",
        point_six: "Access to Study Spaces",
    }
]

export {
    pricingHSCEnglish,
    pricingHSCEconomics,
    pricingHSCChemistry,
    pricingHSCPhysics,
    pricingHSC4UMaths,
    pricingHSC3UMaths,
    pricingHSC2UMaths,
    pricingY11English,
    pricingY11Economics,
    pricingY11Chemistry,
    pricingY11Physics,
    pricingY11MathsAccel,
    pricingY11MathsAdv,
    pricingY10Maths,
    pricingY9Maths
}

