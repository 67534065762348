import React from 'react'
import "./LongHistory.scss";

function LongHistory({school}) {
  return (
    <div className="container history-container">
      {school?.map((n) => (
        <>
          <div className="history-left">
              <div className="history-left-top">
                  <h2>{n.header}</h2>
                  <p>{n.paragraph}</p>
              </div>
              <div className="history-left-bottom">
                  <h3>{n.headerTwo}</h3>
                  <p>{n.paragraphTwo}</p>
              </div>
          </div>
          <div className="history-right">
          <img src={n.image} />
          </div>
        </>
      ))}
    </div>
  )
}

export default LongHistory