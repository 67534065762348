import React from 'react'
import "./GeneralFold.scss";

import { lifeatprojectData } from '../assets/lifeatproject/lifeatprojectObjects';
import { chatswoodData } from '../assets/lifeatproject/chatswoodObjects';
import { tutorsHero } from '../assets/heroObjects';


function GeneralWideFold({topic}) {
    if (topic === "lifeatproject") topic = lifeatprojectData;
    if (topic === "chatswood") topic = chatswoodData;
    if (topic === "tutors") topic = tutorsHero;

    return (
    <div className="container">
    <div className="generalfold-group">
        {topic?.map((n) => (
            <>
            <div className="generalfold-container">
                <div className="generalfold-left">
                    <h2>{n.heading}</h2>
                    <p>{n.description}</p>
                    <div className="generalfold-list">
                        <p className="generalfold-list-contents">Contents</p>
                        {n.points?.map((i) => (
                            <p>{i.heading1}</p>
                        ))}
                    </div>
                </div>
                <div className="generalfold-right">
                    {n.video 
                    ? <iframe className="generalfold-video" width="100%" height="500" src={n.video} allowfullscreen autoplay></iframe>
                    : ""
                    }
                    <div className="general-full-group">
                    {n.points?.map((i) => (
                        <div className="general-full-info">
                            <img className="info-right-image" src={i.image} />
                            <div className="info-right-paragraph">
                                <div className="info-group-text-full">
                                    <span className="info-paragraph-heading">{i.heading1}</span>
                                    <p>{i.description1}</p>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
                </div>
            </>
        ))}
    </div>                   
    </div>
  )
}

export default GeneralWideFold