//HSC ENGLISH ADVANCED 
//HSC ENGLISH ADVANCED 
//HSC ENGLISH ADVANCED 
//HSC ENGLISH ADVANCED 
//HSC ENGLISH ADVANCED 

const schedulesY12English = [
    {
        heading: "Normal Schedule →",
        text: "Texts taught:",
        term1: 
            [
                "William Shakespeare - King Richard III and Al Pacino - Looking for Richard",
                "William Shakespeare - King Henry IV, Part 1",
                "Sylvia Plath - Ariel and Ted Hughes - Birthday Letters",
                "William Shakespeare - The Tempest and Margaret Atwood - Hag-Seed",
                "John Keats and Jane Campion Bright Star"
            ],
        term2:
            [
                "William Shakespeare - King Henry IV, Part 1",
                "T. S. Eliot Poetry",
                "Sylvia Plath - Ariel and Ted Hughes - Birthday Letters",
                "John Keats and Jane Campion Bright Star",
                "Good Night, and Good Luck",
            ],
        term3: 
            [
                "William Shakespeare - King Richard III and Al Pacino - Looking for Richard",
                "William Shakespeare - King Henry IV, Part 1",
                "Sylvia Plath - Ariel and Ted Hughes - Birthday Letters",
                "William Shakespeare - The Tempest and Margaret Atwood - Hag-Seed",
                "John Keats and Jane Campion Bright Star"
            ],
        term4:
            [
                "William Shakespeare - King Richard III and Al Pacino - Looking for Richard",
                "William Shakespeare - King Henry IV, Part 1",
                "Sylvia Plath - Ariel and Ted Hughes - Birthday Letters",
                "William Shakespeare - The Tempest and Margaret Atwood - Hag-Seed",
                "John Keats and Jane Campion Bright Star"
            ]
    },
]

//HSC Economics 
//HSC Economics 
//HSC Economics 
//HSC Economics 
//HSC Economics 

const schedulesY12Economics = [
    {
        heading: "Normal Schedule →",
        text: "",
        term1: 
            [
                "The Global Economy",
                "Exam and Review",
                "Australia's Place in the Global Economy",
            ],
        term2:
            [
                "Comprehensive Review & Half Yearly Prep",
                "Economic Issues",
                "Australia's Place in the Global Economy",
            ],
        term3: 
            [
                "Economic Policies and Management",
                "Exam and Review",
                "Comprehensive Review of HSC Course",
                "Comprehensive Trials Prep",
            ],
        term4:
            [
                "LEAP - Weekly Predicted HSC Exams",
                "LEAP Review - Weekly In-class Review of Exams",
            ]
    },
]

//HSC CHEMISTRY 
//HSC CHEMISTRY 
//HSC CHEMISTRY 
//HSC CHEMISTRY 
//HSC CHEMISTRY 

const schedulesY12Chemistry = [
    {
        heading: "Normal Schedule →",
        text: "",
        term1: 
            [
                "Equilibrium & Acid Reactions",
                "Exam and Review",
                "Acid & Base Reactions",
            ],
        term2:
            [
                "Acid & Base Reactions",
                "Exam & Review",
                "Organic Chemistry",
            ],
        term3: 
            [
                "Organic Chemistry",
                "Exam and Review",
                "Applying Chemical Ideas",
                "Comprehensive Review of HSC Course",
                "Comprehensive Trials Preparation"
            ],
        term4:
            [
                "LEAP - Weekly Predicted HSC Exams",
                "LEAP Review - Weekly In-class Review of Exams",
            ]
    },
]

//HSC PHYSICS
//HSC PHYSICS
//HSC PHYSICS
//HSC PHYSICS
//HSC PHYSICS

const schedulesY12Physics = [
    {
        heading: "Normal Schedule →",
        text: "",
        term1: 
            [
                "Mechanics",
                "Prac Sessions",
                "Exam A1 & A2 + Review",
                "Electromagnetism"
            ],
        term2:
            [
                "Electromagnetism",
                "Exam B1 & B2 + Review",
                "Nature of Light",
                "Exam C1 & C2 + Review"
            ],
        term3: 
            [
                "Universe to the Atom",
                "Comprehensive Review of HSC Course",
                "Comprehensive Trials Preparation"
            ],
        term4:
            [
                "LEAP - Weekly Predicted HSC Exams",
                "LEAP Review - Weekly In-class Review of Exams",
            ]
    },
]

//HSC 4U MATHS
//HSC 4U MATHS
//HSC 4U MATHS
//HSC 4U MATHS
//HSC 4U MATHS

const schedulesY124UMaths = [
    {
        heading: "Normal Schedule →",
        text: "",
        term1: 
            [
                "Complex Numbers",
                "Polynomials",
                "Exam & Review",
            ],
        term2:
            [
                "Proofs",
                "Vectors",
                "Integration",
                "Inequalities"
            ],
        term3: 
            [
                "Maths Induction",
                "Mechanics",
                "Comprehensive Review of HSC Course",
                "Comprehensive Trials Preparation"
            ],
        term4:
            [
                "LEAP - Weekly Predicted HSC Exams",
                "LEAP Review - Weekly In-class Review of Exams",
            ]
    },
]

//HSC 3U MATHS
//HSC 3U MATHS
//HSC 3U MATHS
//HSC 3U MATHS
//HSC 3U MATHS

const schedulesY123UMaths = [
    {
        heading: "Normal Schedule →",
        text: "",
        term1: 
            [
                "Polynomials",
                "Maths Induction",
                "Further Trigonometry",
                "Trigonometric Calculus",
                "Assessment Prep",
                "2U Statistics",
                "Exam & Review",
            ],
        term2:
            [
                "Combinatorics",
                "Further Calculus",
                "Differential Equations",
                "Exam B + Review",
                "Vectors",
                "Motion",
                "Related Rates",
            ],
        term3: 
            [
                "Further Sketching",
                "Exam & Review",
                "Binomial Theorem",
                "3U Statistics",
                "Inverse Trigonometry",
                "Comprehensive Review of HSC Course",
                "Comprehensive Trials Preparation"
            ],
        term4:
            [
                "LEAP - Weekly Predicted HSC Exams",
                "LEAP Review - Weekly In-class Review of Exams",
            ]
    },
    {
        heading: "North Sydney Girls Schedule →",
        text: "",
        term1: 
            [
                "Polynomials",
                "2U Statistics",
                "Combinatorics",
                "Assessment Prep",
                "Binomial Theorem",
                "Vectors",
                "Motion",
            ],
        term2:
            [
                "Further Trigonometry",
                "Trigonometric Calculus",
                "Further Calculus",
                "Maths Induction",
                "NSG Exam & Review",
                "Assessment Preparation",
                "2U Statistics",
                "Inverse Trigonometry",
                "Further Sketching",
            ],
        term3: 
            [
                "Further Sketching",
                "Related Rates",
                "Motion",
                "Differential Equations",
                "Revision",
                "NSG Exam & Review",
                "3U Statistics",
                "Comprehensive Review of HSC Course",
                "Comprehensive Trials Preparation"
            ],
        term4:
            [
                "LEAP - Weekly Predicted HSC Exams",
                "LEAP Review - Weekly In-class Review of Exams",
            ]
    }
]

//HSC 2U MATHS
//HSC 2U MATHS
//HSC 2U MATHS
//HSC 2U MATHS
//HSC 2U MATHS

const schedulesY122UMaths = [
    {
        heading: "Normal Schedule →",
        text: "",
        term1: 
            [
                "Geometric Apps of Differentiation",
                "Integration",
                "Assessment Prep",
                "Logs & Exponentials",
                "Exam & Review",
            ],
        term2:
            [
                "Series & Applications",
                "Curve Sketching",
                "Assessment Prep",
                "Further Optimisation",
                "Probability",
                "Statistics",
                "Exam & Review",
            ],
        term3: 
            [
                "Statistics",
                "Exam & Review",
                "Trigonometric Calculus",
                "Applications of Calculus",
                "Comprehensive Review of HSC Course",
                "Comprehensive Trials Preparation"
            ],
        term4:
            [
                "LEAP - Weekly Predicted HSC Exams",
                "LEAP Review - Weekly In-class Review of Exams",
            ]
    },
]

export {
    schedulesY12English,
    schedulesY12Economics,
    schedulesY12Chemistry,
    schedulesY12Physics,
    schedulesY124UMaths,
    schedulesY123UMaths,
    schedulesY122UMaths
};