import React from 'react'
import Hero from '../../components/Hero';
import Tutors from '../../components/TutorSection';
import Info from '../../components/Info';
import Results from '../../components/Results';
import BlogSection from '../../components/BlogSection';
import TimetableSection from '../../components/TimetableSection';
import CourseSchedule from '../../components/CourseSchedule';
import CTA from '../../components/CTA';
import Pricing from '../../components/Pricing';
import SEO from '../../components/SEO';

//Objects
import { home_InfoOne, home_InfoTwo, home_InfoThree } from '../../assets/InfoObjects';
import { infoProvenY10Maths, infoResourcesY10Maths } from '../../assets/infoObjects/infoY10Maths';

//Data
import { hero_Y10Maths } from '../../assets/HeroText';
import { schedulesY10Maths } from '../../assets/courseSchedules/schedulesJunior';

function Y10Maths() {

  return (
    <>
        <SEO 
          title='Year 10 Maths Tutoring | Project Academy'
          description='Year 10 Maths tutoring designed to maximise your chances of an upper Band 6. Access 99+ ATAR teachers, incredible resources and past papers.'
          name='Project Academy'
        />
        <Hero data={hero_Y10Maths} />
        <Info data={infoProvenY10Maths} />
        <Tutors data="Junior Maths" />
        <Info data={infoResourcesY10Maths} />
        <Info data={home_InfoTwo} />
        <CourseSchedule data={schedulesY10Maths} />
        <TimetableSection data="Y10 Maths Advanced" />
        <Pricing data="Y10 Maths" />
        <BlogSection />
        <CTA />
    </>
        
  )
}

export default Y10Maths