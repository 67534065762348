import React from 'react'
import Hero from '../../components/Hero';
import Tutors from '../../components/TutorSection';
import Info from '../../components/Info';
import Results from '../../components/Results';
import BlogSection from '../../components/BlogSection';
import TimetableSection from '../../components/TimetableSection';
import SEO from '../../components/SEO';

//Objects
import { home_InfoOne, home_InfoTwo, home_InfoThree } from '../../assets/InfoObjects';
import { infoProvenY12Economics } from '../../assets/infoObjects/infoY12Economics';

//Data
import { hero_economics } from '../../assets/HeroText';
import CourseSchedule from '../../components/CourseSchedule';
import CTA from '../../components/CTA';
import Pricing from '../../components/Pricing';
import { schedulesY12Economics } from '../../assets/courseSchedules/schedulesHSC';

function HSCEconomics() {


  return (
    <>
        <SEO 
          title='HSC Economics Tutoring | Project Academy'
          description='Year 12 HSC Economics tutoring designed to maximise your chances of an upper Band 6. Access 99+ ATAR teachers, incredible resources and past papers.'
          name='Project Academy'
        />
        <Hero data={hero_economics} />
        <Info data={infoProvenY12Economics} />
        <Tutors data="HSC Economics" />
        <Info data={home_InfoThree} />
        <Info data={home_InfoTwo} />
        <CourseSchedule data={schedulesY12Economics} />
        <TimetableSection data="HSC Economics" />
        <Pricing data={"HSC Economics"} />
        <BlogSection />
        <CTA />
    </>
        
  )
}

export default HSCEconomics