import React from 'react';
import Hero from '../components/Hero';
import Tutors from '../components/TutorSection';
import Info from '../components/Info';
import Alumni from '../components/Alumni';
import Results from '../components/Results';
import BlogSection from '../components/BlogSection';
import CTA from '../components/CTA';
import Profile from '../components/Profile';

import SEO from '../components/SEO';

//Data - Info
import { home_InfoOne } from '../assets/InfoObjects';
import { home_InfoTwo } from '../assets/InfoObjects';
import { home_InfoThree } from '../assets/InfoObjects';

//Data - Hero
import { hero_home } from '../assets/HeroText';


function Home() {

  return (
    <>
        <SEO 
          title='Project Academy: Year 9 - 12 HSC Tutoring Experts'
          description='Project Academy is a tutoring centre for Year 9 to Year 12 students and is home to NSW&#x27;s most passionate and inspirational teachers – across maths, chemistry, english, economics and physics.'
          name='Project Academy'
        />
        <Hero data={hero_home} />
        <Results />
        <Alumni data="Alumni" />
        <Info data={home_InfoOne} />
        <Tutors data="General" />
        <Info data={home_InfoTwo} />
        <Info data={home_InfoThree} />
        <BlogSection />
        <CTA />
    </>

  )
}

export default Home;