const faq_Pymble = [
    {
        question: "What’s the student experience at Pymble Ladies' College like?",
        answer: "Your time at Pymble Ladies’ College will be one that provides you with a treasure trove of skills, experiences, memories and friends that will equip you to achieve higher and better at every stage of your life. Pymble Ladies’ College offers good teachers and a competitive environment in which your academic abilities and confidence as a leader will be strongly fostered. You’ll find that the student community at Pymble Ladies’ College will support you through your time at high school. In addition to this, Pymble Ladies’ College offers a wide range of non-academic subjects to enrich students, such as participation in musical bands."
    },
    {
        question: "Is Pymble Ladies' College a good school?",
        answer: "Pymble Ladies’ College’s academic performance is very strong, with the school ranking within the top 40 schools in NSW in the HSC since 2010. Each year, large numbers of students from North Sydney Girls achieve band 6 grades in the HSC."
    },
    {
        question: "What’s Pymble suburb like?",
        answer: "Pymble is a pleasant quiet residential suburb that is suited for students’ families. Nearby suburbs Hornsby, Lindfield and Macquarie Park, which have excellent transport, shopping and recreational amenities. Sydney CBD is a short journey away."
    },
    {
        question: "What is the best tutoring for Pymble Ladies’ College",
        answer: "Project Academy has a long and successful relationship with students of Pymble Ladies’ College. Each year, we help guide more than half of our students to achieve a >95 ATAR in the HSC, with the majority of our students achieving top band 6 grades in their respective subjects."
    },
    {
        question: "Do Pymble Ladies’ College (PLC) students often get tutoring?",
        answer: "The majority of students of Pymble Ladies’ College attend some form of coaching or tutoring. And every year, many who attend Project Academy have achieved highly in the HSC."
    },
    {
        question: "What is Pymble Ladies’ College rank in the HSC?",
        answer: "Pymble Ladies’ College consistently achieves rankings within the top 50 in the HSC. In 2020, PLC achieved rank 31 in all of NSW in the HSC. As a top private high school, its students perform very well academically across most subjects each year."
    },
    {
        question: "What are some famous alumni of Pymble Ladies’ College?",
        answer: "Famous alumni of Pymble Ladies’ College include: rugby olympic gold medalist Chloe Dalton and Nine News presenter Amber Higlett. The size of each annual cohort is roughly 250 students. Recent alumni are notable for being high achievers in academic fields such as 4 Unit Mathematics, 3 Unit Mathematics and Chemistry."
    },
    {
        question: "What are Pymble Ladies’ College fees like?",
        answer: "Pymble Ladies’ College is a private school, with annual tuition fees of about AUD33,000. Other costs that need to be covered by parents include purchasing textbooks, uniforms, parent’s association donations and school contribution fees."
    },
]

export {
    faq_Pymble
}