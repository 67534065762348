import React from 'react'

import './BlogArticleWide.scss'

function BlogArticleWide({subject, topic, desc, img, link}) {
  return (
    <>
        <a href={link}>
            <div className="blog-article-wide-group">
                <div className="blog-article-wide-top">
                    <img src={img} />
                </div>
                <div className="blog-article-wide-bottom">
                    <p className="blog-article-wide-subject">{subject}</p>
                    <p className="blog-article-wide-topic">{topic}</p>
                    <p className="blog-article-wide-paragraph">{desc}</p>
                </div>
            </div>
        </a>
    </>
    
  )
}

export default BlogArticleWide