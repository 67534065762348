import React from 'react'

import './BlogHero.scss'

function BlogHero() {
  return (
    <>
        <div className="blog-hero">
            <div className="blog-hero-text-group">
                <h1 className="hero-text">Trials and HSC Exam Preparation Collection</h1>
                <p className="blog-hero-under-text">Resources written and curated by our 99+ ATAR and state ranking tutors.</p>
            </div>
        </div>
    </>
  )
}

export default BlogHero