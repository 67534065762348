import React from 'react'
import "./Pricing.scss"

import {
    pricingHSCEnglish,
    pricingHSCEconomics,
    pricingHSCChemistry,
    pricingHSCPhysics,
    pricingHSC4UMaths,
    pricingHSC3UMaths,
    pricingHSC2UMaths,
    pricingY11English,
    pricingY11Economics,
    pricingY11Chemistry,
    pricingY11Physics,
    pricingY11MathsAccel,
    pricingY11MathsAdv,
    pricingY10Maths,
    pricingY9Maths
} from "../assets/pricing/pricingObjects"

function Pricing({data}) {
    if (data === "HSC English") data = pricingHSCEnglish;
    if (data === "HSC Economics") data = pricingHSCEconomics;
    if (data === "HSC Chemistry") data = pricingHSCChemistry;
    if (data === "HSC Physics") data = pricingHSCPhysics;
    if (data === "HSC 4U Maths") data = pricingHSC4UMaths;
    if (data === "HSC 3U Maths") data = pricingHSC3UMaths;
    if (data === "HSC 2U Maths") data = pricingHSC2UMaths;
    if (data === "Y11 English") data = pricingY11English;
    if (data === "Y11 Economics") data = pricingY11Economics;
    if (data === "Y11 Chemistry") data = pricingY11Chemistry;
    if (data === "Y11 Physics") data = pricingY11Physics;
    if (data === "Y11 Maths Accel") data = pricingY11MathsAccel;
    if (data === "Y11 Maths Adv") data = pricingY11MathsAdv;
    if (data === "Y10 Maths") data = pricingY10Maths;
    if (data === "Y9 Maths") data = pricingY9Maths;

    return (
    <div className="container">
        <div className="pricing-container">
            <div className="pricing-top">
                <div className="pricing-top-left">
                    <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/611e2249abf89544fbca3c78_Academy-Bg.png" />
                </div>
                    <div className="pricing-top-right">
                        <div className="pricing-right-one">
                            <h2>Join Project Academy</h2>
                            <p>{data[0].description_one}<span className="pricing-red">&nbsp;{data[0].description_two}&nbsp;</span>{data[0].description_three}</p>
                        </div>
                        <div className="pricing-right-two">
                            <p className="purple"><b>{data[0].main_one}</b></p>
                            <p>{data[0].main_two}</p>
                            <p>{data[0].main_three}</p>
                        </div>
                        <div className="pricing-right-three">
                            <div className="pricing-point-group">
                                <div className="pricing-one">
                                    <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60e6b9c3dbc2d5c8d4e269dd_Tick.png" />
                                    <p>{data[0].point_one}</p>
                                </div>
                                <div className="pricing-two">
                                    <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60e6b9c3dbc2d5c8d4e269dd_Tick.png" />
                                    <p>{data[0].point_two}</p>
                                </div>
                                <div className="pricing-three">
                                    <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60e6b9c3dbc2d5c8d4e269dd_Tick.png" />
                                    <p>{data[0].point_three}</p>
                                </div>
                                <div className="pricing-four">
                                    <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60e6b9c3dbc2d5c8d4e269dd_Tick.png" />
                                    <p>{data[0].point_four}</p>
                                </div>
                                <div className="pricing-five">
                                    <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60e6b9c3dbc2d5c8d4e269dd_Tick.png" />
                                    <p>{data[0].point_five}</p>
                                </div>
                                <div className="pricing-six">
                                    <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60e6b9c3dbc2d5c8d4e269dd_Tick.png" />
                                    <p>{data[0].point_six}</p>
                                </div>
                                {data[0].point_seven
                                ?
                                    (
                                        <div className="pricing-seven">
                                            <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60e6b9c3dbc2d5c8d4e269dd_Tick.png" />
                                            <p>{data[0].point_seven}</p>
                                        </div>
                                    )
                                : ""
                                }
                            </div>
                        </div>
                        <div className="pricing-right-four">
                            <a className="pricing-cta" href={`${process.env.REACT_APP_WT_LINK}`}><p>Secure your 3 week trial</p></a>
                        </div>
                    </div>
                
            </div>
            <div className="pricing-bottom"></div>
        </div>
    </div>
  )
}

export default Pricing