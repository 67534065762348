import React from 'react'
import "./TutorSection.css"
import { allProfiles } from '../assets/profileObjects'

function Profile({tutorName, tutorSubject}) {
  return (
    <div>
      {allProfiles?.map((tutor) => (
          ((tutorName === tutor.name) && (tutorSubject === tutor.subject))
          ? 
          (
            <>
              <div className="profile">
                <div className="profile-img-container">
                  <img className="profile-img" src={tutor.img} />
                </div>
                <div className="profile-data">
                  <p className="profile-name">{tutor.name}</p>
                  <p className="profile-atar">{tutor.ATAR}</p>
                  <p className="profile-dux">{tutor.school}</p>
                  <div className="profile-stat-group">
                    <p className="profile-stat">
                      <span className="purple">{tutor.line1[0]}</span>&nbsp;{tutor.line1[1]}
                    </p>
                    <p className="profile-stat">
                      <span className="purple">{tutor.line2[0]}</span>&nbsp;{tutor.line2[1]}
                    </p>
                    <p className="profile-stat">
                      <span className="purple">{tutor.line3[0]}</span>&nbsp;{tutor.line3[1]}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )
          : ""
          ))}
    </div>
  )
}

export default Profile