// HSC ENGLISH ADVANCED
// HSC ENGLISH ADVANCED
// HSC ENGLISH ADVANCED
// HSC ENGLISH ADVANCED
// HSC ENGLISH ADVANCED

const infoProvenY11English = [
    {
        heading: "1. Weekly Text Specific Masterclass →",
        heading_para_1: "NSW's Top Teachers",
        para_1: "Learn from a HSC English state-ranker, first in course, or experienced teacher.",
        heading_para_2: "Skills Based",
        para_2: "In class, perfect the skills reuired to achieve a high Band 6 in Year 12.",
        heading_para_3: "Discussion Based",
        para_3: "A unique, interesting, and highly-effective discussion-based approach to HSC English.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/61025ea884b1bf1f7fbe6d43_Home%20Section%20A%20Slide%2011.png"
    },
    {
        heading: "2. Unlimited Essay Marking →",
        heading_para_1: "Unlimited Marking",
        para_1: "Whether it's 20 or 200 times, we'll mark your essays until they're state ranking material.",
        heading_para_2: "Detailed Feedback",
        para_2: "Detailed essay annotations that push your thinking and abilities to Band 6 and beyond.",
        heading_para_3: "NSW's Best Markers",
        para_3: "Your marker is a teacher who achieved a Band 6 or state rank in English Adv.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/61025c09e68a664a25e2e3b8_Home%20Section%20A%20Slide%209.png"
    },
    {
        heading: "3. Daily Support till 10pm →",
        heading_para_1: "Ask Anything",
        para_1: "Got questions? Every night from 8 - 10pm, we run homework help sessions called Thinktank.",
        heading_para_2: "Any Subject",
        para_2: "Whether it's English, Maths, Science, Economics... you can get help with it all. ",
        heading_para_3: "School Work Help",
        para_3: "Need help with work from school? No problem. Your tutor is there to help you.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60c71d4d4b03cb8925ffb62d_Home%20Section%20A%20Slide%203-p-1080.png"
    },
    {
        heading: "4. Exam Perfection (LEAP) →",
        heading_para_1: "12 Predicted HSC Exams",
        para_1: "12 predicted HSC exams that covers almost every question you can possibly be asked.",
        heading_para_2: "Exam Conditions",
        para_2: "12 HSC simulations that make the actual HSC feel like a practice exam.",
        heading_para_3: "Detailed Exam Review",
        para_3: "Unlimited one-on-one review sessions with your marker to learn from your mistakes.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60c71e81d3399ee4352927bd_Home%20Section%20A%20Slide%205%20(1).png"
    },
    {
        testimonial: `"Academic success has and will always be predicated on self motivation... the light-hearted, supportive and collaborative culture of Project has provided the catalyst for self-determination and ultimately improving my marks"`,
        testimonial_name: "Jason Lin",
        testimonial_achieve: "Dux of Knox Grammar '20",
        testimonial_atar: "99.95 ATAR, State Ranker",
        testimonial_img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60efb31f45789a7a206c888f_Jason.png"
    },
    {
        section_heading: "A Proven Approach to Y11 English"
    }
]

// HSC RESOURCES
// HSC RESOURCES
// HSC RESOURCES
// HSC RESOURCES
// HSC RESOURCES

const infoResourcesY11English = [
    {
        heading: "1. In-depth Weekly Notes →",
        heading_para_1: "In-depth Weekly Notes",
        para_1: "For each text of study in the Common Module, Mod A and B, receive highly detailed notes written by our 99+ ATAR and state ranking tutors. For Mod C, expect detailed notes for each of the four text types: Creative, Discursive, Persuasive and Informative.",
        heading_para_2: "Purposefully Structured",
        para_2: "All notes are accessed through the Project Books app. The app organises the notes by lesson and by topic to the syllabus so you know exactly where to focus on when preparing for an assessment.",
        doc: "https://drive.google.com/file/d/1bSI064eauIdWZoxomJosN33xu4IX5nrf/preview"
    },
    {
        heading: "2. State Ranker Notes & Exemplars →",
        heading_para_1: "20/20 Essay Exemplars",
        para_1: "Access dozens of 20/20 essays and creative exemplars written by our 99+ ATAR and state ranking team.",
        heading_para_2: "State Ranker Level Notes",
        para_2: "Access hundreds of 20/20 essay plans, essay scaffolds, summary sheets and more for each subject.",
        doc: "https://docs.google.com/file/d/1zYAgFS0173-la2LJPDHojQKMoKkA8EuC/preview"
    },
    {
        heading: "3. Unlimited Marking →",
        heading_para_1: "Detailed Marking",
        para_1: "We don't mark in batch like others do. You'll get detailed markups and feedback on all your weekly homework submissions because a tick or cross just doesn't cut it.",
        heading_para_2: "Personalised & Tracked",
        para_2: "We know you on an individual level and personalise our marking. All mistakes are tracked via an app so we know exactly what topics you're struggling with.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/6107b52626820d3ce733cd6f_Frame%20132%20(1).png"
    },
    {
        heading: "4. 300+ English Past Papers →",
        heading_para_1: "Hundreds of Past Papers",
        para_1: "Access thousands of exclusive past papers collected from NSW schools over the past 10 years. They cover all assignments and assessments examined.",
        heading_para_2: "Example Solutions Included",
        para_2: "We provide detailed solutions in the form of scaffolds or exemplars for our past papers, so you can confidently work through and reference to level up.",
        doc: "https://drive.google.com/file/d/1ELc-WdlANo1opbCGYVIoaCRfksx-3oql/preview"
    },
    {
        testimonial: "Academic success has and will always be predicated on self motivation... the light-hearted, supportive and collaborative culture of Project has provided the catalyst for self-determination and ultimately improving my marks",
        testimonial_name: "Jason Lin",
        testimonial_achieve: "Dux of Knox Grammar '20",
        testimonial_atar: "99.95 ATAR, 3rd in NSW for Chemistry",
        testimonial_img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60efb31f45789a7a206c888f_Jason.png"
    },
    {
        section_heading: "The Right Resources"
    }
]
export {
    infoProvenY11English,
    infoResourcesY11English
}
