import React from 'react'
import Hero from '../../components/Hero';
import Tutors from '../../components/TutorSection';
import Info from '../../components/Info';
import Results from '../../components/Results';
import BlogSection from '../../components/BlogSection';
import TimetableSection from '../../components/TimetableSection';
import CourseSchedule from '../../components/CourseSchedule';
import CTA from '../../components/CTA';
import Pricing from '../../components/Pricing';
import SEO from '../../components/SEO';

//Objects
import { home_InfoOne, home_InfoTwo, home_InfoThree } from '../../assets/InfoObjects';
import { infoProvenY11Chemistry, infoResourcesY11Chemistry } from '../../assets/infoObjects/infoY11Chemistry';

//Data
import { hero_Y11Chemistry } from '../../assets/HeroText';
import { schedulesY11Chemistry } from '../../assets/courseSchedules/schedulesY11';

function Y11Chemistry() {

  return (
    <>
        <SEO 
          title='Year 11 Chemistry Tutoring | Project Academy'
          description='Year 11 Chemistry tutoring designed to maximise your chances of an upper Band 6. Access 99+ ATAR teachers, incredible resources and past papers.'
          name='Project Academy'
        />
        <Hero data={hero_Y11Chemistry} />
        <Info data={infoProvenY11Chemistry} />
        <Tutors data="HSC Chemistry" />
        <Info data={infoResourcesY11Chemistry} />
        <Info data={home_InfoTwo} />
        <CourseSchedule data={schedulesY11Chemistry} />
        <TimetableSection data="Y11 Chemistry" />
        <Pricing data="Y11 Chemistry" />
        <BlogSection />
        <CTA />
    </>
        
  )
}

export default Y11Chemistry