import React from 'react';
import "./HeroWide.scss";

function HeroWide({heroInput}) {
  return (
    <>
      {heroInput?.map((n, i) => (
        <div className="hero-wide-home-bg" style={{backgroundImage: `url(${n.hero_bg_image})`}}>
          <div className="container">
            <div className="hero-wide">
                <h1 className="hero-wide-text">{n.heading}</h1>
                <div className="hero-wide-text-group">
                  {n.heroPoints?.map((i) => (
                    <p className="hero-wide-point">{i}</p>
                  ))}
                </div>
                <a href={`${process.env.REACT_APP_WT_LINK}`}>
                  <div className="hero-wide-button">Take the 3 week trial</div>
                </a>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default HeroWide