import React from 'react'
import "./Footer.scss"

function Footer() {
  return (
    <div className="footer-container">
      <div className="container">
        <div className="footer-top">
          <div className="footer-left">
            <p className="footer-header">PROJECT ACADEMY</p>
            <p className="footer-paragraph">Our mission is to help our students ace the HSC and help them perform at a higher level than they ever thought possible for themselves.</p>
          </div>
          <div className="footer-right">
            <div className="footer-right-col">
              <div className="footer-right-col-header">PROGRAMS</div>
              <ul>
                <li><a href="/procon-masterclasses">PROCON</a></li>
                <li><a href="/leap">LEAP</a></li>
                <li><a href="/timetable">Timetable</a></li>
              </ul>
            </div>
            <div className="footer-right-col">
              <div className="footer-right-col-header">ABOUT US</div>
              <ul>
                <li><a href="/life-at-project">Life at Project</a></li>
                <li><a href="/tutors">Our Team</a></li>
                <li><a href="/chatswood">Our Campus</a></li>
                <li><a href="/contact">Contact Us</a></li>
              </ul>
            </div>
            <div className="footer-right-col">
              <div className="footer-right-col-header">RESOURCES</div>
              <ul>
                <li><a href="https://projectacademy.nsw.edu.au/thoughts">Thoughts</a></li>
                <li><a href="/school">Our Schools</a></li>
              </ul>
            </div>
            <div className="footer-right-col">
              <div className="footer-right-col-header">YEAR 12</div>
              <ul>
                <li><a href={`${process.env.REACT_APP_HSCENGLISH}`}>HSC English Adv.</a></li>
                <li><a href={`${process.env.REACT_APP_HSCECONOMICS}`}>HSC Economics</a></li>
                <li><a href={`${process.env.REACT_APP_HSCCHEMISTRY}`}>HSC Chemistry</a></li>
                <li><a href={`${process.env.REACT_APP_HSCPHYSICS}`}>HSC Physics</a></li>
                <li><a href={`${process.env.REACT_APP_HSC4UMATHS}`}>HSC Maths 4U</a></li>
                <li><a href={`${process.env.REACT_APP_HSC3UMATHS}`}>HSC Maths 3U</a></li>
                <li><a href={`${process.env.REACT_APP_HSC2UMATHS}`}>HSC Maths 2U</a></li>
              </ul>
            </div>
            <div className="footer-right-col">
              <div className="footer-right-col-header">YEAR 11</div>
              <ul>
                <li><a href={`${process.env.REACT_APP_Y11ENGLISH}`}>Y11 English Adv.</a></li>
                <li><a href={`${process.env.REACT_APP_Y11ECONOMICS}`}>Y11 Economics</a></li>
                <li><a href={`${process.env.REACT_APP_Y11CHEMISTRY}`}>Y11 Chemistry</a></li>
                <li><a href={`${process.env.REACT_APP_Y11PHYSICS}`}>Y11 Physics</a></li>
                <li><a href={`${process.env.REACT_APP_Y11MATHSADV}`}>Y11 Maths 3U</a></li>
                <li><a href={`${process.env.REACT_APP_Y11MATHSACCEL}`}>Y11 Maths 2U</a></li>
              </ul>
            </div>
            <div className="footer-right-col">
              <div className="footer-right-col-header">YEAR 10&09</div>
              <ul>
                <li><a href={`${process.env.REACT_APP_Y10MATHS}`}>Y10 Maths Adv.</a></li>
                <li><a href={`${process.env.REACT_APP_Y9MATHS}`}>Y09 Maths Adv.</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-mid"></div>
        <div className="footer-bottom">
          <div><a href="/careers">Careers at Project Academy</a></div>
          <div><a href="/privacy-policy">Privacy Policy</a></div>
          <div className="copyright">© 2023 Project Academy. All Rights Reserved.</div>
        </div>
      </div>
    </div>
  )
}

export default Footer