import React from 'react'
import Hero from '../../components/Hero';
import Tutors from '../../components/TutorSection';
import Info from '../../components/Info';
import Results from '../../components/Results';
import BlogSection from '../../components/BlogSection';
import TimetableSection from '../../components/TimetableSection';
import CourseSchedule from '../../components/CourseSchedule';
import CTA from '../../components/CTA';
import Pricing from '../../components/Pricing';
import SEO from '../../components/SEO';

//Objects
import { home_InfoOne, home_InfoTwo, home_InfoThree } from '../../assets/InfoObjects';
import { infoProvenY11MathsAccel, infoResourcesY11MathsAccel } from '../../assets/infoObjects/infoY11MathsAccel';

//Data
import { hero_Y11MathsAccel } from '../../assets/HeroText';
import { schedulesY11MathsAccel } from '../../assets/courseSchedules/schedulesY11';

function Y11MathsAccel() {

  return (
    <>
        <SEO 
          title='Year 11 Maths Accelerated Tutoring | Project Academy'
          description='Year 11 Maths Accelerated tutoring designed to maximise your chances of an upper Band 6. Access 99+ ATAR teachers, incredible resources and past papers.'
          name='Project Academy'
        />
        <Hero data={hero_Y11MathsAccel} />
        <Info data={infoProvenY11MathsAccel} />
        <Tutors data="HSC 3U Maths" />
        <Info data={infoResourcesY11MathsAccel} />
        <Info data={home_InfoTwo} />
        <CourseSchedule data={schedulesY11MathsAccel} />
        <TimetableSection data="Y11 Maths Accelerated" />
        <Pricing data="Y11 Maths Accel" />
        <BlogSection />
        <CTA />
    </>
        
  )
}

export default Y11MathsAccel