// HSC ECONOMICS
// HSC ECONOMICS
// HSC ECONOMICS
// HSC ECONOMICS
// HSC ECONOMICS

const infoProvenY12Economics = [
    {
        heading: "1. Weekly Live Masterclass →",
        heading_para_1: "NSW's Top Teachers",
        para_1: "Learn from a HSC Economics state-ranker, first in course, or highly experienced teacher.",
        heading_para_2: "Exam Oriented",
        para_2: "Receive detailed syllabus breakdowns alongside exam style questions and thorough ",
        heading_para_3: "Economic Updates",
        para_3: "Every fortnight, you receive summaries of key economic news from around the world.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60fe99d9a4cf33b1d74817b8_Frame%2073.png"
    },
    {
        heading: "2. Weekly HSC Eco Tutorials →",
        heading_para_1: "Personalised Sessions",
        para_1: "With a tutorial size of no more than 4 students, ask as many questions and get plenty of individualised help from our HSC Economics tutors.",
        heading_para_2: "Unlimited Bookings",
        para_2: "One tutorial per week not enough? Book as many economics tutorials as you need daily, on demand.",
        img: require("../panels/TutorialsPanel5.jpg")
    },
    {
        heading: "3. Unlimited Essay Marking →",
        heading_para_1: "Unlimited Marking",
        para_1: "Whether it's 20 or 200 times, we'll mark your essays until they're state ranking material.",
        heading_para_2: "Detailed Feedback",
        para_2: "Detailed essay annotations that push your thinking and abilities to Band 6 and beyond.",
        heading_para_3: "NSW's Best Markers",
        para_3: "We have a team of dedicated, trained markers who provide highly personalised feedback.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/61025c09e68a664a25e2e3b8_Home%20Section%20A%20Slide%209.png"
    },
    {
        heading: "4. State Ranker Essays →",
        heading_para_1: "State Ranker Scaffolds",
        para_1: "To be a state ranker, you must write like one. We weave state-ranking material into class content every week. Also receive exclusive access to our Library app.",
        heading_para_2: "Detailed Feedback & Exemplars",
        para_2: "Get detailed essay annotations and exclusive state ranker exemplars that push your thinking and abilities to Band 6 and beyond.",
        img: require("../panels/TutorialsPanel2.jpg")
    },
    {
        heading: "5. Daily Support till 10pm →",
        heading_para_1: "Any Time, Any Subject",
        para_1: "Got questions? Every night from 8 - 10pm, we run homework help sessions called Thinktank. Whether it's English, Maths or Economics, we cover it all.",
        heading_para_2: "School Work Help",
        para_2: "Need help with work from school? Got an assignemnt coming up? No problem, ask your dedicated tutor online, or on campus. ",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60c71d4d4b03cb8925ffb62d_Home%20Section%20A%20Slide%203-p-1080.png"
    },
    {
        heading: "6. Exam Perfection (LEAP) →",
        heading_para_1: "12 Predicted HSC Exams",
        para_1: "In the final term, sit 12 predicted HSC exams that covers almost every question you can possibly be asked, all simulated in HSC conditions.",
        heading_para_2: "Detailed Exam Review",
        para_2: "In the lead up to exams, receive unlimited review sessions with your tutor to learn from your mistakes and perfect your technique.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60c71e81d3399ee4352927bd_Home%20Section%20A%20Slide%205%20(1).png"
    },
    {
        testimonial: '"Not only were the resources super in-depth and comprehensive, but I always felt supported by the both the tutors and other students!"s',
        testimonial_name: "Jasmine De Rosa",
        testimonial_achieve: "AR at North Sydney Girls '20",
        testimonial_atar: "99.60 ATAR, 5th in NSW for Economics",
        testimonial_img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/6107b826899b05504fb2b29b_Jasmine.jpg"
    },
    {
        section_heading: "A Proven Approach to HSC Economics"
    }
]

export {
    infoProvenY12Economics
}