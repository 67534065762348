import React from 'react'
import Hero from '../../components/Hero';
import Tutors from '../../components/TutorSection';
import Info from '../../components/Info';
import Results from '../../components/Results';
import BlogSection from '../../components/BlogSection';
import TimetableSection from '../../components/TimetableSection';
import SEO from '../../components/SEO';

//Objects
import { home_InfoOne, home_InfoTwo, home_InfoThree } from '../../assets/InfoObjects';
import { infoProvenY11English, infoResourcesY11English } from '../../assets/infoObjects/infoY11English';
import { schedulesY11English } from '../../assets/courseSchedules/schedulesY11';
import { hero_Y11English } from '../../assets/HeroText';
import CourseSchedule from '../../components/CourseSchedule';
import CTA from '../../components/CTA';
import Pricing from '../../components/Pricing';

function Y11English() {

  return (
    <>
        <SEO 
          title='Year 11 English Advanced Tutoring | Project Academy'
          description='Year 11 English Advanced tutoring designed to maximise your chances of an upper Band 6. Access 99+ ATAR teachers, incredible resources and past papers.'
          name='Project Academy'
        />
        <Hero data={hero_Y11English} />
        <Info data={infoProvenY11English} />
        <Tutors data="HSC English" />
        <Info data={infoResourcesY11English} />
        <Info data={home_InfoTwo} />
        <CourseSchedule data={schedulesY11English} />
        <TimetableSection data="Y11 English Advanced" />
        <Pricing data="Y11 English" />
        <BlogSection />
        <CTA />
    </>
        
  )
}

export default Y11English