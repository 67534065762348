import { useFetch } from '../hooks/useFetch';
import { useEffect, useState } from 'react';

//icons
import iconTick from "../assets/icons/icon-tick.svg"
import iconCross from "../assets/icons/icon-cross.svg"

function GetTimetable() {
    var HSCMat4 = [];
    var HSCMat3 = [];
    var HSCMat2 = [];
    var HSCEcon = [];
    var HSCEAdv = [];
    var HSCPhys = [];
    var HSCChem = []; 

    var Y11MatA = [];
    var Y11Mat3 = [];
    var Y11Econ = [];
    var Y11Phys = [];
    var Y11Chem = [];
    var Y11EAdv = [];

    var Y10Mat = [];
    var Y09Mat = [];

    var instanceArray = [];
    var totalArray = [];

    const [subscriptions, setSubscriptions] = useState()

    useEffect(() => {
        fetch("https://polar-fjord-31535.herokuapp.com/api")
            .then((res) => res.json())
            .then(data => setSubscriptions(data))
    }, [])

    if (subscriptions != null) {
        //console.log(subscriptions)
        for (var i = 0; i < subscriptions.results.length; i++) {
            var subject = subscriptions.results[i].focus;
            var text = subscriptions.results[i].lesson;
            var time = subscriptions.results[i].time;
            var day = subscriptions.results[i].day;
            var location = subscriptions.results[i].location; // class
            var campus;
            var studentnum = subscriptions.results[i].assigned ? subscriptions.results[i].assigned.length : 0;
            var capacitystatus;

            if (day === "M") {day = "Monday"};
            if (day === "TU") {day = "Tuesday"};
            if (day === "W") {day = "Wednesday"};
            if (day === "TH") {day = "Thursday"};
            if (day === "F") {day = "Friday"};
            if (day === "SA-1" || day === "SA-2" || day === "SA-3") {day = "Saturday"};
            if (day === "SU-1" || day === "SU-2" || day === "SU-3") {day = "Sunday"};

            //Check for online classes
            if (subscriptions.results[i].tier === "Lite") {campus = "Online live"; location = "zoom";} else {campus = "Chatswood campus"}

            capacitystatus = capacityCalc(studentnum, location);

            //Close all Year 12
            if (subject.includes("HSC")) capacitystatus = "At capacity";
            if (subject.includes("Y11.MatA")) capacitystatus = "At capacity";

            instanceArray = [subject, text, time, day, location, campus, studentnum, capacitystatus];

            if (subject === "HSC.Mat4") {instanceArray[0] = "HSC 4U Maths"; HSCMat4.push(instanceArray)};
            if (subject === "HSC.Mat3") {instanceArray[0] = "HSC 3U Maths"; HSCMat3.push(instanceArray)};
            if (subject === "HSC.Mat2") {instanceArray[0] = "HSC 2U Maths"; HSCMat2.push(instanceArray)};
            if (subject === "HSC.Chem") {instanceArray[0] = "HSC Chemistry"; HSCChem.push(instanceArray)};
            if (subject === "HSC.Phys") {instanceArray[0] = "HSC Physics"; HSCPhys.push(instanceArray)};
            if (subject === "HSC.Econ") {instanceArray[0] = "HSC Economics"; HSCEcon.push(instanceArray)};
            if (subject === "HSC.EAdv") {instanceArray[0] = "HSC English Advanced"; HSCEAdv.push(instanceArray)};

            if (subject === "Y11.MatA") {instanceArray[0] = "Y11 Maths Accelerated"; Y11MatA.push(instanceArray)};
            if (subject === "Y11.Mat3") {instanceArray[0] = "Y11 Maths Advanced"; Y11Mat3.push(instanceArray)};
            if (subject === "Y11.Chem") {instanceArray[0] = "Y11 Chemistry"; Y11Chem.push(instanceArray)};
            if (subject === "Y11.Phys") {instanceArray[0] = "Y11 Physics"; Y11Phys.push(instanceArray)};
            if (subject === "Y11.Econ") {instanceArray[0] = "Y11 Economics"; Y11Econ.push(instanceArray)};
            if (subject === "Y11.EAdv") {instanceArray[0] = "Y11 English Advanced"; Y11EAdv.push(instanceArray)};

            if (subject === "Y10.Math") {instanceArray[0] = "Y10 Maths Advanced"; Y10Mat.push(instanceArray)};
            if (subject === "Y09.Math") {instanceArray[0] = "Y09 Maths Advanced"; Y09Mat.push(instanceArray)};


        }
        totalArray.push(HSCMat4, HSCMat3, HSCMat2, HSCChem, HSCPhys, HSCEcon, HSCEAdv);
        totalArray.push(Y11MatA, Y11Mat3, Y11Chem, Y11Phys, Y11Econ, Y11EAdv);
        totalArray.push(Y10Mat, Y09Mat);

        //console.log(totalArray)
    }

    return totalArray;
}

function capacityCalc(studentnum, location) {
    var capacityleft;

    if (location === "Cube" || location === "Forest" || location === "Berry" || location === "zoom") {
        if (studentnum >= 17) {
            return "At capacity";
        } else {
            capacityleft = 17 - studentnum;
            if (capacityleft > 5) {
                return "Spots available"
            } else {
                return `${capacityleft} spots remaining`;

            }
        }
    }

    if (location === "Coconut" || location === "Mango") {
        if (studentnum >= 11) {
            return "At capacity";
        } else {
            capacityleft = 11 - studentnum;
            if (capacityleft > 5) {
                return "Spots available"
            } else {
                return `${capacityleft} spots remaining`;

            }
        }
    }
}

function CampusCalc({campus}) {
    if (campus === "Online live") {
        return (
            <p className="timetable-campus-online">{campus}</p>
        )
    } else {
        return (
            <p className="timetable-campus-chatswood">{campus}</p>
        )
    }
}

function CapacityStatus({capacity}) {
    if (capacity === "At capacity") {
        return (
            <span className="timetable-status">
                <img src={iconCross} />
                <p className="timetable-status-capacity">{capacity}</p>
            </span>
        )

    }
    return (
        <span className="timetable-status">
            <img src={iconTick} />
            <p className="timetable-status-available">{capacity}</p>
        </span>
    )
}

export {GetTimetable, CampusCalc, CapacityStatus};