import React from 'react'

import './BlogArticleSmall.scss'

function BlogArticleSmall({subject, topic, img, link, className}) {
  return (
    <div className={className}>
        <a href={link}>
            <div className="blog-article-small-group">
                <div className="blog-article-small-left">
                    <img src={img} />
                </div>
                <div className="blog-article-small-right">
                    <p className="blog-article-small-subject">{subject}</p>
                    <p className="blog-article-small-topic">{topic}</p>
                </div>
            </div>
        </a>
    </div>

  )
}

export default BlogArticleSmall