// Y11 INFO
// Y11 INFO
// Y11 INFO
// Y11 INFO
// Y11 INFO

const infoProvenY11MathsAccel = [
    {
        heading: "1. Accelerated Program →",
        heading_para_1: "Designed to help you excel",
        para_1: "Our specially designed Year 11 Maths Accelerated Tutoring classes comprehensively covers Year 11 2U+3U Maths and Year 12 2U+3U Maths in 9 months.",
        heading_para_2: "Purposefully Structured",
        para_2: "In our Year 11 Maths tutoring classes, every aspect of the subject syllabus is investigated in detail and will equip you with the skills to tackle any challenging or unfamiliar questions.",
        img: require("../panels/SupportPanel.jpg")
    },
    {
        heading: "2. Weekly Masterclass →",
        heading_para_1: "NSW's Top Teachers",
        para_1: "Learn from a HSC Maths state-ranker, first in course, or experienced teacher.",
        heading_para_2: "Exam Oriented",
        para_2: "Apply theory to practice with exam style maths questions on a weekly basis.",
        heading_para_3: "Principles Based",
        para_3: "Designed to help you develop a deep understanding of core Maths Advanced concepts.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/6102653ec9168e30a41ec49a_Home%20Section%20A%20Slide%2014.png"
    },
    {
        heading: "3. Weekly Personalised Tutorials →",
        heading_para_1: "Highly Personalised",
        para_1: "Every week, attend highly personalised academic-help sessions with some of the best tutors in NSW.",
        heading_para_2: "Dedicated Tutor",
        para_2: "A tutor who achieved a Band 6 in HSC Maths will be assigned to you. Their goal is to ensure your marks increase every week.",
        heading_para_3: "Unlimited Tutorials",
        para_3: "Book as many tutorials as you need, any time you need. We run sessions almost every hour of the day, 7 days a week.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60fe99d9a4cf33b1d74817b8_Frame%2073.png"
    },
    {
        heading: "4. Weekly Exam-like Homework →",
        heading_para_1: "Exam-like Homework",
        para_1: "Effective homework that'll actually help you. Our homework sets are all carefully crafted to help you top the state.",
        heading_para_2: "Assessment Task Prep",
        para_2: "In the lead up to assessment tasks, tutors will work with you to personalise their approach to help you come first in your exams.",
        heading_para_3: "NSW's Best Markers",
        para_3: "Your homework sets will include a rubric developed by NSW's best markers so you know what to expect come exam time.",
        img: require("../panels/HomeworkPanel.jpg")
    },
    {
        heading: "5. Daily Support till 10pm →",
        heading_para_1: "Ask Anything",
        para_1: "Got questions? Every night from 8 - 10pm, we run homework help sessions called Thinktank.",
        heading_para_2: "Any Subject",
        para_2: "Whether it's English, Maths, Science, Economics... you can get help with it all. ",
        heading_para_3: "School Work Help",
        para_3: "Need help with work from school? No problem. Your tutor is there to help you.",
        img: require("../panels/SupportPanel.jpg")
    },
    {
        heading: "6. Unlimited Marking →",
        heading_para_1: "Homework Marking",
        para_1: "Every homework submission is marked carefuly. Worked feedback is given with the greatest detail possible.",
        heading_para_2: "Assessment Drafts",
        para_2: "Submit school work or assessment drafts for marking! A dedicated tutor will work with you to maximise your performance.",
        heading_para_3: "NSW's Best Markers",
        para_3: "Your marker is a teacher who achieved a Band 6 or state rank in HSC Maths.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/61025c09e68a664a25e2e3b8_Home%20Section%20A%20Slide%209.png"
    },
    {
        testimonial: `"Academic success has and will always be predicated on self motivation... the light-hearted, supportive and collaborative culture of Project has provided the catalyst for self-determination and ultimately improving my marks"`,
        testimonial_name: "Jason Lin",
        testimonial_achieve: "Dux of Knox Grammar '20",
        testimonial_atar: "99.95 ATAR, 3x State Ranker",
        testimonial_img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60efb31f45789a7a206c888f_Jason.png"
    },
    {
        section_heading: "A Proven Approach to Year 11 Maths"
    }
]

// Y11 RESOURCES
// Y11 RESOURCES
// Y11 RESOURCES
// Y11 RESOURCES
// Y11 RESOURCES

const infoResourcesY11MathsAccel = [
    {
        heading: "1. Weekly In-depth Notes →",
        heading_para_1: "In-depth Weekly Notes",
        para_1: "Our notes cover every aspect of the syllabus in detail and explores the underlying mechanics to help you build a thorough understanding of Year 11 Maths Advanced.",
        heading_para_2: "Purposefully Structured By Topic",
        para_2: "All notes are accessed through the Project Books app organised at an unmatched level of detail. Content is organised by lesson and topic, alongside topic-specific HSC exam questions so you know exactly where to focus when preparing for an assessment.",
        doc: "https://drive.google.com/file/d/13pGUcwWZmvaIivVSdeEe2mLCVMS-ePuF/preview"
    },
    {
        heading: "2. Weekly In-class Workbook →",
        heading_para_1: "Comprehensive Theory Notes",
        para_1: "Every week, you will receive a specially designed workbook for class. Each workbook helps you capture class content in a neat, structured way so that you will easily remember how to approach each concept.",
        heading_para_2: "Specially Designed Workbooks",
        para_2: "This way, our workbooks are designed to make absorbing class content infinitely more efficient, while ensuring you understand, retain and can easily revise content for your HSC exam.",
        doc: "https://docs.google.com/file/d/1Z3cuVHAzhehAYGwPm3gJa09jL3_1Ddaz/preview"
    },
    {
        heading: "3. Weekly Exam-like Homework →",
        heading_para_1: "Weekly Exam-like Homework",
        para_1: "Every week, you will receive specially designed homework sets to mimic how you will be examined in the HSC. By the time you sit your HSC, it should feel like a breeze.",
        heading_para_2: "Marked & Tracked",
        para_2: "After you submit your homework, receive highly detailed feedback from a state ranking or high band 6 tutor. All mistakes are tracked via an app so we know exactly what topics you're struggling with.",
        doc: `https://drive.google.com/file/d/123wQ4ok0-Sgp8we-2P71yG7QsiO31x3V/preview`
    },
    {
        heading: "4. 1,000+ Past Papers →",
        heading_para_1: "1,000+ Past Papers",
        para_1: "Access over 300 past papers for Year 11 Maths and over 1,000 HSC past papers across any other subject you take at school.",
        heading_para_2: "Not Just HSC Papers",
        para_2: "These past papers aren't just for Trials or the HSC. They cover all assessment tasks encountered every term, and for both Year 11 and Year 12.",
        doc: "https://drive.google.com/file/d/18ugtuN-5gP1yO7vJPM6EyYSiiS6hhE1L/preview"
    },
    {
        testimonial: "Academic success has and will always be predicated on self motivation... the light-hearted, supportive and collaborative culture of Project has provided the catalyst for self-determination and ultimately improving my marks",
        testimonial_name: "Jason Lin",
        testimonial_achieve: "Dux of Knox Grammar '20",
        testimonial_atar: "99.95 ATAR, 4th in NSW for 4U Maths",
        testimonial_img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60efb31f45789a7a206c888f_Jason.png"
    },
    {
        section_heading: "The Right Resources"
    }
]
export {
    infoProvenY11MathsAccel,
    infoResourcesY11MathsAccel
}
