import React from 'react';
import Hero from '../../components/Hero';
import Tutors from '../../components/TutorSection';
import Results from '../../components/Results';
import BlogSection from '../../components/BlogSection';
import CTA from '../../components/CTA';
import Profile from '../../components/Profile';
import SEO from '../../components/SEO';

//Data - Hero
import { hero_Schools} from '../../assets/HeroText';
import SchoolList from '../../components/SchoolList';


function Schools() {

  return (
    <>
        <SEO 
          title='Schools at Project Academy - Tutoring and Insights'
          description="Over 100 schools in NSW attend Project Academy as their HSC tuition centre of choice. We offer school-specific classes for Year 9 - 12 tutoring."
          name='Project Academy'
        />
        <Hero data={hero_Schools} />
        <Results />
        <div className="schools-list">
            <SchoolList />
        </div>
        <Tutors data="General" />
        <BlogSection />
        <CTA />
    </>

  )
}

export default Schools;