import React from 'react'
import Hero from '../../components/Hero';
import Tutors from '../../components/TutorSection';
import Info from '../../components/Info';
import Results from '../../components/Results';
import BlogSection from '../../components/BlogSection';
import TimetableSection from '../../components/TimetableSection';
import CourseSchedule from '../../components/CourseSchedule';
import CTA from '../../components/CTA';
import Pricing from '../../components/Pricing';
import SEO from '../../components/SEO';

//Objects
import { home_InfoOne, home_InfoTwo, home_InfoThree } from '../../assets/InfoObjects';
import { infoProvenY12Physics, infoResourcesY12Physics } from '../../assets/infoObjects/infoY12Physics';

//Data
import { hero_physics } from '../../assets/HeroText';
import { schedulesY12Physics } from '../../assets/courseSchedules/schedulesHSC';

function HSCPhysics() {

  return (
    <>
        <SEO 
          title='HSC Physics Tutoring | Project Academy'
          description='Year 12 HSC Physics tutoring designed to maximise your chances of an upper Band 6. Access 99+ ATAR teachers, incredible resources and past papers.'
          name='Project Academy'
        />
        <Hero data={hero_physics} />
        <Info data={infoProvenY12Physics} />
        <Tutors data="HSC Physics" />
        <Info data={infoResourcesY12Physics} />
        <Info data={home_InfoTwo} />
        <CourseSchedule data={schedulesY12Physics} />
        <TimetableSection data="HSC Physics" />
        <Pricing data="HSC Physics" />
        <BlogSection />
        <CTA />
    </>
        
  )
}

export default HSCPhysics