import React from 'react'
import "./GeneralFold.scss";
import Testimonial from './Testimonial';

import TestimonialsList from '../assets/Testominals';

function VideoFold() {
  return (
    <div className="container">
        <div className="generalfold-container">
            <div className="generalfold-left">
                <h2>Introducing the Project iPad</h2>
                <p>For over 10 years, the Project iPad has been the best kept secret of 99+ ATAR and state ranker achievers. A core pillar a Project education, students take online classes, receive live support, and access thousands of the most in-depth notes.</p>
            </div>
            <div className="generalfold-right">
                <iframe className="generalfold-video" width="100%" height="475" src="https://www.youtube.com/embed/UlA-RcaW74A?autoplay=1&mute=1" allowfullscreen autoplay></iframe>
            </div>
        </div>
    </div>
  )
}

export default VideoFold