const faq_Knox = [
    {
        question: "What’s the student experience at Knox Grammar School like?",
        answer: "Your time at Knox Grammar School will be one that provides you with a treasure trove of skills, experiences, memories and friends that will equip you to achieve higher and better at every stage of your life. Knox offers good teachers and a competitive environment in which your academic abilities and confidence as a leader will be strongly fostered. You’ll find that the student community at Knox Grammar will support you through your time at high school. In addition to this, Knox Grammar offers a wide range of non-academic activities to enrich students, such as participation in musical bands, army cadets, weekly sport in the prestigious CAS competition league and more."
    },
    {
        question: "Is Knox Grammar a good school?",
        answer: "Knox Grammar School’s academic performance is increasingly strong, with the school consistently ranking within the top 30 schools in the HSC for the past 5 years and consistently climbing. Each year, many students from Knox achieve band 6 grades in the HSC."
    },
    {
        question: "What is Wahroonga suburb like?",
        answer: "Located at the junction of Pennant Hills Road and the Pacific Highway, Wahroonga is a pleasant residential suburb suited to students and families’ needs while offering good road infrastructure. Nearby suburbs include Hornsby, with decent transport, shopping and recreational amenities. Sydney CBD is a half hour train ride away."
    },
    {
        question: "What is the best tutoring for Knox Grammar School?",
        answer: "Project Academy has a long and successful relationship with students of Knox Grammar school. Each year, we help guide more than half of all our students to achieve a >95 ATAR in the HSC, with the majority of our students achieving top band 6 grades in their respective subjects."
    },
    {
        question: "Do Knox Students often attend tutoring?",
        answer: "Many Knox Grammar students attend some form of coaching or tutoring. And every year, many who attend Project Academy achieve highly in the HSC."
    },
    {
        question: "What is Knox Grammar School’s rank in the HSC?",
        answer: "Knox Grammar consistently achieves rankings within the top 30 in the HSC. In 2020, Knox achieved rank 19 in all of NSW in the HSC. As a top private high school, its students perform well academically across most subjects each year."
    },
    {
        question: "What are some famous alumni of Knox Grammar School?",
        answer: "Notable alumni of Knox Grammar include: Macquarie Group chairman David S. Clarke and famous journalist/sportsman David FitzSimons. The size of each annual cohort is roughly 350 students."
    },
    {
        question: "What are Knox Grammar School's fees?",
        answer: "Being an independent Uniting Church high school, tuition at Knox Grammar is around AUD32,000 a year! Other costs include purchasing uniforms, textbooks, parent’s association donations and school contribution fees."
    },
]

export {
    faq_Knox
}