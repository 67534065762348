const blogSection = [
    {
        img: "https://uploads-ssl.webflow.com/60b5fcff999dc132ebcefb3e/60c9f12a9ba8c82a91318a95_Xerxes%20Blog%20Image.jpg",
        heading: "How I achieved a 99.65 ATAR and Got into Medicine",
        description: "The biggest misconceptions that I hear students say about the HSC...",
        author: "Xerxes Lopes",
        marks: "All-Rounder & 99.65 ATAR",
        profilepic: "https://uploads-ssl.webflow.com/60b5fcff999dc132ebcefb3e/60c9f176c90c1954a084a51f_Xerxes-Blog-Small-150x150.jpeg",
        link: "https://www.projectacademy.com.au/headline-articles/hsc-getting-into-medicine"
    },
    {
        img: "https://uploads-ssl.webflow.com/60b5fcff999dc132ebcefb3e/60bf33f102fec429e8b33cfb_Main%20Image-p-1080.jpeg",
        heading: "A State Ranker's Guide to Writing 20/20 English Advanced Essays",
        description: "I completed 4 units of English, so take it from me, I've written a lot of essays!",
        author: "Marko Beocanin",
        marks: "State Ranker & 99.95 ATAR",
        profilepic: "https://uploads-ssl.webflow.com/60b5fcff999dc132ebcefb3e/60bf34648dc8a3a0b953eca0_Marko.jpeg",
        link: "https://www.projectacademy.com.au/headline-articles/a-state-rankers-guide-to-writing-20-20-english-advanced-essays"
    },
    {
        img: "https://uploads-ssl.webflow.com/60b5fcff999dc132ebcefb3e/60bf356464f7c403ea821c21_Laura%20-%20How%20I%20achieved%203rd%20in%20NSW-p-1080.jpeg",
        heading: "How I Achieved 3rd in NSW for 3U Maths and a 99.80 ATAR",
        description: "I'm here to pass on the strategies I used to achieve my goal ATAR of 99.80...",
        author: "Laura Hardy",
        marks: "State Ranker & 99.80 ATAR",
        profilepic: "https://uploads-ssl.webflow.com/60b5fcff999dc132ebcefb3e/60bf357d0b23331f4047ae07_Laura.jpeg",
        link: "https://www.projectacademy.com.au/headline-articles/how-i-achieved-3rd-in-nsw-for-3u-maths-and-a-9980-atar"
    },
    {
        img: "https://uploads-ssl.webflow.com/60b5fcff999dc132ebcefb3e/60b5fd5b2fdfd43203500021_Test%20Image%202-p-500.jpeg",
        heading: "Achieving Two State Ranks, Dux and a 99.95 ATAR",
        description: "I'd be lying if I told you that I enjoyed sitting my HSC...'",
        author: "Cory Aitchison",
        marks: "State Ranker & 99.80 ATAR",
        profilepic: "https://uploads-ssl.webflow.com/60b5fcff999dc132ebcefb3e/62d8919877e0d44d96bb2a05_Cory%20Headshot.png",
        link: "https://www.projectacademy.com.au/headline-articles/achieving-two-state-ranks-and-a-99-95-atar"
    },
]

export default blogSection;