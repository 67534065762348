import React, {useState, useEffect, useRef} from 'react'
import "./NavbarMobile.scss"

function NavbarMobile() {
    const [showDropdown, setShowDropdown] = useState();
    const [showCourses, setShowCourses] = useState();
    const [showCampus, setShowCampus] = useState();
    const [showY12Courses, setY12Courses] = useState();
    const [showY11Courses, setY11Courses] = useState();
    const [showY10Courses, setY10Courses] = useState();
    const [showY09Courses, setY09Courses] = useState();

    function toggleDropdown() {
        setShowDropdown(!showDropdown);
    }
    function toggleCourses() {
        setShowCourses(!showCourses);
    }
    function toggleCampus() {
        setShowCampus(!showCampus);
    }

    function toggleY12Courses() {
        setY12Courses(!showY12Courses);
        if(showY11Courses) setY11Courses(!showY11Courses);
        if(showY10Courses) setY10Courses(!showY10Courses);
        if(showY09Courses) setY09Courses(!showY09Courses);
    }
    function toggleY11Courses() {
        setY11Courses(!showY11Courses);
        if(showY12Courses) setY12Courses(!showY12Courses);
        if(showY10Courses) setY10Courses(!showY10Courses);
        if(showY09Courses) setY09Courses(!showY09Courses);
    }
    function toggleY10Courses() {
        setY10Courses(!showY10Courses);
        if(showY12Courses) setY12Courses(!showY12Courses);
        if(showY11Courses) setY11Courses(!showY11Courses);
        if(showY09Courses) setY09Courses(!showY09Courses);
    }
    function toggleY09Courses() {
        setY09Courses(!showY09Courses);
        if(showY12Courses) setY12Courses(!showY12Courses);
        if(showY11Courses) setY11Courses(!showY11Courses);
        if(showY10Courses) setY10Courses(!showY10Courses);
    }

    const ref = useRef();

    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowDropdown(showDropdown)
            setShowCourses(showCourses)
            setShowCampus(showCampus)
            setY12Courses(showY12Courses)
            setY11Courses(showY11Courses)
            setY10Courses(showY10Courses)
            setY09Courses(showY09Courses)
        }
    }

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('click', handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
        document.removeEventListener('click', handleClickOutside);
        };
    }, [ref]);
    
return (
    <div className="mNav-group" ref={ref}>
        <div className="mNav-container">
            <div className="mNav-left">
                <div className="mNav-logo">
                    <a href="/"><img className="navbar-logo" src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/608b945167ea830217a3e588_Logo.svg" /></a>
                </div>
            </div>
            <div className="mNav-right">
                <div className="mNav-hamburger">
                    <a ref={ref} onClick={toggleDropdown}><img src={require("../assets/icons/menu-burger.webp")} /></a>
                </div>
            </div>
        </div>
        {/* {Show Dropdown} */}
        {showDropdown &&
        <div className="mDropdown-group">
            <div className="mDropdown-container">
                <ul id="mDropdown-main" className="mDropdown-subjects">
                    <li><a onClick={toggleCourses}>Courses <span className="gg-chevron-down"></span></a></li>
                        {/* {ShowCourses} */}
                        {showCourses && 
                            <div id="mDropdown-courses" className="mDropdown-container">
                                <ul className="mDropdown-subjects">
                                    <li><a onClick={toggleY12Courses}>Year 12 Courses<span className="gg-chevron-down"></span></a></li>
                                    {/* {Show Year 12 Courses} */}
                                    {showY12Courses &&
                                        <div id="mDropdown-year12" className="mDropdown-container">
                                            <ul className="mDropdown-subjects">
                                                <li><a href={`${process.env.REACT_APP_HSCENGLISH}`}>Y12 English Advanced <span></span></a></li>
                                                <li><a href={`${process.env.REACT_APP_HSCECONOMICS}`}>Y12 Economics <span></span></a></li>
                                                <li><a href={`${process.env.REACT_APP_HSCCHEMISTRY}`}>Y12 Chemistry <span></span></a></li>
                                                <li><a href={`${process.env.REACT_APP_HSCPHYSICS}`}>Y12 Physics <span></span></a></li>
                                                <li><a href={`${process.env.REACT_APP_HSC4UMATHS}`}>Y12 Maths Extension 2 <span></span></a></li>
                                                <li><a href={`${process.env.REACT_APP_HSC3UMATHS}`}>Y12 Maths Extension 1 <span></span></a></li>
                                                <li><a href={`${process.env.REACT_APP_HSC2UMATHS}`}>Y12 Maths Advanced <span></span></a></li>
                                            </ul>
                                        </div>
                                    }
                                    <li><a onClick={toggleY11Courses}>Year 11 Courses<span className="gg-chevron-down"></span></a></li>
                                    {/* {Show Year 11 Courses} */}
                                    {showY11Courses && 
                                        <div id="mDropdown-year11" className="mDropdown-container">
                                            <ul className="mDropdown-subjects">
                                                <li><a href={`${process.env.REACT_APP_Y11ENGLISH}`}>Y11 English Advanced<span></span></a></li>
                                                <li><a href={`${process.env.REACT_APP_Y11ECONOMICS}`}>Y11 Economics<span></span></a></li>
                                                <li><a href={`${process.env.REACT_APP_Y11CHEMISTRY}`}>Y11 Chemistry<span></span></a></li>
                                                <li><a href={`${process.env.REACT_APP_Y11PHYSICS}`}>Y11 Physics<span></span></a></li>
                                                <li><a href={`${process.env.REACT_APP_Y11MATHSACCEL}`}>Y11 Maths Accelerated<span></span></a></li>
                                                <li><a href={`${process.env.REACT_APP_Y11MATHSADV}`}>Y11 Maths Advanced<span></span></a></li>
                                            </ul>
                                        </div>
                                    }
                                    <li><a onClick={toggleY10Courses}>Year 10 Courses<span className="gg-chevron-down"></span></a></li>
                                    {/* {Show Year 10 Courses} */}
                                    {showY10Courses &&
                                        <div id="mDropdown-year10" className="mDropdown-container">
                                            <ul className="mDropdown-subjects">
                                            <li><a href={`${process.env.REACT_APP_Y10MATHS}`}>Y10 Maths Advanced</a></li>
                                            </ul>
                                        </div>
                                    }
                                    <li><a onClick={toggleY09Courses}>Year 09 Courses<span className="gg-chevron-down"></span></a></li>
                                    {/* {Year 09 Courses} */}
                                    {showY09Courses &&
                                        <div id="mDropdown-year09" className="mDropdown-container">
                                            <ul className="mDropdown-subjects">
                                            <li><a href={`${process.env.REACT_APP_Y9MATHS}`}>Y09 Maths Advanced</a></li>
                                            </ul>
                                        </div>
                                    }
                                </ul>
                            </div>
                        }
                    <li><a href="/ipad">The iPad</a></li>
                    <li><a onClick={toggleCampus}>Locations <span className="gg-chevron-down"></span></a></li>
                        {/* {ShowCampus} */}
                        {showCampus && 
                            <div className="mDropdown-container">
                                <ul className="mDropdown-subjects">
                                    <li><a href="/chatswood">Chatswood Campus<span></span></a></li>
                                </ul>
                            </div>
                        }
                    <li><a href="/life-at-project">Life at Project <span></span></a></li>
                    <li><a href="/guides">Guides <span></span></a></li>
                    <li><a href="/timetable">Timetable <span></span></a></li>
                </ul>
            </div>

        </div>
        }
    </div>
  )
}

export default NavbarMobile