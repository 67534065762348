import React, {useState, useEffect, useRef} from 'react'
import Banner from './Banner'
import './Navbar.scss'
import NavbarDropdown_Campus from './NavbarDropdown_Campus'
import NavbarDropdown_Subjects from './NavbarDropdown_Subjects'

function Navbar() {
    const [show1, setShow1] = useState();
    const [show2, setShow2] = useState();
    const ref = useRef();

    function toggleShow1() {
        setShow1(!show1);
        if(show2) {
          setShow2(!show2);
        }
    }
    function toggleShow2() {
      setShow2(!show2)
      if(show1) {
        setShow1(!show1);
      }
    }

    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow1(show1);
          setShow2(show2);
        }
      }

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('click', handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener('click', handleClickOutside);
        };
      }, [ref]);

    return (
    <>
        <Banner />
        <div className="container navbar-full" ref={ref}>
            <div className="navbar">
                <div className="navbar-left">
                    <a href="/"><img className="navbar-logo" src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/608b945167ea830217a3e588_Logo.svg" /></a>
                </div>
                <div className="navbar-right">
                    <div className="nav-items">
                        <p ref={ref} onClick={toggleShow1}>Courses<span className="gg-chevron-down"></span></p>
                        <p><a href="/ipad">The iPad</a></p>
                        <p ref={ref} onClick={toggleShow2}>Locations<span className="gg-chevron-down"></span></p>
                        <p><a href="/life-at-project">Life at Project</a></p>
                        <p><a href="https://www.projectacademy.nsw.edu.au/thoughts">Guides</a></p>
                        <a href={`${process.env.REACT_APP_WT_LINK}`}><p className="nav-cta">3 Week Trial</p></a>
                    </div>
                    
                </div>
                
              
            </div>
            <div>
              {/* {show1 && <NavbarDropdown_Subjects />} */}
              {show1 && <NavbarDropdown_Subjects />}
              {show2 && <NavbarDropdown_Campus />}
            </div>
        </div>
        
    </>
  )
}

export default Navbar