import React from 'react';
import "./Hero.scss";

function Hero({data}) {
  return (
    <>
        <div className="hero-home-bg" style={{backgroundImage: `url(${data[0].hero_bg_image})`}}>
        <div className="hero-overlay"></div>
          <div className="container">
              <div className="hero">
                  <h1 className="hero-text">{data[0].hero_text}</h1>
                  <p className="hero-under-text">{data[0].hero_under_text}</p>
                  <a href={`${process.env.REACT_APP_WT_LINK}`}><div className="hero-button">Take the 3 week trial</div></a>
              </div>
            </div>
        </div>
    </>
  )
}

export default Hero