const hero_home = [
    {
        hero_text: "The Most Personalised HSC Tuition, Designed For You.",
        hero_under_text: "Maximise your chances of coming first in Maths, Chemistry, Physics, Economics and English.",
        hero_bg_image: require("../assets/cover/Cover.webp")
    }
]

const hero_english = [
    {
        hero_text: "The Most Effective HSC English Advanced Tuition",
        hero_under_text: "Highly personalised, text-specific HSC English tuition. Designed to maximise your chances of an upper Band 6.",
        hero_bg_image: require("../assets/cover/HSCEnglishCover.webp")
    }
]

const hero_economics = [
    {
        hero_text: "The Most Effective HSC Economics Tuition",
        hero_under_text: "Highly personalised HSC Economics tuition. Designed to maximise your chances of an upper Band 6.",
        hero_bg_image: require("../assets/cover/HSCEconomicsCover.webp")
    }
]

const hero_chemistry = [
    {
        hero_text: "The Most Effective HSC Chemistry Tuition",
        hero_under_text: "Highly personalised HSC Chemistry tuition. Designed to maximise your chances of an upper Band 6.",
        hero_bg_image: require("../assets/cover/HSCChemistryCover.webp")
    }
]

const hero_physics = [
    {
        hero_text: "The Most Effective HSC Physics Tuition",
        hero_under_text: "Highly personalised HSC Physics tuition. Designed to maximise your chances of an upper Band 6.",
        hero_bg_image: require("../assets/cover/HSCPhysicsCover.webp")
    }
]

const hero_4UMaths = [
    {
        hero_text: "The Most Effective HSC 4U Maths Tuition",
        hero_under_text: "Highly personalised HSC 4U Maths tuition. Designed to maximise your chances of an upper Band 6.",
        hero_bg_image: require("../assets/cover/HSC4UMathsCover.webp")
    }
]
const hero_3UMaths = [
    {
        hero_text: "The Most Effective HSC 3U Maths Tuition",
        hero_under_text: "Highly personalised HSC 3U Maths tuition. Designed to maximise your chances of an upper Band 6.",
        hero_bg_image: require("../assets/cover/HSC3UMathsCover.webp")
    }
]
const hero_2UMaths = [
    {
        hero_text: "The Most Effective HSC 2U Maths Tuition",
        hero_under_text: "Highly personalised HSC 2U Maths tuition. Designed to maximise your chances of an upper Band 6.",
        hero_bg_image: require("../assets/cover/HSC3UMathsCover.webp")
    }
]

const hero_Y11MathsAdv = [
    {
        hero_text: "The Most Effective Y11 Maths Advanced Tuition",
        hero_under_text: "Cover all of Year 11 and Year 12 Advanced (2U) even before Year 12 begins.",
        hero_bg_image: require("../assets/cover/HSC3UMathsCover.webp")
    }
]

const hero_Y11MathsAccel = [
    {
        hero_text: "Get Y11 Maths Accelerated Tuition",
        hero_under_text: "Cover all of Year 11 and Year 12 Advanced (2U) and Extension 1 (3U), before Year 12 begins.",
        hero_bg_image: require("../assets/cover/HSC4UMathsCover.webp")
    }
]

const hero_Y11Chemistry = [
    {
        hero_text: "The Most Effective Year 11 Chemistry Tuition",
        hero_under_text: "Highly personalised Year 11 Chemistry tuition. Designed to maximise your chances of an upper Band 6.",
        hero_bg_image: require("../assets/cover/HSCChemistryCover.webp")
    }
]

const hero_Y11Physics = [
    {
        hero_text: "The Most Effective Year 11 Physics Tuition",
        hero_under_text: "Highly personalised Year 11 Physics tuition. Designed to maximise your chances of an upper Band 6.",
        hero_bg_image: require("../assets/cover/HSCPhysicsCover.webp")
    }
]

const hero_Y11English = [
    {
        hero_text: "The Most Effective Year 11 English Tuition",
        hero_under_text: "Highly personalised Year 11 English tuition. Designed to maximise your chances of an upper Band 6.",
        hero_bg_image: require("../assets/cover/HSCEnglishCover.webp")
    }
]

const hero_Y11Econ = [
    {
        hero_text: "The Most Effective Year 11 Economics Tuition",
        hero_under_text: "Highly personalised Year 11 Economics tuition. Designed to maximise your chances of an upper Band 6.",
        hero_bg_image: require("../assets/cover/HSCEconomicsCover.webp")
    }
]

const hero_Y10Maths = [
    {
        hero_text: "The Most Effective Year 10 Maths Tuition",
        hero_under_text: "Highly personalised Year 10 Maths tuition. Designed to maximise your chances of an upper Band 6.",
        hero_bg_image: require("../assets/cover/HSC4UMathsCover.webp")
    }
]

const hero_Y9Maths = [
    {
        hero_text: "The Most Effective Year 9 Maths Tuition",
        hero_under_text: "Highly personalised Year 9 Maths tuition. Designed to maximise your chances of an upper Band 6.",
        hero_bg_image: require("../assets/cover/HSC4UMathsCover.webp")
    }
]

const hero_Tutors = [
    {
        hero_text: "Meet the Leading HSC Minds in NSW",
        hero_under_text: "Exceptional results start with exceptional teachers. With a 8% acceptance rate, this is NSW's most elite teaching team.",
        hero_bg_image: require("./cover/TutorsCover.webp"),
    }
]

const hero_Ipad = [
    {
        hero_text: "Introducing the Project Academy iPad",
        hero_under_text: "Backed by our EdTech software engineers, this is the ultimate tech ecosystem for the HSC.",
        hero_bg_image: require("./cover/iPadCover.webp"),
    }
]

const hero_ChatswoodHigh = [
    {
        hero_text: "Chatswood High students at Project Academy",
        hero_under_text: "Cover your bases and accelerate ahead of Chatswood High School's curriculum with NSW's most effective tuition program.",
        hero_bg_image: require("./cover/iPadCover.webp"),
    }
]

const hero_Normanhurst = [
    {
        hero_text: "Normanhurst Boys students at Project Academy",
        hero_under_text: "Cover your bases and accelerate ahead of Normanhurst Boys High School's curriculum with NSW's most effective tuition program.",
        hero_bg_image: require("./cover/iPadCover.webp"),
    }
]

const hero_Knox = [
    {
        hero_text: "Knox Grammar students at Project Academy",
        hero_under_text: "Cover your bases and accelerate ahead of Knox Grammar's curriculum with NSW's most effective tuition program.",
        hero_bg_image: require("./cover/iPadCover.webp"),
    }
]

const hero_Pymble = [
    {
        hero_text: "Pymble Ladies College students at Project Academy",
        hero_under_text: "Cover your bases and accelerate ahead of Pymble Ladies's curriculum with NSW's most effective tuition program.",
        hero_bg_image: require("./cover/iPadCover.webp"),
    }
]

const hero_NSG = [
    {
        hero_text: "North Sydney Girls students at Project Academy",
        hero_under_text: "Cover your bases and accelerate ahead of North Sydney Girls's curriculum with NSW's most effective tuition program.",
        hero_bg_image: require("./cover/iPadCover.webp"),
    }
]

const hero_NSB = [
    {
        hero_text: "North Sydney Boys students at Project Academy",
        hero_under_text: "Cover your bases and accelerate ahead of North Sydney Boys's curriculum with NSW's most effective tuition program.",
        hero_bg_image: require("./cover/iPadCover.webp"),
    }
]

const hero_Willoughby = [
    {
        hero_text: "Willoughby Girls students at Project Academy",
        hero_under_text: "Cover your bases and accelerate ahead of Willoughby Girls's curriculum with NSW's most effective tuition program.",
        hero_bg_image: require("./cover/iPadCover.webp"),
    }
]

const hero_Schools = [
    {
        hero_text: "Students from Over 100 Schools Learn at Project Academy",
        hero_under_text: "Project Academy is home to a diverse group of students that study at over 100+ different high schools across Greater Sydney.",
        hero_bg_image: require("./cover/iPadCover.webp"),
    }
]

export {
    hero_home, 
    hero_english,
    hero_economics,
    hero_chemistry, 
    hero_physics,
    hero_4UMaths,
    hero_3UMaths,
    hero_2UMaths,
    hero_Y11English,
    hero_Y11Econ,
    hero_Y11Chemistry,
    hero_Y11Physics,
    hero_Y11MathsAccel,
    hero_Y11MathsAdv,
    hero_Y10Maths,
    hero_Y9Maths,
    hero_Tutors,
    hero_Ipad,
    hero_ChatswoodHigh,
    hero_Normanhurst,
    hero_Knox,
    hero_NSG,
    hero_NSB,
    hero_Willoughby,
    hero_Pymble,
    hero_Schools
};