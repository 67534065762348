import React from 'react'
import resultsHome from '../assets/results/resultsHome.webp'
import "./Results.scss"

function Results() {
  return (
    <div className="container">
        <div className="results-container">
            <div className="results-left">
                <h2>Our Results</h2>
                <p>Every year, we keep our cohorts small to ensure every student receives the most personalised tuition possible. We let our results speak for themselves:</p>
                <div className="results-grid">
                        <div className="results-one">
                            <span>1 in 2</span>
                            <p>students achieved a <b>97+ ATAR</b></p>
                        </div>
                        <div className="results-two">
                            <span>1 in 3</span>
                            <p>students achieved a <b>99+ ATAR</b></p>
                        </div>
                        <div className="results-three">
                            <span>7 in 10</span>
                            <p>students achieved a <b>95+ ATAR</b></p>
                        </div>
                        <div className="results-four">
                            <span>99.95</span>
                            <p><b>6 students</b> achieved a 99.95 ATAR</p>
                        </div>
                        <div className="results-five">
                            <span>8</span>
                            <p><b>8 state ranks</b> were achieved in 2020 alone</p>
                        </div>
                        <div className="results-six">
                            <span>3 of 10</span>
                            <p>HSC Economics state ranks achieved by Project students</p>
                        </div>
                </div>
            </div>
            <div className="results-right">
                <img src={resultsHome} />
            </div>
        </div>
    </div>
  )
}

export default Results