const faq_NSB = [
    {
        question: "What is North Sydney Boys High rank in the HSC?",
        answer: "North Sydney Boys High school consistently achieves rankings within the top 10 in the HSC. In 2020, NBHS achieved rank 3 in all of NSW in the HSC. As a top selective high school, its students perform very well academically across most subjects each year."
    },
    {
        question: "What are North Sydney Boys High School fees like?",
        answer: "Being a government-run public high school, tuition at North Sydney Boys is free! However there are some costs that need to be covered by parents over the course of a year. These amount to around $1200 a year, and include purchasing uniforms, textbooks, parent’s association donations and school contribution fees."
    },
    {
        question: "What is the best tutoring for North Sydney Boys High School?",
        answer: "Project Academy has a long and successful relationship with students of North Sydney Boys’ High school. Many of our students come from North Sydney Boys and in the past, we’ve helped most of them achieve band 6 in the HSC! Each year, we help guide more than half of our students to achieve a >95 ATAR in the HSC, with the majority of our students achieving top band 6 grades in their respective subjects."
    },
    {
        question: "Do North Sydney Boys students often attend tutoring?",
        answer: "The majority of students of North Sydney Boys attend some form of coaching or tutoring. Many studying for the HSC attend Project Academy, and in the past, those who attended Project Academy have achieved top marks in the HSC."
    },
    {
        question: "What’s North Sydney suburb like?",
        answer: "North Sydney is an economically important suburb located centrally within Sydney. North Sydney possesses excellent transport, shopping and recreational amenities and Sydney CBD is a short journey away."
    },
    {
        question: "Is North Sydney Boys a good school?",
        answer: "North Sydney Boys’ High School’s academic performance is very strong, with the school consistently ranking within the top 10 schools in NSW in the HSC. Each year, large numbers of students from North Sydney Boys achieve band 6 grades in the HSC.."
    },
    {
        question: "What’s the student experience at North Sydney Boys High School like?",
        answer: "North Sydney Boys High School provides boys with a competitive environment in which high academic achievement is fostered. Students will meet like-minded boys and form life-long bonds on their high school journey. In addition to academic pursuits, North Sydney Boys offers many extra-curricular activities, such as musical bands, weekly sport and also international language exchange programs to Japan."
    },
]

export {
    faq_NSB
}