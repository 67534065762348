import React from 'react'
import Hero from '../../components/Hero'
import Profile from '../../components/Profile'
import LongHistory from '../../components/LongHistory'
import HSCSubjects from '../../components/HSCSubjects'
import Tutors from '../../components/TutorSection'
import FAQ from '../../components/FAQ'
import CTA from '../../components/CTA'
import SEO from '../../components/SEO'

import { allProfiles } from '../../assets/profileObjects'
import { hero_Knox } from '../../assets/HeroText'
import { faq_Knox } from '../../assets/FAQ/Knox'
import { history_Knox } from '../../assets/history/history'

import "../../components/TutorSection.css"

function Knox() {
    var tutorNameArray = [];
    var tutorArray = [];

    allProfiles?.map((tutor) => ( 
        (!tutorNameArray.includes(tutor.name) && tutor.school.includes("Knox"))
        ? (
            tutorNameArray.push(tutor.name),
            tutorArray.push(tutor)
            )
        : ""
    ));
    
    return (
    <>
        <SEO 
          title='Knox Grammar - Tutoring and Insights'
          description="Project Academy is a tutoring centre for Year 9 to Year 12 students attending Knox Grammar and is home to NSW's most passionate and inspirational teachers."
          name='Project Academy'
        />
        <Hero data={hero_Knox} />
        <LongHistory school={history_Knox} />
        <div className="tutor-container">
            <div className="container tutors-group">
                {tutorArray?.map((tutor) => (
                    <Profile tutorName={tutor.name} tutorSubject={tutor.subject}/>
                ))}
            </div>
        </div>
        {history_Knox?.map((n) => (
            <div className="container tailored-support">
                <h2>{n.headerTailored}</h2>
                <p>{n.paragraphTailorOne}</p>
                <p>{n.paragraphTailorTwo}</p>
                <p>{n.paragraphTailorThree}</p>
            </div>        
        ))}
        <HSCSubjects />
        <Tutors data="General" />
        <FAQ topic={faq_Knox} />
        <CTA />
    </>


  )
}

export default Knox