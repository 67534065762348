const iPadHero = [
    {
        heading: "Introducing the Project iPad",
        heroPoints: [
            "1. Introduction →",
            "2. Online Learning →",
            "3. Resources →",
            "4. Marking & Feedback →",
            "5. Three Week Trial →",
        ],
        hero_bg_image: require("./cover/iPadCover.webp"),
    }  
]

const lifeHero = [
    {
        heading: "Life at Project Academy",
        heroPoints: [
            "1. Academic Life →",
            "2. Community Life →",
            "3. Student Support & Wellbeing →",
            "4. Careers & Partnerships →",
            "5. Alumni Network →",
        ],
        hero_bg_image: require("./cover/LifeCover.jpg"),
    }
]

const tutorsHero = [
    {
        heading: "The Highest Performing Tutors",
        heroPoints: [
            "1. Introduction",
            "2. Featured tutors →",
            "3. Our tutors →",
        ],
        hero_bg_image: require("./cover/TutorsCover.webp"),
    }
]

const chatswoodHero = [
    {
        heading: "Welcome to Project Chatswood",
        heroPoints: [
            "1. A Beautiful campus →",
            "2. World Class Classroom Design →",
            "3. Purpose Built Classrooms →",
            "4. Grasslands Study Sanctuary →",
            "5. Staff & Students →",
            "6. Main Office →",
        ],
        hero_bg_image: require("./cover/ChatswoodCover.jpg"),
    }
]

export {iPadHero, lifeHero, chatswoodHero, tutorsHero};