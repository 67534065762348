const faq_Willoughby = [
    {
        question: "What’s the student experience at Willoughby Girls High School like?",
        answer: "Your time at Willoughby Girls High School will be one that provides you with a treasure trove of skills, experiences, memories and friends that will equip you to achieve higher and better at every stage of your life. Willoughby Girls offers good teachers and a competitive environment in which your academic abilities and confidence as a leader will be strongly fostered. You’ll find that the student community at Willoughby Girls High will support you through your time at high school. In addition to this, WGHS offers a wide range of non-academic subjects to enrich students, such as participation in musical bands."
    },
    {
        question: "Is Willoughby Girls High School a good school?",
        answer: "Willoughby Girls High School’s academic performance is strong, with the school consistently ranking within the top 80 schools in NSW in the HSC since 2010. Each year, many students from Willoughby achieve band 6 grades in the HSC."
    },
    {
        question: "What’s Willoughby suburb like?",
        answer: "Willoughby is a pleasant quiet residential suburb that is suited for students’ families. Nearby suburbs including Hornsby have excellent transport, shopping and recreational amenities."
    },
    {
        question: "What is the best tutoring for Willoughby Girls High School?",
        answer: "Project Academy has a long and successful relationship with students of Willoughby Girls High School. Each year, we help guide more than half of our students to achieve a >95 ATAR in the HSC, with the majority of our students achieving top band 6 grades in their respective subjects."
    },
    {
        question: "What are some notable alumni of Willoughby Girls High School?",
        answer: "The size of each annual cohort is roughly 150 students. Recent alumni are notable for being high achievers in academic fields such as 4 Unit Mathematics, 3 Unit Mathematics and Chemistry."
    },
    {
        question: "What is Willoughby Girls High rank in the HSC?",
        answer: "Willoughby Girls High School consistently achieves rankings within the top 80 in the HSC. In 2020, WGHS achieved rank 59 in all of NSW in the HSC. As a top selective high school, its students perform well academically across most subjects each year."
    },
    {
        question: "What are Willoughby Girls High School fees like?",
        answer: "Being a government-run public high school, tuition at Willoughby Girls High is free! However there are some costs that need to be covered by parents over the course of a year. These include purchasing textbooks, uniforms, parent’s association donations and school contribution fees."
    },
    {
        question: "Do Willoughby Girls High School students often attend tutoring?",
        answer: "The majority of students of Willoughby Girls High School students attend some form of coaching or tutoring, and those who attended Project Academy have achieved top marks in the HSC in the past."
    },
]

export {
    faq_Willoughby
}