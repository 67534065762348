const articlesHscEnglish = [
    {
        subject: "EXEMPLAR ESSAY",
        topic: "John Keats X Jane Campion Bright Star",
        description: "",
        img: require('../img-english/English 2.webp'),
        link: "https://projectacademy.nsw.edu.au/year-12-guides/exemplar-keats-x-campion-bright-star-essay"
    },
    {
        subject: "EXEMPLAR ESSAY",
        topic: "The Tempest and Hag-Seed",
        description: "",
        img: require('../img-english/English 3.webp'),
        link: "https://projectacademy.nsw.edu.au/year-12-guides/the-tempest-and-hag-seed-textual-conversations"
    },
    {
        subject: "EXEMPLAR ESSAY",
        topic: "Poetry of T.S. Eliot",
        description: "",
        img: require('../img-english/English 4.webp'),
        link: "https://projectacademy.nsw.edu.au/year-12-guides/t-s-eliot-exemplar-essay-module-b-hsc-english-advanced"
    },
    {
        subject: "EXEMPLAR ESSAY",
        topic: "King Richard III & Looking for Richard",
        description: "",
        img: require('../img-english/English 5.webp'),
        link: "https://projectacademy.nsw.edu.au/year-12-guides/king-richard-iii-x-looking-for-richard-essay"
    },
    {
        subject: "GUIDE",
        topic: "Ultimate Guide to Literary Techniques",
        description: "",
        img: require('../img-english/English 10.jpg'),
        link: "https://www.projectacademy.nsw.edu.au/year-12-guides/ultimate-guide-to-literary-techniques-in-hsc-english"
    },
    {
        subject: "GUIDE",
        topic: "Achieving 1st in English at Baulkham Hills",
        description: "",
        img: require('../img-english/English 1.webp'),
        link: "https://projectacademy.nsw.edu.au/year-12-guides/an-achieving-a-99-85-atar"
    },
    {
        subject: "GUIDE",
        topic: "Deep Dive: The Context of Plath & Hughes",
        description: "",
        img: require('../img-english/English 11.jpg'),
        link: "https://www.projectacademy.nsw.edu.au/year-12-guides/the-context-of-sylvia-plath-and-ted-hughes"
    },
]

export { articlesHscEnglish }