import React from 'react'
import "./Timetable.scss"
import {GetTimetable, CampusCalc, CapacityStatus} from "../assets/GetTimetable.js"


function TimetableSection(props) {
    var timetableArray = GetTimetable();
    var subjectArray = [];

    timetableArray?.map((i) => (
        i?.map((j) => {
            if (!j[1]) j[1] = "Normal Stream"
            if (j[1].includes("Accelerated")) j[1] = "Accelerated Stream"
            if (j[1].includes("Normal")) j[1] = "Normal Stream"
            if (j[1].includes("NBHS")) j[1] = "Normanhurst Boys Stream"
            if (j[1].includes("NSGHS")) j[1] = "North Sydney Girls Stream"
            //console.log(j[1]);
        })
    ));

    timetableArray?.forEach((n) => {
        if(n.length > 0 && n[0][0] === props.data) subjectArray.push(n)
    })

    return (
        <div className="container info-container timetableSection-container">
        <div className="info-left timetableSection-info-left">
            <div className="info-left-top">
                <div className="info-header timetableSection-info-header">
                        <h2>Timetable</h2>
                        <a href={`${process.env.REACT_APP_WT_LINK}`}><div className="hero-button timetableSection-hero-button">Secure your 3 week trial</div></a>
                </div>
            </div>
        </div>
        <div className="info-right timetableSection-info-left">
            {subjectArray?.map((i) => (
                <div className="timetableSection-group">
                    {i.map((j) => (
                        <div className="timetable-slot">
                            <p id="timetable-time-desktop" className="timetable-time">{j[3]} {j[2]}</p>
                            <p id="timetable-time-mobile" className="timetable-time">{j[3].substring(0,3)}<span className="timetable-time-pm">&nbsp;{j[2].replace(/\s/g, '')}</span></p>
                            <p className="timetable-stream">
                                {j[1]}
                            </p>
                            <CampusCalc campus={j[5]} />
                            <CapacityStatus capacity={j[7]} />
                        </div>
                    ))}
                </div>
            ))} 
            </div>
        </div>
    )
}

export default TimetableSection