import React from 'react'
import Hero from '../../components/Hero';
import Tutors from '../../components/TutorSection';
import Info from '../../components/Info';
import Results from '../../components/Results';
import BlogSection from '../../components/BlogSection';
import TimetableSection from '../../components/TimetableSection';
import CourseSchedule from '../../components/CourseSchedule';
import CTA from '../../components/CTA';
import Pricing from '../../components/Pricing';
import SEO from '../../components/SEO';

//Objects
import { home_InfoOne, home_InfoTwo, home_InfoThree } from '../../assets/InfoObjects';
import { infoProvenY124UMaths, infoResourcesY124UMaths } from '../../assets/infoObjects/infoY124UMaths';

//Data
import { hero_4UMaths } from '../../assets/HeroText';
import { schedulesY124UMaths } from '../../assets/courseSchedules/schedulesHSC';

function HSC4UMaths() {

  return (
    <>
        <SEO 
          title='HSC 4U Maths Tutoring | Project Academy'
          description='Year 12 HSC 4U Maths tutoring designed to maximise your chances of an upper Band 6. Access 99+ ATAR teachers, incredible resources and past papers.'
          name='Project Academy'
        />
        <Hero data={hero_4UMaths} />
        <Info data={infoProvenY124UMaths} />
        <Tutors data="HSC 4U Maths" />
        <Info data={infoResourcesY124UMaths} />
        <Info data={home_InfoTwo} />
        <CourseSchedule data={schedulesY124UMaths} />
        <TimetableSection data="HSC 4U Maths" />
        <Pricing data="HSC 4U Maths" />
        <BlogSection />
        <CTA />
    </>
        
  )
}

export default HSC4UMaths