import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

//Components
import Navbar from "./components/Navbar";
import NavbarMobile from "./components/NavbarMobile";
import Footer from "./components/Footer";

// Pages
import Home from "./pages/Home";
import About from "./pages/About";
import NotFound from "./pages/NotFound"

import HSCEnglish from "./pages/subjects/HSCEnglish";
import HSCEconomics from "./pages/subjects/HSCEconomics";
import HSCChemistry from "./pages/subjects/HSCChemistry";
import HSCPhysics from "./pages/subjects/HSCPhysics";
import HSC4UMaths from "./pages/subjects/HSC4UMaths";
import HSC3UMaths from "./pages/subjects/HSC3UMaths";
import HSC2UMaths from "./pages/subjects/HSC2UMaths";

import LifeProject from "./pages/LifeProject";
import Ipad from "./pages/Ipad";
import Timetable from "./pages/Timetable";
import Chatswood from "./pages/Chatswood";
import Tutorsandresults from "./pages/Tutorsandresults";

import Y11English from "./pages/subjects/Y11English";
import Y11Economics from "./pages/subjects/Y11Economics";
import Y11Chemistry from "./pages/subjects/Y11Chemistry";
import Y11Physics from "./pages/subjects/Y11Physics";
import Y11MathsAdv from "./pages/subjects/Y11AdvMaths";
import Y11MathsAccel from "./pages/subjects/Y11AccelMaths";

import Y10Maths from "./pages/subjects/Y10Maths";
import Y9Maths from "./pages/subjects/Y9Maths";

import ChatswoodHigh from "./pages/schools/ChatswoodHigh";
import Normanhurst from "./pages/schools/Normanhurst";
import Knox from "./pages/schools/Knox";
import Pymble from "./pages/schools/Pymble";
import NSB from "./pages/schools/NSB";
import NSG from "./pages/schools/NSG";
import Willoughby from "./pages/schools/Willoughby";
import Schools from "./pages/schools/Schools";

import TrialsHSC from "./pages/guide/TrialsHSC";

export default function App() {
  return (
    <HelmetProvider>
      <Router>
          <Navbar />
          <NavbarMobile /> 
        <Routes>
            <Route path='*' element={<NotFound />} />
            <Route exact path='/' element={<Home />} />
            {/* <Route path='/about' element={<About />} /> */}
            <Route path={process.env.REACT_APP_HSCENGLISH} element={<HSCEnglish />} />
            <Route path={process.env.REACT_APP_HSCECONOMICS} element={<HSCEconomics />} />
            <Route path={process.env.REACT_APP_HSCCHEMISTRY} element={<HSCChemistry />} />
            <Route path={process.env.REACT_APP_HSCPHYSICS} element={<HSCPhysics />} />
            <Route path={process.env.REACT_APP_HSC4UMATHS} element={<HSC4UMaths />} />
            <Route path={process.env.REACT_APP_HSC3UMATHS} element={<HSC3UMaths />} />
            <Route path={process.env.REACT_APP_HSC2UMATHS} element={<HSC2UMaths />} />

            <Route path={process.env.REACT_APP_Y11ENGLISH} element={<Y11English />} />
            <Route path={process.env.REACT_APP_Y11ECONOMICS} element={<Y11Economics />} />
            <Route path={process.env.REACT_APP_Y11CHEMISTRY} element={<Y11Chemistry />} />
            <Route path={process.env.REACT_APP_Y11PHYSICS} element={<Y11Physics />} />
            <Route path={process.env.REACT_APP_Y11MATHSADV} element={<Y11MathsAdv />} />
            <Route path={process.env.REACT_APP_Y11MATHSACCEL} element={<Y11MathsAccel />} />

            <Route path={process.env.REACT_APP_Y10MATHS} element={<Y10Maths />} />
            <Route path={process.env.REACT_APP_Y9MATHS} element={<Y9Maths />} />

            <Route path='/life-at-project' element={<LifeProject />} />
            <Route path='/ipad' element={<Ipad />} />
            <Route path='/timetable' element={<Timetable />} />
            <Route path='/tutors' element={<Tutorsandresults />} />
            <Route path='/chatswood' element={<Chatswood />} />
            <Route path='/school/' element={<Schools />} />

            <Route path='/school/chatswood-high' element={<ChatswoodHigh />} />
            <Route path='/school/normanhurst-boys' element={<Normanhurst />} />
            <Route path='/school/knox-grammar' element={<Knox />} />
            <Route path='/school/pymble-ladies' element={<Pymble />} />
            <Route path='/school/north-sydney-boys' element={<NSB />} />
            <Route path='/school/north-sydney-girls' element={<NSG />} />
            <Route path='/school/willoughby-girls' element={<Willoughby />} />

            <Route path='/guides/hsc-trials-collection' element={<TrialsHSC />} />
        </Routes>
        <Footer />
      </Router>
    </HelmetProvider>


  );
}

ReactDOM.render(<App />, document.getElementById("root"));