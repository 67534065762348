const Ipad_one = [
    {
        heading: "Truly engaging live online classes →",
        heading_para: "Online classes shouldn't be dry. We've spent thousands of hours designing a truly engaging, highly motivating and effective remote learning experience. Prepare to be wowed.",
        app_img_one: require("./ipad/Zoom.webp"),
        app_one: "Zoom"
    },
    {
        heading: "Help anytime, anywhere →",
        heading_para: "Difficult concepts appear often in the HSC. This is why we have tutors on standby so you can ask questions whenever you need. Stumped by your Maths homework? Message a tutor.",
        app_img_one: require("./ipad/Slack.webp"),
        app_one: "Zoom"
    },
    {
        section_heading: "Online Learning",
    }
]

const Ipad_two = [
    {
        heading: "Weekly state ranker class notes →",
        heading_para: "Your iPad has over 1,000 pages of notes for each subject. These notes are written in exceptional detail and mapped to the HSC curriculum, pointing out exactly what you need to know and look out for.",
        app_img_one: require("./ipad/Books.webp"),
        app_one: "Zoom"
    },
    {
        heading: "Thousands of past papers →",
        heading_para: "You receive exclusive access to over 1,000 past papers from HSC exams and school assessments. Our top teachers have written detailed solutions so you get plenty of practice and learn from your mistakes.",
        app_img_one: require("./ipad/Library.webp"),
        app_one: "Zoom"
    },
    {
        section_heading: "Resources"
    }
]

const Ipad_three = [
    {
        heading: "Receive marked homework →",
        heading_para: "Receive highly personalised feedback straight to your Documents App on your iPad, where you can easily track and check off every correction as you work through them.",
        app_img_one: require("./ipad/Documents.webp"),
        app_one: "Zoom"
    },
    {
        heading: "Every correction is tracked →",
        heading_para: "When Project markers mark your homework, they track every mistake into the app. Your tutor then go over your mistakes with you until you're absolutely confident.",
        app_img_one: require("./ipad/Analytics.webp"),
        app_one: "Zoom"
    },
    {
        section_heading: "Marking and feedback",
    }
]

export {
    Ipad_one,
    Ipad_two, 
    Ipad_three
}