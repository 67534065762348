import React, { useState } from 'react'

import './TrialsHSC.scss'

import SEO from '../../components/SEO'
import Hero from '../../components/Hero'
import BlogHero from '../../components/blog/BlogHero'
import BlogArticleWide from '../../components/blog/BlogArticleWide'
import BlogArticleSmall from '../../components/blog/BlogArticleSmall'
import BlogSection from '../../components/BlogSection'
import CTA from '../../components/CTA'

//Data
import {articlesHscEnglish} from '../../assets/blog/articles/articles-hscenglish';
import {articlesHscEconomics} from '../../assets/blog/articles/articles-hsceconomics';

//Images
import ChemistryMain from '../../assets/blog/main/Chemistry Main.jpg'
import PhysicsMain from '../../assets/blog/main/Physics Main.jpg'

import Cory from '../../assets/blog/main/Chem Main 3.jpg'
import Laura from '../../assets/blog/main/3U Maths.jpg'
import Ben from '../../assets/blog/main/2U Maths.jpg'

import EconMain1 from '../../assets/blog/main/Econ Main 1.jpg'
import EconMain2 from '../../assets/blog/main/Econ Main 2.jpg'
import EconMain3 from '../../assets/blog/main/Econ Main 3.jpg'

import MathMain from '../../assets/blog/main/Math Main.jpg'
import MathMain1 from '../../assets/blog/main/Math Main 1.jpg'
import MathMain2 from '../../assets/blog/main/Math Main 2.jpg'

import EnglishMain from '../../assets/blog/img-english/English Main.webp'
import EnglishMain2 from '../../assets/blog/main/English Main 2.jpg'
import EnglishMain3 from '../../assets/blog/main/English Main 3.jpg'
import EnglishMain4 from '../../assets/blog/main/English Main 4.jpg'
import English11 from '../../assets/blog/img-english/English 11.jpg'
import EconomicsMain from '../../assets/blog/img-english/Economics Main.webp'
import HSC from '../../assets/blog/img-english/HSC.jpg'

import up from '../../assets/blog/up-arrow.png'


function TrialsHSC() {

    //draw open/close
    const [showDraw, setShowDraw] = useState(1);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    function toggleDraw() {
        setShowDraw(!showDraw); 
        if (showDraw) document.getElementById("sticky-contents").style.display="block";
        if (!showDraw) document.getElementById("sticky-contents").style.display="none";
    }

    function toggleGradientEnglish() {
        document.getElementById("gradient-English").style.display="none";
        var elems = document.getElementsByClassName("gridEnglish");
        for (var i=0; i< elems.length; i+=1) elems[i].style.display="block";
    }
    function toggleGradientEconomics() {
        document.getElementById("gradient-Economics").style.display="none";
        var elems = document.getElementsByClassName("gridEconomics");
        for (var i=0; i< elems.length; i+=1) elems[i].style.display="block";
    }
    function toggleGradientChem() {
        document.getElementById("gradient-Chem").style.display="none";
        var elems = document.getElementsByClassName("gridChem");
        for (var i=0; i< elems.length; i+=1) elems[i].style.display="block";
    }
    function toggleGradientPhysics() {
        document.getElementById("gradient-Physics").style.display="none";
        var elems = document.getElementsByClassName("gridPhysics");
        for (var i=0; i< elems.length; i+=1) elems[i].style.display="block";
    }
    function toggleGradientM4() {
        document.getElementById("gradient-M4").style.display="none";
        var elems = document.getElementsByClassName("gridM4");
        for (var i=0; i< elems.length; i+=1) elems[i].style.display="block";
    }
    function toggleGradientM3() {
        document.getElementById("gradient-M3").style.display="none";
        var elems = document.getElementsByClassName("gridM3");
        for (var i=0; i< elems.length; i+=1) elems[i].style.display="block";
    }
    function toggleGradientM2() {
        document.getElementById("gradient-M2").style.display="none";
        var elems = document.getElementsByClassName("gridM2");
        for (var i=0; i< elems.length; i+=1) elems[i].style.display="block";
    }

  return (
    <div>
        <SEO 
          title='Preparation for Trials and HSC | Project Academy Exam Collections'
          description='A curated collection of Trials and HSC exam past papers and preparation resources written by our incredible 99+ ATAR teachers and state rankers.'
          name='Project Academy'
        />
        <div className="sticky">
            <div className="sticky-bar" onClick={toggleDraw}>
                <p>Jump to:</p>
                <img src={up} />
            </div>
            <div className="sticky-contents" id="sticky-contents">
                <p><a href="#trialsHSC-english">HSC English Advanced</a></p>
                <p><a href="#trialsHSC-economics">HSC Economics</a></p>
                <p><a href="#trialsHSC-chemistry">HSC Chemistry</a></p>
                <p><a href="#trialsHSC-physics">HSC Physics</a></p>
                <p><a href="#trialsHSC-maths">HSC Maths</a></p>
            </div>
        </div>
        <BlogHero />
        {/* HSC ENGLISH ADVANCED */}
        <div id="trialsHSC-english" className="trialsHSC-block">
            <div className="trialsHSC-subject-heading">
                <h2>HSC English Advanced</h2>
                <p>Guides</p>
            </div>
            <div className="trialsHSC-subject-section">
                <BlogArticleWide 
                    subject="STATE RANK GUIDE"
                    topic="Writing 20/20 English Advanced Essays"
                    desc="The ultimate guide to HSC English essay writing."
                    img={EnglishMain}
                    link="https://projectacademy.nsw.edu.au/year-12-guides/a-state-rankers-guide-to-writing-20-20-english-advanced-essays"
                />
                <BlogArticleWide 
                    subject="STATE RANK ESSAY"
                    topic="Nineteen Eighty-Four Exemplar Essay"
                    desc="Written and reviewed by state rankers."
                    img={EnglishMain2}
                    link="https://www.projectacademy.nsw.edu.au/year-12-guides/common-module-state-rank-essay-showcase-nineteen-eighty-four"
                />
                <BlogArticleWide 
                    subject="STATE RANK ESSAY"
                    topic="Sylvia Plath & Ted Hughes Exemplar Essay"
                    desc="Written and reviewed by state rankers."
                    img={EnglishMain3}
                    link="https://www.projectacademy.nsw.edu.au/year-12-guides/module-a-exemplar-essay-poetry-of-sylvia-plath-ted-hughes"
                />
                <BlogArticleWide 
                    subject="STATE RANK ESSAY"
                    topic="King Henry IV, Part 1 Exemplar Essay"
                    desc="Written and reviewed by state rankers."
                    img={EnglishMain4}
                    link="https://projectacademy.nsw.edu.au/year-12-guides/module-b-exemplar-essay-king-henry-iv-part-1-william-shakespeare"
                />
            </div>
            <div className="trialsHSC-right-grid">
                {articlesHscEnglish?.map((n) => (
                    <BlogArticleSmall subject={n.subject} topic={n.topic} img={n.img} link={n.link}/>
                ))}
            </div>
            <div className="trialsHSC-subject-heading">
                <p>Past Papers</p>
            </div>
            <div className="trialsHSC-grid">
                <BlogArticleSmall subject="PAST PAPER" topic="2022 HSC English Advanced Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/c8616543-b001-47c9-ac9d-1d851d45b657/2022-hsc-english-advanced-p1.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-c8616543-b001-47c9-ac9d-1d851d45b657-ofjX962" />
                <BlogArticleSmall subject="PAST PAPER" topic="2021 HSC English Advanced Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/ea67feba-dee3-45ad-a303-8a9c647611d3/2021-hsc-english-advanced-p1.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-ea67feba-dee3-45ad-a303-8a9c647611d3-nRfXx9H" />
                <BlogArticleSmall subject="PAST PAPER" topic="2020 HSC English Advanced Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/651ae3e4-2c53-4a11-8f8c-61fdd8e3fcab/2020-hsc-english-advanced-paper-1.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-651ae3e4-2c53-4a11-8f8c-61fdd8e3fcab-no2ONLA" />
                <div id="gradient-English" className="trialsHSC-grid-gradient" onClick={toggleGradientEnglish}></div>
                <BlogArticleSmall className="gridEnglish" subject="PAST PAPER" topic="2019 HSC English Advanced Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/eeff2995-6b97-4740-8ccd-ae4613b242e5/2019-hsc-english-advanced-p1.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-eeff2995-6b97-4740-8ccd-ae4613b242e5-mUqRA7U" />
                <BlogArticleSmall className="gridEnglish" subject="PAST PAPER" topic="2018 HSC English Advanced Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/e0b1fed1-1464-4198-8c56-0445ec1219eb/2018-hsc-english-std-adv-p1.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-e0b1fed1-1464-4198-8c56-0445ec1219eb-mD.BgrI" />
                <BlogArticleSmall className="gridEnglish" subject="PAST PAPER" topic="2017 HSC English Advanced Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/4fd3938f-959e-4b24-ad79-9b379aad98f7/2017-hsc-english-p1-std-and-adv.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-4fd3938f-959e-4b24-ad79-9b379aad98f7-mr28jcA" />
                <BlogArticleSmall className="gridEnglish" subject="PAST PAPER" topic="2016 HSC English Advanced Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/1b6c662e-e645-4f35-8523-82c10c2b62df/2016-hsc-english-p1-std-and-adv.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-1b6c662e-e645-4f35-8523-82c10c2b62df-lP3-egD" />
                <BlogArticleSmall className="gridEnglish" subject="PAST PAPER" topic="2015 HSC English Advanced Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/9ad94cec-c1dd-4079-bb55-794c528409dc/english-adv-hsc-exam-1-2015.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-9ad94cec-c1dd-4079-bb55-794c528409dc-lG90hnK" />
            </div>
        </div>
        {/* END HSC ENGLISH ADVANCED */}

        {/* HSC ECONOMICS */}
        <div id="trialsHSC-economics" className="trialsHSC-block">
            <div className="trialsHSC-subject-heading">
                <h2>HSC Economics</h2>
                <p>Guides</p>
            </div>
            <div className="trialsHSC-subject-section">
                <BlogArticleWide 
                    subject="STATE RANK GUIDE"
                    topic="Writing 20/20 Economics Essays"
                    desc="The ultimate guide to HSC Economics essay writing."
                    img={EconMain1}
                    link="https://projectacademy.nsw.edu.au/year-12-guides/a-state-rankers-guide-to-writing-20-20-english-advanced-essays"
                />
                <BlogArticleWide 
                    subject="STATE RANK GUIDE"
                    topic="Guide to Collecting Stats for HSC Economics"
                    desc="The ultimate guide on how to approach stats collection."
                    img={EconMain2}
                    link="https://about.projectacademy.nsw.edu.au/year-12-guides/how-to-collect-statistics-for-hsc-prelim-economics"
                />
                <BlogArticleWide 
                    subject="EXAM GUIDE"
                    topic="Ultimate Guide to HSC Econ Past Papers"
                    desc="How to leverage past papers for Trials and HSC prep."
                    img={EconMain3}
                    link="https://projectacademy.nsw.edu.au/year-12-guides/ultimate-guide-to-hsc-economics-past-papers"
                />
            </div>
            <div className="trialsHSC-right-grid">
                {articlesHscEconomics?.map((n) => (
                    <BlogArticleSmall subject={n.subject} topic={n.topic} img={n.img} link={n.link}/>
                ))}
            </div>
            <div className="trialsHSC-subject-heading">
                <p>Past Papers</p>
            </div>
            <div className="trialsHSC-grid">
                <BlogArticleSmall subject="PAST PAPER" topic="2022 HSC Economics Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/f3c8d13d-ea41-46ca-8282-2ef78d98107a/2022-hsc-economics.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-f3c8d13d-ea41-46ca-8282-2ef78d98107a-ogWJo4n" />
                <BlogArticleSmall subject="PAST PAPER" topic="2021 HSC Economics Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/b9e06ba3-874c-4aac-93db-4345169ec9a6/2021-hsc-economics.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-b9e06ba3-874c-4aac-93db-4345169ec9a6-nTqz50q" />
                <BlogArticleSmall subject="PAST PAPER" topic="2020 HSC Economics Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/5ea67d95-57f8-47e3-9a4e-400d83d82837/2020-hsc-economics.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-5ea67d95-57f8-47e3-9a4e-400d83d82837-nxyzLGA" />
                <div id="gradient-Economics" className="trialsHSC-grid-gradient" onClick={toggleGradientEconomics}></div>
                <BlogArticleSmall className="gridEconomics" subject="PAST PAPER" topic="2019 HSC Economics Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/c8a70860-be55-4e9f-a3ec-7ef02307806b/2019-hsc-economics.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-c8a70860-be55-4e9f-a3ec-7ef02307806b-mV8nZUz" />
                <BlogArticleSmall className="gridEconomics" subject="PAST PAPER" topic="2018 HSC Economics Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/6a7e7c78-0dc8-44c1-b701-b4e358541657/2018-hsc-economics.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-6a7e7c78-0dc8-44c1-b701-b4e358541657-mD.A81c" />
                <BlogArticleSmall className="gridEconomics" subject="PAST PAPER" topic="2017 HSC Economics Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/fef45a39-d7af-40d5-9ad0-b4f987be28c2/2017-hsc-economics.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-fef45a39-d7af-40d5-9ad0-b4f987be28c2-l-USlY0" />
                <BlogArticleSmall className="gridEconomics" subject="PAST PAPER" topic="2016 HSC Economics Exam" img={HSC} link="https://www.boardofstudies.nsw.edu.au/hsc_exams/2016/exams/2016-hsc-economics.pdf" />
                <BlogArticleSmall className="gridEconomics" subject="PAST PAPER" topic="2015 HSC Economics Exam" img={HSC} link="https://www.boardofstudies.nsw.edu.au/hsc_exams/2015/exams/2015-hsc-economics.pdf" />
            </div>
        </div>
        {/* END HSC ECONOMICS */}

        {/* HSC CHEMISTRY */}
        <div id="trialsHSC-chemistry" className="trialsHSC-block">
            <div className="trialsHSC-subject-heading">
                <h2>HSC CHEMISTRY</h2>
                <p>Guides</p>
            </div>
            <div className="trialsHSC-subject-section">
                <BlogArticleWide 
                    subject="GUIDE"
                    topic="How to actually study for HSC Chemistry"
                    desc="The ultimate guide to HSC Chemistry study technique."
                    img={ChemistryMain}
                    link="https://www.projectacademy.nsw.edu.au/year-12-guides/how-to-actually-study-for-hsc-chemistry"
                />
                <BlogArticleWide 
                    subject="GUIDE"
                    topic="Guide to Mod 6: Acid/Base Reactions"
                    desc="The ultimate guide to acid/base reactions."
                    img={ChemistryMain}
                    link="https://www.projectacademy.nsw.edu.au/year-12-guides/complete-guide-hsc-chemistry-module-6-acid-base-reactions"
                />
                <BlogArticleWide 
                    subject="GUIDE"
                    topic="Guide to Reactions & Chemical Processes"
                    desc="The ultimate guide to reactions and chemical processes."
                    img={ChemistryMain}
                    link="https://www.projectacademy.nsw.edu.au/year-12-guides/complete-guide-to-reactions-and-chemical-processes"
                />
                <BlogArticleWide 
                    subject="STATE RANK GUIDE"
                    topic="How I came 12th in NSW for HSC Chemistry"
                    desc="Written by Cory Aitchison, 12th in NSW for HSC Chemistry."
                    img={Cory}
                    link="https://www.projectacademy.nsw.edu.au/headline-articles/achieving-two-state-ranks-and-a-99-95-atar"
                />
            </div>
            
            <div className="trialsHSC-subject-heading">
                <p>Past Papers</p>
            </div>
            <div className="trialsHSC-grid">
                <BlogArticleSmall subject="PAST PAPER" topic="2022 HSC Chemistry Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/f9447ca0-8d67-474a-a8a7-ccda95061656/2022-hsc-chemistry.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-f9447ca0-8d67-474a-a8a7-ccda95061656-ogRvEgE" />
                <BlogArticleSmall subject="PAST PAPER" topic="2021 HSC Chemistry Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/e75585c5-1fe2-455a-a1bf-6c2c84c42d0a/2021-hsc-chemistry.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-e75585c5-1fe2-455a-a1bf-6c2c84c42d0a-nSymB4p" />
                <BlogArticleSmall subject="PAST PAPER" topic="2020 HSC Chemistry Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/3b6d7bfe-e406-4eab-aa4d-161054e717f7/2020-hsc-chemistry.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-3b6d7bfe-e406-4eab-aa4d-161054e717f7-nmQlO74" />
                <div id="gradient-Chem" className="trialsHSC-grid-gradient" onClick={toggleGradientChem}></div>
                <BlogArticleSmall className="gridChem" subject="PAST PAPER" topic="2019 HSC Chemistry Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/e9541cfe-0a5a-499c-9c25-ae06ffd3a2c0/2019-hsc-chemistry.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-e9541cfe-0a5a-499c-9c25-ae06ffd3a2c0-mVt82KS" />
                <BlogArticleSmall className="gridChem" subject="PAST PAPER" topic="2018 HSC Chemistry Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/b17504ca-d354-4e45-ba86-e36a1bdb00ad/2018-hsc-chemistry.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-b17504ca-d354-4e45-ba86-e36a1bdb00ad-mrMhAnN" />
                <BlogArticleSmall className="gridChem" subject="PAST PAPER" topic="2017 HSC Chemistry Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/cb65ca25-fc28-4e21-8f2d-4886bf176ddd/2017-hsc-chemistry.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-cb65ca25-fc28-4e21-8f2d-4886bf176ddd-lZ.o-ZW" />
                <BlogArticleSmall className="gridChem" subject="PAST PAPER" topic="2016 HSC Chemistry Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/e76232cd-1d84-4d3e-92ce-ea41546810f6/2016-hsc-chemistry.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-e76232cd-1d84-4d3e-92ce-ea41546810f6-lBJsKUq" />
                <BlogArticleSmall className="gridChem" subject="PAST PAPER" topic="2015 HSC Chemistry Exam" img={HSC} link="https://www.boardofstudies.nsw.edu.au/hsc_exams/2015/exams/2015-hsc-chemistry.pdf" />
            </div>
        </div>
        {/* END HSC CHEMISTRY */}

        {/* HSC Physics */}
        <div id="trialsHSC-physics" className="trialsHSC-block">
            <div className="trialsHSC-subject-heading">
                <h2>HSC Physics</h2>
                <p>Guides</p>
            </div>
            <div className="trialsHSC-subject-section">
                <BlogArticleWide 
                    subject="STATE RANK GUIDE"
                    topic="How to actually study for HSC Physics"
                    desc="The ultimate guide to HSC Physics study technique."
                    img={PhysicsMain}
                    link="https://www.projectacademy.nsw.edu.au/year-12-guides/the-fundamental-physics-strategy"
                />
                <BlogArticleWide 
                    subject="STATE RANK GUIDE"
                    topic="How to answer long and short answer questions"
                    desc="The ultimate guide to answering long and short answer questions."
                    img={PhysicsMain}
                    link="https://projectacademy.nsw.edu.au/year-12-guides/hsc-physics-how-to-answer-short-and-long-questions"
                />
                <BlogArticleWide 
                    subject="STATE RANK GUIDE"
                    topic="Guide to Mod 6: Electromagnetism"
                    desc="A deep dive into Module 6: Electromagnetism."
                    img={PhysicsMain}
                    link="https://projectacademy.nsw.edu.au/year-12-guides/year-12-hsc-physics-module-6-electromagnetism"
                />
            </div>
            <div className="trialsHSC-subject-heading">
                <p>Past Papers</p>
            </div>
            <div className="trialsHSC-grid">
                <BlogArticleSmall subject="PAST PAPER" topic="2022 HSC Physics Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/f5536414-e9af-4371-be12-3155664b9792/2022-hsc-physics.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-f5536414-e9af-4371-be12-3155664b9792-ohf9VbD" />
                <BlogArticleSmall subject="PAST PAPER" topic="2021 HSC Physics Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/d26accd3-a77e-493f-8529-ff6418585a06/2021-hsc-physics.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-d26accd3-a77e-493f-8529-ff6418585a06-nRJx0k3" />
                <BlogArticleSmall subject="PAST PAPER" topic="2020 HSC Physics Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/c1fd9b67-e8dc-48ef-b7aa-0ed7b0edf0fd/2020-hsc-physics.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-c1fd9b67-e8dc-48ef-b7aa-0ed7b0edf0fd-no2PxI8" />
                <div id="gradient-Physics" className="trialsHSC-grid-gradient" onClick={toggleGradientPhysics}></div>
                <BlogArticleSmall subject="PAST PAPER" topic="2019 HSC Physics Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/83f6918e-c426-4cce-a0eb-680752290bba/2019-hsc-physics.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-83f6918e-c426-4cce-a0eb-680752290bba-mVxIvfP" />
                <BlogArticleSmall subject="PAST PAPER" topic="2018 HSC Physics Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/dfca82ef-5e10-45dd-b908-dec528d2bf77/2018-hsc-physics.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-dfca82ef-5e10-45dd-b908-dec528d2bf77-mrMizNb" />
                <BlogArticleSmall subject="PAST PAPER" topic="2017 HSC Physics Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/27f6eee8-da04-4373-aa0d-6e038f04a92e/2017-hsc-physics.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-27f6eee8-da04-4373-aa0d-6e038f04a92e-lZQpaBk" />
                <BlogArticleSmall subject="PAST PAPER" topic="2016 HSC Physics Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/5c414559-9792-41e6-bd98-e43c9097168d/2016-hsc-physics.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-5c414559-9792-41e6-bd98-e43c9097168d-lChkvU4" />
                <BlogArticleSmall subject="PAST PAPER" topic="2015 HSC Physics Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/fb68aa28-8d98-463f-84e3-e167eed76972/physics-hsc-exam-2015.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-fb68aa28-8d98-463f-84e3-e167eed76972-lG90KzN" />
            </div>
        </div>
        {/* END HSC Physics */}

        {/* HSC Maths */}
        <div id="trialsHSC-maths" className="trialsHSC-block">
            <div className="trialsHSC-subject-heading">
                <h2>HSC Maths</h2>
                <p>Guides</p>
            </div>
            <div className="trialsHSC-subject-section">
                <BlogArticleWide 
                    subject="GUIDE"
                    topic="4U Maths Past Paper Strategy"
                    desc="Written by ex-Head of Maths and 1st in NSB for 4U Maths (98)."
                    img={MathMain}
                    link="https://projectacademy.nsw.edu.au/year-12-guides/4u-maths-past-paper-strategiess"
                />
                <BlogArticleWide 
                    subject="STATE RANK GUIDE"
                    topic="3U Maths Past Paper Strategies"
                    desc="Written by 3rd in NSW for 3U Maths (100)."
                    img={MathMain1}
                    link="https://projectacademy.nsw.edu.au/hsc-success-stories/3u-maths-past-paper-strategies"
                />
                <BlogArticleWide 
                    subject="STATE RANK GUIDE"
                    topic="How I came 3rd in NSW for 3U Maths"
                    desc="Project alumni Laura Hardy shares her study secrets."
                    img={Laura}
                    link="https://projectacademy.nsw.edu.au/hsc-success-stories/how-i-achieved-3rd-in-nsw-for-3u-maths-and-a-99-80-atar"
                />
                <BlogArticleWide 
                    subject="STATE RANK GUIDE"
                    topic="How I came 12th in NSW for 2U Maths"
                    desc="Project alumni Benjamin Tran shares his study secrets."
                    img={Ben}
                    link="https://projectacademy.nsw.edu.au/hsc-success-stories/the-mindset-and-habits-of-a-hsc-maths-state-ranker-ben-tran"
                />
            </div>
            <div className="trialsHSC-subject-heading">
                <p>4U Maths Past Papers</p>
            </div>
            <div className="trialsHSC-grid">
                <BlogArticleSmall subject="PAST PAPER" topic="2022 HSC 4U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/b1c14c48-d8e7-4b10-9f28-84ce9f0d65a8/2022-hsc-mathematics-extension-2.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-b1c14c48-d8e7-4b10-9f28-84ce9f0d65a8-og8aQIY" />
                <BlogArticleSmall subject="PAST PAPER" topic="2021 HSC 4U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/0af4be99-49e1-48b0-be8a-1918bbfadc5c/2021-hsc-mathematics-extension-2.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-0af4be99-49e1-48b0-be8a-1918bbfadc5c-nQGVMC." />
                <BlogArticleSmall subject="PAST PAPER" topic="2020 HSC 4U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/49c4cd12-5c7f-4969-93fd-b34b06426620/2020-hsc-mathematics-extension-2.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-49c4cd12-5c7f-4969-93fd-b34b06426620-no2PTNa" />
                <div id="gradient-M4" className="trialsHSC-grid-gradient" onClick={toggleGradientM4}></div>
                <BlogArticleSmall className="gridM4" subject="PAST PAPER" topic="2019 HSC 4U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/991319b8-413f-4389-8d15-5942641b2d89/2019-hsc-mathematics-extension-2.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-991319b8-413f-4389-8d15-5942641b2d89-mUkr8K6" />
                <BlogArticleSmall className="gridM4" subject="PAST PAPER" topic="2018 HSC 4U Maths Exam" img={HSC} link="https://www.educationstandards.nsw.edu.au/wps/wcm/connect/faaffce8-847b-4fc6-8d72-4cb072dd8c56/2018-hsc-mathematics-ext-2.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-faaffce8-847b-4fc6-8d72-4cb072dd8c56-mr2.1-X" />
                <BlogArticleSmall className="gridM4" subject="PAST PAPER" topic="2017 HSC 4U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/700df45c-5316-4877-9766-ccd681854bb8/2017-hsc-maths-ext-2.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-700df45c-5316-4877-9766-ccd681854bb8-lZgHUPF" />
                <BlogArticleSmall className="gridM4" subject="PAST PAPER" topic="2016 HSC 4U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/d483decf-16ed-4afd-b3da-60807d00addd/2016-hsc-maths-ext-2.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-d483decf-16ed-4afd-b3da-60807d00addd-lCkVLwF" />
                <BlogArticleSmall className="gridM4" subject="PAST PAPER" topic="2015 HSC 4U Maths Exam" img={HSC} link="https://www.boardofstudies.nsw.edu.au/hsc_exams/2015/exams/2015-hsc-maths-ext-2.pdf" />
            </div>
            <div className="trialsHSC-subject-heading">
                <p>3U Maths Past Papers</p>
            </div>
            <div className="trialsHSC-grid">
                <BlogArticleSmall subject="PAST PAPER" topic="2022 HSC 3U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/c2f22c69-d21a-417b-9f12-4efdeecf9e23/2022-hsc-mathematics-extension-1.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-c2f22c69-d21a-417b-9f12-4efdeecf9e23-oghstyU" />
                <BlogArticleSmall subject="PAST PAPER" topic="2021 HSC 3U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/a8bf2663-fbd2-4dba-97e9-198d76d11def/2021-hsc-mathematics-extension-1.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-a8bf2663-fbd2-4dba-97e9-198d76d11def-nR9AG40" />
                <BlogArticleSmall subject="PAST PAPER" topic="2020 HSC 3U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/fbeee854-66b0-4905-873d-f59f48735f4a/2020-hsc-mathematics-extension-1.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-fbeee854-66b0-4905-873d-f59f48735f4a-no2Q1c5" />
                <div id="gradient-M3" className="trialsHSC-grid-gradient" onClick={toggleGradientM3}></div>
                <BlogArticleSmall className="gridM3" subject="PAST PAPER" topic="2019 HSC 3U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/7546e0b2-1a7a-4883-b7c8-850adc8fcf85/2019-hsc-mathematics-extension-1.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-7546e0b2-1a7a-4883-b7c8-850adc8fcf85-mUZx14W" />
                <BlogArticleSmall className="gridM3" subject="PAST PAPER" topic="2018 HSC 3U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/8e9f3072-c973-43d9-90d7-c1bea2b136ab/2018-hsc-mathematics-ext-1.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-8e9f3072-c973-43d9-90d7-c1bea2b136ab-mrhngT-" />
                <BlogArticleSmall className="gridM3" subject="PAST PAPER" topic="2017 HSC 3U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/807028e6-87cd-4c19-bf19-9c3c7f6e6be8/2017-hsc-maths-ext-1.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-807028e6-87cd-4c19-bf19-9c3c7f6e6be8-l-jq7Cv" />
                <BlogArticleSmall className="gridM3" subject="PAST PAPER" topic="2016 HSC 3U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/756b83c4-d51a-4033-ae36-ff7cd39d4369/2016-hsc-maths-ext-1.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-756b83c4-d51a-4033-ae36-ff7cd39d4369-lCkVKC5" />
                <BlogArticleSmall className="gridM3" subject="PAST PAPER" topic="2015 HSC 3U Maths Exam" img={HSC} link="https://www.boardofstudies.nsw.edu.au/hsc_exams/2015/exams/2015-hsc-maths-ext-1.pdf" />
            </div>
            <div className="trialsHSC-subject-heading">
                <p>2U Maths Past Papers</p>
            </div>
            <div className="trialsHSC-grid">
                <BlogArticleSmall subject="PAST PAPER" topic="2022 HSC 2U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/bf12620b-b016-43d0-b739-44aaa5fc8bfd/2022-hsc-mathematics-advanced.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-bf12620b-b016-43d0-b739-44aaa5fc8bfd-og8aEQB" />
                <BlogArticleSmall subject="PAST PAPER" topic="2021 HSC 2U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/eaf73553-d943-4917-a656-7aaec9696b26/2021-hsc-mathematics-advanced.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-eaf73553-d943-4917-a656-7aaec9696b26-nQGVmVS" />
                <BlogArticleSmall subject="PAST PAPER" topic="2020 HSC 2U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/f445e36c-01cd-42a5-be6f-7d409c1dfef0/2020-hsc-mathematics-advanced.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-f445e36c-01cd-42a5-be6f-7d409c1dfef0-no2Q3OH" />
                <div id="gradient-M2" className="trialsHSC-grid-gradient" onClick={toggleGradientM2}></div>
                <BlogArticleSmall className="gridM2" subject="PAST PAPER" topic="2019 HSC 2U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/8615a669-1ff2-4f16-b66b-ffba0dab89f8/2019-hsc-mathematics.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-8615a669-1ff2-4f16-b66b-ffba0dab89f8-mUkq.u5" />
                <BlogArticleSmall className="gridM2" subject="PAST PAPER" topic="2018 HSC 2U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/9b8503bb-b6bb-4818-803a-661ba2289782/2018-hsc-mathematics.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-9b8503bb-b6bb-4818-803a-661ba2289782-mrcp3WD" />
                <BlogArticleSmall className="gridM2" subject="PAST PAPER" topic="2017 HSC 2U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/bc11fd79-e2ec-4c02-967c-b31a741b621c/2017-hsc-maths.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-bc11fd79-e2ec-4c02-967c-b31a741b621c-lZgGNg5" />
                <BlogArticleSmall className="gridM2" subject="PAST PAPER" topic="2016 HSC 2U Maths Exam" img={HSC} link="https://educationstandards.nsw.edu.au/wps/wcm/connect/47b7a5d2-8729-4d55-825a-7e840444d93f/2016-hsc-maths.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-47b7a5d2-8729-4d55-825a-7e840444d93f-lCkVM2M" />
                <BlogArticleSmall className="gridM2" subject="PAST PAPER" topic="2015 HSC 2U Maths Exam" img={HSC} link="https://www.boardofstudies.nsw.edu.au/hsc_exams/2015/exams/2015-hsc-maths.pdf" />
            </div>
        </div>
        {/* END HSC Maths */}
        <hr />
        <br />
        <br />
        <BlogSection />
        <CTA />
    </div>
  )
}

export default TrialsHSC