import React from 'react'

import Chatswood from "../assets/schools/Chatswood.webp"
import NSG from "../assets/schools/NSG.webp"
import NSB from "../assets/schools/NSB.webp"
import Willoughby from "../assets/schools/Willoughby.webp"
import Pymble from "../assets/schools/Pymble.webp"
import Knox from "../assets/schools/Knox.webp"
import Normo from "../assets/schools/Normo.webp"

function SchoolList() {
  return (
    <div className="container">
        <div className="subjects-list">
        <a href="/school/north-sydney-girls">
            <div className="subject-item">
                <img src={NSG} />
                <div className="subject-item-textgroup">
                    <p className="subject-item-subject">North Sydney Girls</p>
                    <p className="subject-item-paragraph">Learn more about North Sydney Girls at Project Academy.</p>
                </div>
            </div>
        </a>
        <a href="/school/knox-grammar">
            <div className="subject-item">
                <img src={Knox} />
                <div className="subject-item-textgroup">
                    <p className="subject-item-subject">Knox Grammar</p>
                    <p className="subject-item-paragraph">Learn more about Knox Grammar at Project Academy.</p>
                </div>
            </div>
        </a>
        <a href="/school/north-sydney-boys">
            <div className="subject-item">
                <img src={NSB} />
                <div className="subject-item-textgroup">
                    <p className="subject-item-subject">North Sydney Boys</p>
                    <p className="subject-item-paragraph">Learn more about North Sydney Boys at Project Academy.</p>
                </div>
            </div>
        </a>
        <a href="/school/normanhurst-boys">
            <div className="subject-item">
                <img src={Normo} />
                <div className="subject-item-textgroup">
                    <p className="subject-item-subject">Normanhurst Boys</p>
                    <p className="subject-item-paragraph">Learn more about Normanhurst Boys at Project Academy.</p>
                </div>
            </div>
        </a>
        <a href="/school/pymble-ladies">
            <div className="subject-item">
                <img src={Pymble} />
                <div className="subject-item-textgroup">
                    <p className="subject-item-subject">Pymble Ladies College</p>
                    <p className="subject-item-paragraph">Learn more about Pymble Ladies College at Project Academy.</p>
                </div>
            </div>
        </a>
        <a href="/school/willoughby-girls">
            <div className="subject-item">
                <img src={Willoughby} />
                <div className="subject-item-textgroup">
                    <p className="subject-item-subject">Willoughby Girls</p>
                    <p className="subject-item-paragraph">Learn more about Willoughby Girls at Project Academy.</p>
                </div>
            </div>
        </a>
        <a href="/school/chatswood">
            <div className="subject-item">
                <img src={Chatswood} />
                <div className="subject-item-textgroup">
                    <p className="subject-item-subject">Chatswood High</p>
                    <p className="subject-item-paragraph">Learn more about Chatswood High at Project Academy.</p>
                </div>
            </div>
        </a>
        </div>
    </div>
  )
}

export default SchoolList