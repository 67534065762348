import React from 'react'
import Hero from '../../components/Hero';
import Tutors from '../../components/TutorSection';
import Info from '../../components/Info';
import Results from '../../components/Results';
import BlogSection from '../../components/BlogSection';
import TimetableSection from '../../components/TimetableSection';
import CourseSchedule from '../../components/CourseSchedule';
import CTA from '../../components/CTA';
import Pricing from '../../components/Pricing';
import SEO from '../../components/SEO';

//Objects
import { home_InfoTwo } from '../../assets/InfoObjects';
import { infoProvenY11Physics, infoResourcesY11Physics } from '../../assets/infoObjects/infoY11Physics';

//Data
import { hero_Y11Physics } from '../../assets/HeroText';
import { schedulesY11Physics } from '../../assets/courseSchedules/schedulesY11';

function Y11Physics() {

  return (
    <>
        <SEO 
          title='Year 11 Physics Tutoring | Project Academy'
          description='Year 11 Physics tutoring designed to maximise your chances of an upper Band 6. Access 99+ ATAR teachers, incredible resources and past papers.'
          name='Project Academy'
        />
        <Hero data={hero_Y11Physics} />
        <Info data={infoProvenY11Physics} />
        <Tutors data="HSC Physics" />
        <Info data={infoResourcesY11Physics} />
        <Info data={home_InfoTwo} />
        <CourseSchedule data={schedulesY11Physics} />
        <TimetableSection data="Y11 Physics" />
        <Pricing data="Y11 Physics" />
        <BlogSection />
        <CTA />
    </>
        
  )
}

export default Y11Physics