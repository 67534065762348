import React, {useState} from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./CourseSchedule.scss";
import "./Info.scss";

function CourseSchedule({data}) {
    return (
    <div>
        <Tabs className="container info-container courseschedule" defaultIndex={0} selectedTabClassName="tablist-selected">
            <div className="info-left">
                <div className="info-left-top">
                    <div className="info-header">
                            <h2>Course Schedule</h2>
                    </div>
                    <TabList className="tablist">
                        {/* ? is required below to check if array exists */}
                        {data?.map((n) => (
                            <Tab key={n.heading} className="tablist-item">{n.heading}</Tab>
                        ))
                        }
                    </TabList>
                </div>
            </div>
            
            <div className="info-right courseschedules-info-right">
            {/* ? is required below to check if array exists */}
            {data?.map((i) => (
                <TabPanel>
                    <div className="schedules-group">
                        <div className="schedules-container">
                            <div className="schedules-header schedules-one">Term 1</div>
                            <div className="schedules-body">
                                <p>{i.text}</p>
                                <ul>
                                    {i.term1?.map((n) => (
                                        <li key={n}>{n}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="schedules-container">
                            <div className="schedules-header schedules-two">Term 2</div>
                            <div className="schedules-body">
                                <p>{i.text}</p>
                                <ul>
                                    {i.term2?.map((n) => (
                                        <li key={n}>{n}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="schedules-group">
                        <div className="schedules-container">
                            <div className="schedules-header schedules-three">Term 3</div>
                            <div className="schedules-body">
                                <p>{i.text}</p>
                                <ul>
                                    {i.term3?.map((n) => (
                                        <li key={n}>{n}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="schedules-container">
                            <div className="schedules-header schedules-four">Term 4</div>
                            <div className="schedules-body">
                                <p>{i.text}</p>
                                <ul>
                                    {i.term4?.map((n) => (
                                        <li key={n}>{n}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            ))}
            </div>
        </Tabs>
        
    </div>
  )
}

export default CourseSchedule