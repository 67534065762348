//Y10 Maths
//Y10 Maths
//Y10 Maths
//Y10 Maths
//Y10 Maths

const schedulesY10Maths = [
    {
        heading: "Normal Schedule →",
        text: "",
        term1: 
            [
                "Alebraic Techniques",
                "Surds and Indices",
                "Solving Equations",
                "Linear Relationships",
                "Non-linear Relationships",
                "Financial Mathematics",
                "Rates and Ratio",
                "Revisions",
                "Exam and Review"
            ],
        term2:
            [
                "Trigonometry",
                "Geometric Figures",
                "Areas and Volume",
                "Probability",
                "Statistics",
                "Exan and Review"
            ],
        term3: 
            [
                "Polynomials",
                "Logarithms",
                "Functions and Other Graphs",
                "Circle Geometry",
                "Exam and Review"
            ],
        term4:
            [
                "Series",
                "Curve Sketching",
                "Combinatrics",
                "Exam and Review",
                "Comprehensive Exam Prep"

            ]
    },
]

//Y9 Maths
//Y9 Maths
//Y9 Maths
//Y9 Maths
//Y9 Maths

const schedulesY9Maths = [
    {
        heading: "Normal Schedule →",
        text: "",
        term1: 
            [
                "Algebra and Numbers",
                "Equations and Inequalities",
                "Simultaneous Equations",
                "Exam Review"
            ],
        term2:
            [
                "Linear Relationships",
                "Quadratic Equations and Parabolas",
                "Exam and Review"
            ],
        term3: 
            [
                "Non-linear Relationships",
                "Trigonometry",
                "Exam and Review",
                "Geometry"
            ],
        term4:
            [
                "Exam and Review",
                "Statistics",
                "Probability",
                "Financial Maths",
                "Final Exam and Review"
            ]
    },
]

export {
    schedulesY10Maths,
    schedulesY9Maths
}