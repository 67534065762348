const history_ChatswoodHigh = [
    {
        header: "Our Long History with Chatswood High",
        paragraph: "Chatswood High School students have had a successful and long-standing relationship with Project Academy. With two school duxes amongst our alumni, students from CHS have consistently achieved highly with Project Academy’s assistance. Every year since our establishment, we’ve taught a growing cohort of Chatswood High students, and we’re excited to take you onboard too!",
        headerTwo: "Chatswood High Alumni, Teachers and Students",
        paragraphTwo: "Over the last few years, we’ve had the opportunity to support and work with many Chatswood High students in the Project Family. This is the success we’ve achieved together:",
        image: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/6194d2065e8b16ac3f6f2f92_ChatswoodHS_logo-p-500.png",
        headerTailored: "Tailored support for Chatswood High students",
        paragraphTailorOne: "At Project Academy, we understand what it takes to succeed at CHS and in the HSC. For our CHS students, we've created classes and exams that follow the structure and style of CHS’ curriculum, to help you score at the very top of your cohort.",
        paragraphTailorTwo: "We can offer you this because our teachers include state-rankers and internal 1st ranks at Chatswood High School.",
        paragraphTailorThree: "Project Academy is not just a tuition centre, we’re here to be your extended family, to help you achieve your very best in the HSC and be there for you every step of the way."
    }
]

const history_Normanhurst = [
    {
        header: "Our Long History with Normanhurst Boys",
        paragraph: "Normanhurst Boys High School students have had a successful and long-standing relationship with Project Academy. From our founders to the school captain and dux of 2019, students from Normanhurst have consistently achieved highly with Project Academy’s assistance. Every year since our establishment, we’ve taught a growing cohort of NBHS students, and we’re excited to take you onboard too!",
        headerTwo: "Hundreds of Normanhurst Boys (NBHS) Alumni, Teachers and Students",
        paragraphTwo: "Over the last few years, we’ve had the opportunity to support and work with hundreds of Normanhurst Boys in the Project Family. This is the success we’ve achieved together:",
        image: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/6176696d41c3c812c95bab2e_normo%20logo.png",
        headerTailored: "Tailored support for Normanhurst Boys students",
        paragraphTailorOne: "At Project Academy, we understand what it takes to succeed at NBHS and in the HSC. For our NBHS students, we've created classes and exams that follow the structure and style of NBHS’ curriculum, to help you score at the very top of your cohort.",
        paragraphTailorTwo: "We can offer you this because our teachers include state-rankers and internal 1st ranks at Normanhurst Boys High School.",
        paragraphTailorThree: "Project Academy is not just a tuition centre, we’re here to be your extended family, to help you achieve your very best in the HSC and be there for you every step of the way."
    }
]

const history_Knox = [
    {
        header: "Our Long History with Knox Grammar",
        paragraph: "Knox Grammar School students have had a successful and long-standing relationship with Project Academy. With three school duxes amongst our alumni, students from Knox have consistently achieved highly with Project Academy’s assistance. Every year since our establishment, we’ve taught a growing cohort of Knox Grammar students, and we’re excited to take you onboard too!",
        headerTwo: "Hundreds of Knox Grammar Alumni, Teachers and Students",
        paragraphTwo: "Over the last few years, we’ve had the opportunity to support and work with many Knox Grammar students in the Project Family. This is the success we’ve achieved together:",
        image: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/6193a1e3967564a57149c696_1200px-Knox_Grammar_School_Logo.svg-p-500.png",
        headerTailored: "Tailored support for Knox students",
        paragraphTailorOne: "At Project Academy, we understand what it takes to succeed at Knox Grammar and in the HSC. For our Knox students, we've created classes and exams that follow the structure and style of Knox Grammar’s curriculum, to help you score at the very top of your cohort.",
        paragraphTailorTwo: "We can offer you this because our teachers include state-rankers and internal 1st ranks at Knox Grammar.",
        paragraphTailorThree: "Project Academy is not just a tuition centre, we’re here to be your extended family, to help you achieve your very best in the HSC and be there for you every step of the way."
    }
]

const history_Pymble = [
    {
        header: "Our Long History with Pymble Ladies",
        paragraph: "Pymble Ladies College students have had a successful and long-standing relationship with Project Academy. With three school duxes amongst our alumni, students from PLC have consistently achieved highly with Project Academy’s assistance. Every year since our establishment, we’ve taught a growing cohort of Pymble Ladies (PLC) students, and we’re excited to take you onboard too!",
        headerTwo: "Hundreds of Pymble Ladies Alumni, Teachers and Students",
        paragraphTwo: "Over the last few years, we’ve had the opportunity to support and work with many PLC students in the Project Family. This is the success we’ve achieved together:",
        image: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/6194d51362e72259680d9872_Pymble_Ladies_College.png",
        headerTailored: "Tailored support for Pymble Ladies College students",
        paragraphTailorOne: "At Project Academy, we understand what it takes to succeed at Pymble Ladies College and in the HSC. For our PLC students, we've created classes and exams that follow the structure and style of PLC's curriculum, to help you score at the very top of your cohort.",
        paragraphTailorTwo: "We can offer you this because our teachers include state-rankers and internal 1st ranks at PLC.",
        paragraphTailorThree: "Project Academy is not just a tuition centre, we’re here to be your extended family, to help you achieve your very best in the HSC and be there for you every step of the way."
    }
]

const history_NSG = [
    {
        header: "Our Long History with North Sydney Girls",
        paragraph: "North Sydney Girls students have had a successful and long-standing relationship with Project Academy. With three school duxes amongst our alumni, students from NSG have consistently achieved highly with Project Academy’s assistance. Every year since our establishment, we’ve taught a growing cohort of North Sydney Girls students, and we’re excited to take you onboard too!",
        headerTwo: "Hundreds of North Sydney Girls Alumni, Teachers and Students",
        paragraphTwo: "Over the last few years, we’ve had the opportunity to support and work with many NSG students in the Project Family. This is the success we’ve achieved together:",
        image: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/6177ec552b0c2e368102112f_NSG%20Logo.png",
        headerTailored: "Tailored support for North Sydney Girls students",
        paragraphTailorOne: "At Project Academy, we understand what it takes to succeed at North Sydney Girls and in the HSC. For our NSG students, we've created classes and exams that follow the structure and style of NSG's curriculum, to help you score at the very top of your cohort.",
        paragraphTailorTwo: "We can offer you this because our teachers include state-rankers and internal 1st ranks at North Sydney Girls.",
        paragraphTailorThree: "Project Academy is not just a tuition centre, we’re here to be your extended family, to help you achieve your very best in the HSC and be there for you every step of the way."
    }
]

const history_NSB = [
    {
        header: "Our Long History with North Sydney Boys",
        paragraph: "North Sydney Boys students have had a successful and long-standing relationship with Project Academy. With three school duxes amongst our alumni, students from NSB have consistently achieved highly with Project Academy’s assistance. Every year since our establishment, we’ve taught a growing cohort of North Sydney Boys students, and we’re excited to take you onboard too!",
        headerTwo: "Hundreds of North Sydney Boys Alumni, Teachers and Students",
        paragraphTwo: "Over the last few years, we’ve had the opportunity to support and work with many NSB students in the Project Family. This is the success we’ve achieved together:",
        image: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/6177eaf887db50e1e142fc71_NSB%20Logo.png",
        headerTailored: "Tailored support for North Sydney Boys students",
        paragraphTailorOne: "At Project Academy, we understand what it takes to succeed at North Sydney Boys and in the HSC. For our NSB students, we've created classes and exams that follow the structure and style of NSB's curriculum, to help you score at the very top of your cohort.",
        paragraphTailorTwo: "We can offer you this because our teachers include state-rankers and internal 1st ranks at North Sydney Boys.",
        paragraphTailorThree: "Project Academy is not just a tuition centre, we’re here to be your extended family, to help you achieve your very best in the HSC and be there for you every step of the way."
    }
]

const history_Willoughby = [
    {
        header: "Our Long History with Willoughby Girls",
        paragraph: "Willoughby Girls students have had a successful and long-standing relationship with Project Academy. With three school duxes amongst our alumni, students from Willoughby have consistently achieved highly with Project Academy’s assistance. Every year since our establishment, we’ve taught a growing cohort of Willoughby Girls students, and we’re excited to take you onboard too!",
        headerTwo: "Hundreds of Willoughby Girls Alumni, Teachers and Students",
        paragraphTwo: "Over the last few years, we’ve had the opportunity to support and work with many Willoughby students in the Project Family. This is the success we’ve achieved together:",
        image: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/6194d4e68ee32e52da3eb69f_willoughby-girls-high-school-logo.png",
        headerTailored: "Tailored support for Willoughby Girls students",
        paragraphTailorOne: "At Project Academy, we understand what it takes to succeed at Willoughby Girls and in the HSC. For our Willoughby students, we've created classes and exams that follow the structure and style of Willoughby's curriculum, to help you score at the very top of your cohort.",
        paragraphTailorTwo: "We can offer you this because our teachers include state-rankers and internal 1st ranks at Willoughby Girls.",
        paragraphTailorThree: "Project Academy is not just a tuition centre, we’re here to be your extended family, to help you achieve your very best in the HSC and be there for you every step of the way."
    }
]

export {
    history_ChatswoodHigh,
    history_Normanhurst,
    history_Knox,
    history_Pymble,
    history_NSG,
    history_NSB,
    history_Willoughby
}