import React from 'react'
import "./CTA.scss"

function CTA() {
  return (
    <div className="cta-section">
    <div className="container">
        <div className="cta-container">
            <div className="cta-container-group">
                <div className="cta-left">
                    <h2>Maximise Your Chances Of Coming First At School</h2>
                    <p>Trial any Project Academy course for 3 weeks.</p>
                    <div className="cta-point-group">
                        <div className="cta-one">
                            <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60e6b9c3dbc2d5c8d4e269dd_Tick.png" />
                            <p>NSW's Top 1% Tutors</p>
                        </div>
                        <div className="cta-two">
                            <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60e6b9c3dbc2d5c8d4e269dd_Tick.png" />
                            <p>Unlimited Tutorials</p>
                        </div>
                        <div className="cta-three">
                            <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60e6b9c3dbc2d5c8d4e269dd_Tick.png" />
                            <p>NSW's Most Effective Courses</p>
                        </div>
                        <div className="cta-four">
                            <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60e6b9c3dbc2d5c8d4e269dd_Tick.png" />
                            <p>Access to Project's iPad</p>
                        </div>
                        <div className="cta-five">
                            <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60e6b9c3dbc2d5c8d4e269dd_Tick.png" />
                            <p>Access to Exclusive Resources</p>
                        </div>
                        <div className="cta-six">
                            <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60e6b9c3dbc2d5c8d4e269dd_Tick.png" />
                            <p>Access to Project's Study Space</p>
                        </div>
                    </div>
                </div>
                <div className="cta-right">
                </div>
            </div>
            <a href={`${process.env.REACT_APP_WT_LINK}`}>3 Week Trial</a>
        </div>

    </div>
    </div>

  )
}

export default CTA