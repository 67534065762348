// Y11 INFO
// Y11 INFO
// Y11 INFO
// Y11 INFO
// Y11 INFO

const infoProvenY11Chemistry = [
    {
        heading: "1. Weekly Masterclass →",
        heading_para_1: "NSW's Top Teachers",
        para_1: "Learn from a HSC Chemistry state-ranker, first in course, or experienced teacher.",
        heading_para_2: "Strong Theory Notes",
        para_2: "Receive NSW's most in-depth and comprehensive theory notes for Year 11 Chemistry.",
        heading_para_3: "Live Prac",
        para_3: "Pracs are taught live in class so you can fully understand the most complex concepts.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/6102654085c24d6c9f8ff270_Home%20Section%20A%20Slide%2016.png"
    },
    {
        heading: "2. Weekly Personalised Tutorials →",
        heading_para_1: "Highly Personalised",
        para_1: "Every week, attend highly personalised academic-help sessions with some of the best tutors in NSW.",
        heading_para_2: "Dedicated Tutor",
        para_2: "A tutor who achieved a Band 6 in Chemistry will be assigned to you. Their goal is to ensure your marks increase every week.",
        heading_para_3: "Unlimited Tutorials",
        para_3: "Book as many tutorials as you need, any time you need. We run sessions almost every hour of the day, 7 days a week.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60fe99d9a4cf33b1d74817b8_Frame%2073.png"
    },
    {
        heading: "3. Weekly Exam-like Homework →",
        heading_para_1: "Effective Homework",
        para_1: "Effective homework that'll actually help you. Our homework sets are all carefully crafted to help you top the state.",
        heading_para_2: "Assessment Drafts",
        para_2: "For certain modules, tutors will work with you to personalise content and homework to help you with assessments and exams.",
        heading_para_3: "NSW's Best Markers",
        para_3: "Your homework sets will include a rubric developed by NSW's best markers so you know what to expect come exam time.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/61025c09e68a664a25e2e3b8_Home%20Section%20A%20Slide%209.png"
    },
    {
        heading: "4. Daily Support till 10pm →",
        heading_para_1: "Ask Anything",
        para_1: "Got questions? Every night from 8 - 10pm, we run homework help sessions called Thinktank.",
        heading_para_2: "Any Subject",
        para_2: "Whether it's English, Maths, Science, Economics... you can get help with it all. ",
        heading_para_3: "School Work Help",
        para_3: "Need help with work from school? No problem. Your tutor is there to help you.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60c71d4d4b03cb8925ffb62d_Home%20Section%20A%20Slide%203-p-1080.png"
    },
    {
        heading: "5. Exam Perfection (LEAP) →",
        heading_para_1: "12 Predicted HSC Exams",
        para_1: "12 predicted HSC exams that covers almost every question you can possibly be asked.",
        heading_para_2: "Exam Conditions",
        para_2: "12 HSC simulations that make the actual HSC feel like a practice exam.",
        heading_para_3: "Detailed Exam Review",
        para_3: "Unlimited one-on-one review sessions with your marker to learn from your mistakes.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60c71e81d3399ee4352927bd_Home%20Section%20A%20Slide%205%20(1).png"
    },
    {
        heading: "6. Unlimited Marking →",
        heading_para_1: "Homework Marking",
        para_1: "Every homework submission is marked carefuly. Worked feedback is given with the greatest detail possible.",
        heading_para_2: "Assessment Drafts",
        para_2: "Submit school work or assessment drafts for marking! A dedicated tutor will work with you to maximise your performance.",
        heading_para_3: "NSW's Best Markers",
        para_3: "Your marker is a teacher who achieved a Band 6 or state rank in HSC Chemistry.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/61025c09e68a664a25e2e3b8_Home%20Section%20A%20Slide%209.png"
    },
    {
        testimonial: `"Academic success has and will always be predicated on self motivation... the light-hearted, supportive and collaborative culture of Project has provided the catalyst for self-determination and ultimately improving my marks"`,
        testimonial_name: "Jason Lin",
        testimonial_achieve: "Dux of Knox Grammar '20",
        testimonial_atar: "99.95 ATAR, 3rd in NSW for Chemistry",
        testimonial_img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60efb31f45789a7a206c888f_Jason.png"
    },
    {
        section_heading: "A Proven Approach to Y11 Chemistry"
    }
]

// Y11 RESOURCES
// Y11 RESOURCES
// Y11 RESOURCES
// Y11 RESOURCES
// Y11 RESOURCES

const infoResourcesY11Chemistry = [
    {
        heading: "1. Weekly In-depth Notes →",
        heading_para_1: "In-depth Weekly Notes",
        para_1: "High Band 6 students don't just know the theory. They know the why, the how and know how to apply it in exams, assignments and pracs. Our resources distill the last 10 years of Project's state ranker's and 99+ ATAR tutor's knowledge.",
        heading_para_2: "Purposefully Structured",
        para_2: "All notes are accessed through the Project Books app organised at an unmatched level of detail. Content is organised by lesson and topic, alongside topic-specific HSC exam questions so you know exactly where to focus when preparing for an assessment.",
        doc: "https://drive.google.com/file/d/1CKCnFbGKYeXKozw46bI7O9V-tgWHoB-W/preview"
    },
    {
        heading: "2. Weekly In-class Workbook →",
        heading_para_1: "Comprehensive Theory Notes",
        para_1: "Every week, you will receive a specially designed workbook for class. Each workbook helps you capture class content in a neat, structured way so that you will easily remember how to approach each concept.",
        heading_para_2: "Specially Designed Workbooks",
        para_2: "This way, our workbooks are designed to make absorbing class content infinitely more efficient, while ensuring you understand, retain and can easily revise content for your HSC exam.",
        doc: "https://docs.google.com/file/d/1_vzLrPluHhiwreqVi5o1ogAF53S8BOJT/preview"
    },
    {
        heading: "3. Weekly Exam-like Homework →",
        heading_para_1: "Weekly Exam-like Homework",
        para_1: "Every week, you will receive specially designed homework sets to mimic how you will be examined in the HSC. By the time you sit your HSC, it should feel like a breeze.",
        heading_para_2: "Marked & Tracked",
        para_2: "After you submit your homework, receive highly detailed feedback from a state ranking or high band 6 tutor. All mistakes are tracked via an app so we know exactly what topics you're struggling with.",
        doc: `https://drive.google.com/file/d/18vVNpBh4v1JGONqp9vW4KDHUBX-_q-cG/preview`
    },
    {
        heading: "4. In-depth Prac Notes →",
        heading_para_1: "In-depth Practical Notes",
        para_1: `Your first assessment is likely a practical assessment. We've written detailed guides that cover every aspect of "working scientifically" and includes practice exam questions and solutions to prepare you for every prac you might encounter.`,
        heading_para_2: "School-specific Resources",
        para_2: "Additionally, Project Academy provides school-specific resources and classes often taught by a top performing alumni of your schoo. This will help you really hone in on the topics you study, and help you navigate the nuances of your school's examination system.",
        doc: "https://drive.google.com/file/d/1ELc-WdlANo1opbCGYVIoaCRfksx-3oql/preview"
    },
    {
        heading: "5. Depth Study Resources →",
        heading_para_1: "Depth Study Resources",
        para_1: "You will also receive an extensive range of resources to assist in your depth study assignments. This includes content specific Chemistry books as well as books aimed at developing critical scientific skills.",
        heading_para_2: "Personalised Assistance",
        para_2: "Additionally, highly personalised sessions with a Chemistry tutor are offered on an active basis in case further assistance is required.",
        img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60c71cc9c3ee5b402170e5c7_Home%20Section%20A%20Slide%202%20(1)-p-1080.png"
    },
    {
        heading: "4. 1,000+ Past Papers →",
        heading_para_1: "1,000+ Past Papers",
        para_1: "Access over 300 past papers for HSC Chemistry and over 1,000 HSC past papers across any other subject you take at school.",
        heading_para_2: "Not Just HSC Papers",
        para_2: "These past papers aren't just for Trials or the HSC. They cover all assessment tasks encountered every term, and for both Year 11 and Year 12.",
        doc: "https://drive.google.com/file/d/1o1mTCI_BU-WG7hFQ0NcP-zncSL3olQj3/preview"
    },
    {
        testimonial: "Academic success has and will always be predicated on self motivation... the light-hearted, supportive and collaborative culture of Project has provided the catalyst for self-determination and ultimately improving my marks",
        testimonial_name: "Jason Lin",
        testimonial_achieve: "Dux of Knox Grammar '20",
        testimonial_atar: "99.95 ATAR, 3rd in NSW for Chemistry",
        testimonial_img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60efb31f45789a7a206c888f_Jason.png"
    },
    {
        section_heading: "The Right Resources"
    }
]
export {
    infoProvenY11Chemistry,
    infoResourcesY11Chemistry
}
