//Y11 ENGLISH ADVANCED 
//Y11 ENGLISH ADVANCED 
//Y11 ENGLISH ADVANCED 
//Y11 ENGLISH ADVANCED 
//Y11 ENGLISH ADVANCED 

const schedulesY11English = [
    {
        heading: "Normal Schedule →",
        text: "",
        term1: 
            [
                "Creative writing",
                "Discursive writing",
                "Persuasive writing",
                "Reflections",
                "Workshops",
                "Assessments"
            ],
        term2:
            [
                "Short answers",
                "Text study: Poetry",
                "Essays 1",
                "Essays 2",
                "Multimodals",
                "Exams"
            ],
        term3: 
            [
                "Module B: Critical study of literature",
                "Shakespeare: Othello",
                "Short answers revision",
                "Essay writing deep dive",
                "Exams"
            ],
        term4:
            [
                "HSC English begins"
            ]
    },
]

//Y11 ECONOMICS
//Y11 ECONOMICS
//Y11 ECONOMICS
//Y11 ECONOMICS
//Y11 ECONOMICS

const schedulesY11Economics = [
    {
        heading: "Normal Schedule →",
        text: "",
        term1: 
            [
                "Introduction to Economics",
                "Consumers and business",
                "Exam & review",
                "Markets"
            ],
        term2:
            [
                "Labour markets",
                "Exam & review",
                "Finanical markets",
                "Essay writing skills"
                
            ],
        term3: 
            [
                "Govenrment and the Economy",
                "Exam & review",
                "Essay writing skills",
                "Challenge questions",
                "Final exam & review",
                "Introduction to HSC Economics"
            ],
        term4:
            [
                "HSC commences"
            ]
    },
]

//Y11 CHEMISTRY
//Y11 CHEMISTRY
//Y11 CHEMISTRY
//Y11 CHEMISTRY
//Y11 CHEMISTRY

const schedulesY11Chemistry = [
    {
        heading: "Normal Schedule →",
        text: "",
        term1: 
            [
                "Matter",
                "Exam & review"
            ],
        term2:
            [
                "Quantitative Chemistry",
                "Exam & review",
                "Reactive Chemistry"
                
            ],
        term3: 
            [
                "Reactive Chemistry",
                "Exam & review",
                "Drivers of reactions",
                "Comprehensive final exam preparation",
                "Introduction to HSC Chemistry"
            ],
        term4:
            [
                "HSC commences"
            ]
    },
]

//Y11 PHYSICS
//Y11 PHYSICS
//Y11 PHYSICS
//Y11 PHYSICS
//Y11 PHYSICS

const schedulesY11Physics = [
    {
        heading: "Normal Schedule →",
        text: "",
        term1: 
            [
                "Kinematics",
                "Exam & review",
                "Dynamics",
                "Prac test & skills"
            ],
        term2:
            [
                "Dynamics",
                "Exam & review",
                "Waves & thermo"
                
            ],
        term3: 
            [
                "Waves & thermo",
                "Exam & review",
                "Electricity & magnetism",
                "Comprehensive exam & review"
            ],
        term4:
            [
                "Crash course for Kinematics, Dyanmics, Waves, Electricity and general",
                "Advanced Prelim. 1: Mechanics",
                "Advanced Prelim. 2: Electromagnetism",
                "Advanced Prelim. 3: Light"
            ]
    },
]

//Y11 Maths Accel
//Y11 Maths Accel
//Y11 Maths Accel
//Y11 Maths Accel
//Y11 Maths Accel

const schedulesY11MathsAccel = [
    {
        heading: "Normal Schedule →",
        text: "",
        term1: 
            [
                "Series",
                "Maths Induction",
                "Curve Sketching",
                "Exam & review",
                "Combinatorics"
            ],
        term2:
            [
                "Introduction to Differentiation",
                "Geometric Applications of Differentiation",
                "Trigonometric Foundations",
                "Integration",
                "Logarithms and Exponentials",
                "Series",
                "Induction & Sketching"
            ],
        term3: 
            [
                "Trigonometric Calculus",
                "Calculus Applications",
                "Probability",
                "HSC 2U Statistics",
                "Further Trigonometry",
                "Polynomials",
                "Calculus & Related Rates",
                "Combinatorics"
            ],
        term4:
            [
                "Inverse Trigonometry",
                "Binomial Theorem",
                "HSC 3U Statistics & Sundry",
                "Further Sketching",
                "Sketching",
                "Vectors",
                "Motion",
                "Differential Equations"
            ]
    },
]

//Y11 Maths Adv
//Y11 Maths Adv
//Y11 Maths Adv
//Y11 Maths Adv
//Y11 Maths Adv

const schedulesY11MathsAdv = [
    {
        heading: "Normal Schedule →",
        text: "",
        term1: 
            [
                "Functions",
                "Trigonometric Ratios",
                "Exam & review",
                "Introduction to Differentiation"
            ],
        term2:
            [
                "Geometric Applications of Differentiation",
                "Exam & review",
                "Integration",
                "Assessment Preparation",
                "Logs and Exponentials",
                "Curve Sketching",
                "Trig Calculus"
            ],
        term3: 
            [
                "Series and their applications",
                "Calculus Applications",
                "Probability",
                "Statistics",
                "Exam & review",
                "Financial Maths",
                "Comprehensive Exam Preparation"
            ],
        term4:
            [
                "This Year 11 Maths program is specially designed to cover the entire Year 11 2U Maths and Year 12 2U Maths courses in 9 months. This gives you the opportunity to solely dedicate Year 12 to studying 3U or 4U Maths.",
                "Note: The Accelerated Maths course covers all of Year 11 and Year 12 2U and 3U Maths in 9 months."
            ]
    },
]

export {
    schedulesY11English,
    schedulesY11Economics,
    schedulesY11Chemistry,
    schedulesY11Physics,
    schedulesY11MathsAccel,
    schedulesY11MathsAdv
}