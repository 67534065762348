import React from 'react'
import BlogDiv from './BlogDiv'
import "./BlogSection.scss";

import blogSection from '../assets/blogSectionObjects';

function BlogSection() {
  return (
    <div id="blog-section" className="container">
        <div className="blog-top">
            <div className="blog-top-left">
                <h2>Read State Ranking Advice</h2>
                <p>Hundreds of state ranker level notes, advice and study guides.</p>
                <a href="https://www.projectacademy.nsw.edu.au/thoughts">Visit Our Guides Database →</a>
            </div>
            <div className="blog-top-right">
                <a href="">
                <div className="blog-large">
                    <div className="blog-large-img">
                        <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/608b5b6e5025fd80aa250d62_Marko%20-%20Big%20Blog%20Image.jpg" />
                    </div>
                    <div className="blog-large-text-group">
                        <div className="blog-large-text">
                            <h3>Achieving 3 state ranks, a 99.95 ATAR and being school captain</h3>
                            <p>At times, Year 12 was the last lap of Mario Kart's Rainbow Road...</p>
                        </div>
                        <div className="info-testimonial-person">
                            <div className="info-testimonial-image blog-author-image">
                                <img src="https://uploads-ssl.webflow.com/60b5fcff999dc132ebcefb3e/60bf34648dc8a3a0b953eca0_Marko.jpeg" />
                            </div>
                            <div className="info-testimonial-details">
                                <p className="info-testimonial-name">Marko Beocanin</p>
                                <p className="info-testimonial-school">Dux of Normanhurst Boys & 99.95 ATAR</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                </a>
            </div>
            
        </div>
        <div className="blog-bottom">
        {blogSection?.map((n) => (
            <BlogDiv data={n}/>
        ))}
        </div>
    </div>
  )
}

export default BlogSection