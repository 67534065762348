import React from 'react'
import Profile from './Profile';
import "./TutorSection.css"

import { allProfiles } from '../assets/profileObjects';

function Tutors({data}) {

  data = data.replace(/\s/g, '');

  return (
    <div className="container tutors-container">
        <h2 className="tutors-header">The Most Incredible Tutors</h2>  
        <div className="tutors-group">
          {allProfiles?.map((tutor) => (
              (tutor.subject === data)
              ? <Profile tutorName={tutor.name} tutorSubject={data}/>
              : ""
          ))}
        </div>
    </div>
  )
}

export default Tutors