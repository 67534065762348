const home_InfoOne = [
    {
        heading: "1. Weekly Classes →",
        heading_para_1: "Weekly classes",
        para_1: "In class, you will learn everything from scratch and in great detail, guided by the syllabus. The lessons are packed with opportunities to learn-by-doing as your teachers put you through practice exam questions followed by detailed solutions.",
        heading_para_2: "State ranking tutors",
        para_2: "Project's tutors are all state rankers or 99+ ATAR achievers. They are the highest performing, and most passionate teachers in the state.",
        img: require("./info/infoOne-1.webp")
    },
    {
        heading: "2. Unlimited Tutorials →",
        heading_para_1: "Personalised tutorials",
        para_1: "With a maximum of 4 students, tutorials are your opportunity receive highly personalised support. Review complex concepts, correct homework mistakes and receive help for upcoming school assessments.",
        heading_para_2: "Unlimited Tutorials",
        para_2: "Project has an Unlimited Tutorials Policy to put students in charge of their learning - you can book into extra help any time you need!",
        img: require("./info/infoOne-2.webp")
    },
    {
        heading: "3. State Ranking Resources →",
        heading_para_1: "State Ranker Library & Exemplars",
        para_1: "To help our students excel, our state ranking tutors regularly share their own notes, and actual exam papers and assignments that scored at the very top of the state.",
        heading_para_2: "Weekly Class Notes",
        para_2: "Every week, you receive access to notes written by our tutors designed to cover each topic in a level of depth that allowed them to achieve state ranks and 99+ ATARs.",
        img: require("./info/infoOne-3.webp")
    },
    {
        heading: "4. Daily Support till 11pm →",
        heading_para_1: "Thinktank: Daily Support Till 11pm",
        para_1: "Thinktank is a late night study session from 8pm to 11pm hosted by various tutors a few times a week so you can study at Project until late and get the help you need as you need it!",
        heading_para_2: "Slack: Remote Support",
        para_2: "All Project students get access to the live messaging Slack app on their iPads. This means you can reach your teachers and tutors for academic advice and feedback almost anytime of the week!",
        img: require("./info/infoOne-4.webp")
    },
    {
        heading: "5. Exam Perfection →",
        heading_para_1: "LEAP: Exam Perfection Program",
        para_1: "You will sit 10 full-length mock HSC exams in a strict exam environment starting June of your HSC year. This weekly high pressure exam-and-review routine will turn the HSC into something familiar and comfortably predictable.",
        heading_para_2: "Predicted HSC Exam Questions",
        para_2: "Each exam is a prediction of your HSC. Our staff sit down together throughout the year to design questions that are predicted to appear in this year's HSC. The LEAP program is the ultimate exam preparation program.",
        img: require("./info/infoOne-5.webp")
    },
    {
        testimonial: "Academic success has and will always be predicated on self motivation... the light-hearted, supportive and collaborative culture of Project has provided the catalyst for self-determination and ultimately improving my marks",
        testimonial_name: "Jason Lin",
        testimonial_achieve: "Dux of Knox Grammar '20",
        testimonial_atar: "99.95 ATAR, 3rd in NSW for Chemistry",
        testimonial_img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60efb31f45789a7a206c888f_Jason.png"
    },
    {
        section_heading: "A Proven Mark Maximisation Approach"
    }
]

const home_InfoTwo = [
    {
        heading: "1. World Class Environment →",
        heading_para_1: `"Grasslands" Open till 10PM`,
        para_1: "Everyday from 9am to 10pm, get exclusive access to \"Grasslands\" - Sydney's most effective silent study sanctuary located in each of our campuses. In addition, all campuses include fast wifi, free printing, acccess to textbooks and tutors till 10pm everyday.",
        heading_para_2: "Student Centred Culture",
        para_2: "Everything on campus is designed so that everything you need is within arms length. Need a tutor to speak to? Pop by the staff room. Need essays marked? Send them to your assigned marker. Have an urgent question at 10pm? Message our Slack channels.",
        img: require("./info/infoTwo-1.webp"),
        video: ""
    },
    {
        heading: "2. Your Friends, Team & Family →",
        heading_para_1: "Your Friends and Family",
        para_1: "Incredible friendships are formed at Project that last a lifetime. The HSC and life beyond is far better with your own support network backing you every step of the way.",
        heading_para_2: "Your Own Team",
        para_2: "Behind every champion is a world class team. At Project, every student has at least one dedicated teacher and tutor who are responsible your growth.", 
        img: require("./info/infoTwo-2.webp"),
        video: ""
    },
    {
        heading: "3. Well-being & Support →",
        heading_para_1: "We Care",
        para_1: "Project Academy's entire team and its founders are wholeheartedly here if you ever need a chat. You can talk to us between class or via message on Slack!",
        heading_para_2: "Health and Wellness",
        para_2: "Join health and wellness programs such as group sports, yoga, meditation and more.",
        img: "",
        video: "https://www.youtube.com/embed/uH8TonYlvJk"
    },
    {
        heading: "4. The Project iPad →",
        heading_para_1: "Your Secret Weapon",
        para_1: "This is the Swiss Army Knife of the HSC. Packed into an iPad, you get access to everything you ever need. Help from a tutor is one message away and thousands of 99+ ATAR quality notes are ready at your disposal.",
        heading_para_2: "Custom Built Apps",
        para_2: "You also receive access to our suite of exclusive HSC apps built by our dedicated edutech software engineering team, with our state rankers and 99+ ATAR teachers.",
        img: "",
        video: "https://www.youtube.com/embed/UlA-RcaW74A"
    },
    {
        heading: "5. Daily Live Support →",
        heading_para_1: "Online Grasslands",
        para_1: "We work our best when others are there to motivate you. We've made group studying remotely possible via daily Zoom sessions called 'Grasslands'.",
        heading_para_2: "Daily 'Thinktank'",
        para_2: "If you have any questions, you can jump into the 'Thinktank' Zoom calls and have it answered by a tutor until 10pm every night.",
        img: require("./info/infoTwo-5.webp"),
        video: ""
    },
    {
        testimonial: `"Project Academy's creative and personable team of staff works alongside the students, and has engineered an uplifting space of learning, inspiration and support"`,
        testimonial_name: "Oliver Lenzner",
        testimonial_achieve: "Dux of Riverview '20",
        testimonial_atar: "99.95 ATAR, 1st in 3U Maths (100)",
        testimonial_img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60efb27c41ee385096839f26_Oliver.png"
    },
    {
        section_heading: "The Right Environment"
    }
]

const home_InfoThree = [
    {
        heading: "1. In-depth Weekly Notes →",
        heading_para_1: "In-depth Weekly Notes",
        para_1: "Receive highly detailed notes written by our 99+ ATAR and state ranking tutors. Each resource is specially designed to help you score at the very top of the state.",
        heading_para_2: "Purposefully Structured",
        para_2: "All notes are accessed through the Project Books app. The app organises the notes by lesson and by topic to the syllabus so you know exactly where to focus on when preparing for an assessment.",
        doc: "https://drive.google.com/file/d/1T-dNNRnEqyz0pRNrOYaRJH3rEiKgbz5l/preview"
    },
    {
        heading: "2. Thousands of Past Papers →",
        heading_para_1: "Thousands of Past Papers",
        para_1: "Access thousands of exclusive past papers collected from NSW schools over the past 10 years. They cover all assignments and assessments examined.",
        heading_para_2: "Worked Solutions Included",
        para_2: "We provide detailed worked solutions for most of our past papers, so you can confidently work through, and learn from your corrections.",
        doc: "https://drive.google.com/file/d/1eqtrK0AJ47kl9VxAAZconpXC6xWhr1og/preview"
    },
    {
        heading: "3. State Ranker Notes & Exemplars →",
        heading_para_1: "Comprehensive Resources",
        para_1: "Access over thousands of pages of notes (course notes and supplementary notes) for each subject, written by state-rankers and experienced teachers.",
        heading_para_2: "State Ranker Level Notes",
        para_2: "Access hundreds of 20/20 essay plans, essay scaffolds, summary sheets and more for each subject.",
        doc: "https://docs.google.com/file/d/1FtOeSf85jrPuCUhQcLEXLT0MvpCbhWINaVW69T9BfY0/preview"
    },
    {
        heading: "4. Personalised Marking →",
        heading_para_1: "Detailed Marking",
        para_1: "We don't mark in batch like others do. You'll get detailed markups and feedback on all your weekly homework submissions because a tick or cross just doesn't cut it.",
        heading_para_2: "Personalised & Tracked",
        para_2: "We know you on an individual level and personalise our marking. All mistakes are tracked via an app so we know exactly what topics you're struggling with.",
        img: require("./info/infoThree-4.webp"),
    },
    {
        testimonial: `""The creative and personable team of staff works alongside the students, and has engineered an uplifting space of learning, inspiration and support"`,
        testimonial_name: "Oliver Lenzner",
        testimonial_achieve: "Dux of Riverview '20",
        testimonial_atar: "99.95 ATAR, 1st in 3U Maths (100)",
        testimonial_img: "https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/60efb27c41ee385096839f26_Oliver.png"
    },
    {
        section_heading: "The Right Resources"
    }
]

export {home_InfoOne, home_InfoTwo, home_InfoThree}