import React from 'react'

import HSC3UMaths from "../assets/subjects/HSC3UMaths.jpg"

function HSCSubjects() {
  return (
    <div className="container">
        <div className="subjects-list">
            <a href={process.env.REACT_APP_HSCENGLISH}>
                <div className="subject-item">
                    <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/6129e4626077b6712f3deb97_HSC%203U%20Maths.png" />
                    <div className="subject-item-textgroup">
                        <p className="subject-item-subject">HSC English Advanced</p>
                        <p className="subject-item-paragraph">Learn more about HSC English Advanced at Project Academy.</p>
                    </div>
                </div>
            </a>
            <a href={process.env.REACT_APP_HSCECONOMICS}>
                <div className="subject-item">
                    <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/6129e463f55a835e7613aae7_HSC%20Economics.png" />
                    <div className="subject-item-textgroup">
                        <p className="subject-item-subject">HSC Economics</p>
                        <p className="subject-item-paragraph">Learn more about HSC Economics at Project Academy.</p>
                    </div>
                </div>
            </a>
            <a href={process.env.REACT_APP_HSCCHEMISTRY}>
                <div className="subject-item">
                    <img src="https://uploads-ssl.webflow.com/607520e7e5d45121dbcc43a4/6129e46335b1fdeb042acbc0_HSC%20Chemistry.png" />
                    <div className="subject-item-textgroup">
                        <p className="subject-item-subject">HSC Chemistry</p>
                        <p className="subject-item-paragraph">Learn more about HSC Chemistry at Project Academy.</p>
                    </div>
                </div>
            </a>
            <a href={process.env.REACT_APP_HSC3UMATHS}>
                <div className="subject-item">
                    <img src={HSC3UMaths} />
                    <div className="subject-item-textgroup">
                        <p className="subject-item-subject">HSC 3U Maths</p>
                        <p className="subject-item-paragraph">Learn more about HSC 3U Maths at Project Academy.</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
  )
}

export default HSCSubjects